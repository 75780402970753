import appLocaleData from 'react-intl/locale-data/en';
import common from './en-US.common';
import pages from './en-US.pages';
import pagination from './en-US.pagination';

window.appLocale = {
  // 合并所有 messages, 加入组件的 messages
  messages: Object.assign({}, common, pages, pagination),

  // locale
  locale: 'en-US',
  // react-intl locale-data
  data: appLocaleData
};

export default window.appLocale;
