import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import Ts from 'localeTranslator';
import { Select } from 'antd';
import { BtcModal, LoadingIcon } from '@/components/widget';
import SvgIcon from '@/components/SvgIcon';
import { coinsConfig } from '@/utils/config';
const Option = Select.Option;

@inject('store')
@observer
class OneSwitchModal extends Component {
  constructor(props) {
    super(props);
    this.userStore = this.props.store.userStore;
    this.settingsStore = this.props.store.settingsStore;
    this.appStore = this.props.store.appStore;
    this.settingsStore.setOneSwitchModalOpType('selected-mode');

    const defaultMode = this.props.defaultMode;
    const currentMode = this.props.currentMode;
    this.defaultDest = {
      dest_puid: null,
      dest_puid_name: '',
      region_name: ''
    };
    this.state = {
      defaultMode,
      currentMode,
      switchErrorMsg: '',
      switchedDest: Object.assign({}, this.defaultDest)
    };
  }

  getTitleByOpType = opType => {
    return (
      <>
        <SvgIcon iconName="icon_tips_normal" className="gap-r" />
        <Ts id="pages.settings.oneSwitch.attention" />
      </>
    );
  };

  getCurrentSupportCoin = currentSupportModes => {
    return currentSupportModes.map(m => (
      <Option value={m}>
        <Ts id={`common.allSwitchMode.${m}`} />
      </Option>
    ));
  };

  handleChangeMode = value => {
    this.setState({ currentMode: value });
    this.settingsStore.setOneSwitchModalOpType('selected-mode');
  };

  handleOk = () => {
    const { defaultMode, currentMode } = this.state;
    this.setState({ switchErrorMsg: '' }); // reset switchErrorMsg
    //oneSwitchModalOpType: selected-mode | switching | switched-failed | switched-sucess
    let { oneSwitchModalOpType: opType } = this.settingsStore;
    if (opType === 'selected-mode') {
      this.settingsStore.setOneSwitchModalOpType('switching');
      this.settingsStore.switchHashrate(
        {
          source: defaultMode,
          dest: currentMode,
          puid: this.props.puid
        },
        data => {
          this.setState({ switchedDest: data });
          this.settingsStore.setOneSwitchModalOpType('switched-sucess');
        },
        switchErrorMsg => {
          //失败，则回到上一步
          this.settingsStore.setOneSwitchModalOpType('selected-mode');
          this.setState({ switchErrorMsg });
        }
      );
    } else if (opType === 'switched-sucess') {
      // 进入到切换后的子账户
      const { switchedDest } = this.state;
      this.userStore.changeAccount({
        puid: switchedDest.dest_puid,
        coin_type: this.props.currentMode,
        name: switchedDest.dest_puid_name
      });
      this.userStore.getSubAccountList();

      this.props.onOk();
    }
  };

  handleCancel = () => {
    let { oneSwitchModalOpType: opType } = this.settingsStore;
    if (opType === 'switched-sucess') {
      // 刷新子账户列表
      this.userStore.getSubAccountList();
    }
    this.props.onCancel();
  };

  render() {
    //oneSwitchModalOpType: selected-mode | switching | switched-failed | switched-sucess
    let { oneSwitchModalOpType: opType, loadingSwitching } = this.settingsStore;
    const { zenLang } = this.appStore;
    const { visible } = this.props;

    const { currentMode, switchErrorMsg, switchedDest } = this.state;

    const modalProps = {
      title: this.getTitleByOpType(opType),
      hideOkButton: opType === 'switching',
      hideCancelButton:
        opType === 'selected-mode' ||
        opType === 'switching' ||
        opType === 'switched-failed' ||
        opType === 'switched-sucess',
      okText:
        opType === 'switched-sucess' ? (
          <Ts id="pages.settings.oneSwitch.enter" />
        ) : null
    };

    return (
      <BtcModal
        visible={visible}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        {...modalProps}
      >
        {/* 选择某个模式后 */}
        {opType === 'selected-mode' && (
          <>
            <p>
              <Ts
                id="pages.settings.oneSwitch.switch"
                values={{
                  destMode: <Ts id={`common.allSwitchMode.${currentMode}`} />
                }}
              />
            </p>
            {coinsConfig[currentMode.toLowerCase()].showSwitchTip && (
              <p className="error-text">
                <Ts
                  id="pages.settings.oneSwitch.ethSwitchWarning"
                  type="html"
                />
                <a
                  href={`https://help.pool.btc.com/hc/${zenLang}/articles/360038493691`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Ts id="pages.settings.oneSwitch.ethSwitchWarningLinkTxt"></Ts>
                </a>
              </p>
            )}
            {switchErrorMsg && <p className="error-text">{switchErrorMsg}</p>}
          </>
        )}
        {/* 进行切换 */}
        {opType === 'switching' && (
          <>
            <p>
              <Ts
                id="pages.settings.oneSwitch.switchAnotherMining"
                values={{
                  destMode: <Ts id={`common.allSwitchMode.${currentMode}`} />
                }}
              />
            </p>

            <div className="text-center">
              {loadingSwitching && <LoadingIcon />}
            </div>
          </>
        )}
        {/* 切换成功 */}
        {opType === 'switched-sucess' && (
          <>
            <p>
              <Ts
                id="pages.settings.oneSwitch.hashsHaveSwitched"
                values={{
                  destMode: <Ts id={`common.allSwitchMode.${currentMode}`} />
                }}
              />
            </p>
            <p>
              <Ts
                id="pages.settings.oneSwitch.checkSubAccount"
                values={{
                  subAccountName: switchedDest.dest_puid_name,
                  subAccountRegionNode: switchedDest.region_name
                }}
              />
            </p>
          </>
        )}
      </BtcModal>
    );
  }
}

export default OneSwitchModal;
