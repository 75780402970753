import { observable, action, runInAction, computed } from 'mobx';
import axios from 'axios';
import { lowerCase, upperCase } from 'utils';
import Ts from 'localeTranslator';
import { message } from 'antd';

/**
 * 设置子账户相关，必须提供 puid 和 coinType
 * 分两种情况：
 * 1.为当前登录的子账户进行设置，puid 和 coinType 去 UserStore获取
 * 2.从子账户列表点击“设置”进行子账户设置，puid 和 coinType 通过url链接参数获取
 */
class SettingsStore {
  //#region Overview
  // 当前要设置的子账户的 puid
  @observable puid = null;

  // 当前要设置的子账户的币种 currentCoinType
  @observable currentCoinType = '';

  // 当前要设置的子账户的详情，需要通过以下两个接口获取:
  // v1/account/info?puid=
  // v1/account/sub-account/info?puid=
  @observable currentSubAccountInfo = {};

  @observable loading = false;
  //#endregion

  //#region 收款地址和最小起付金额 wallet address & minimum payment
  @observable
  modalOpType = 'address-input';
  @observable
  modifyModalVisible = false;
  @observable
  verifyUuid = '';
  @observable
  verifyCode = '';
  @observable
  validate = {
    newAddress: '',
    update: ''
  };
  @observable minPayValue = 0.001;
  @observable minPayForAll = false;
  //#endregion

  //#region 共享数据 包括观察者链接和守护者设置 share to watcher
  @observable watcherList = [];
  @observable currentWatcherId = null;
  //#endregion

  //#region 报警设置 Alert Setting
  @observable loadingContactList = false;
  @observable contactList = [];
  @observable alertModalVisible = false;
  @observable alertModalOpType = 'contact-create';
  @observable currentUpdateAlertContact = {};
  //#endregion

  //#region 已BTC支付 Pay with BTC
  @observable loadingChangePaywithBtc = false;
  //#endregion

  /**
   * 要设置的当前子账户的币种
   */
  @action
  setCurrentCoinType = coinType => {
    this.currentCoinType = coinType;
  };

  /**
   * 要设置的当前子账户的 puid
   */
  @action
  setCurrentPuid = puid => {
    this.puid = puid;
  };

  /**
   * 当前要设置的子账户的详情，需要通过以下两个接口获取:
   * v1/account/info?puid=
   * v1/account/sub-account/info?puid=
   */
  @action
  getSubAccountInfo = async puid => {
    this.loading = true;
    const accountRes = await axios.get(`account/info`, {
      params: {
        puid: puid ? puid : this.puid
      }
    });
    if (accountRes && accountRes.data) {
      runInAction(() => {
        // 可以获取到收款地址、起付金额、联系方式等相关信息
        this.currentSubAccountInfo = {
          ...accountRes.data
        };
      });
    }

    const subAccountRes = await axios.get(`account/sub-account/info`, {
      params: {
        puid: this.puid
      }
    });
    this.loading = false;
    if (subAccountRes && subAccountRes.data) {
      runInAction(() => {
        //可以获取到子账户名、地区等信息
        const { name, region_conf } = subAccountRes.data;
        this.currentSubAccountInfo = {
          ...this.currentSubAccountInfo,
          name,
          regionText: region_conf ? region_conf.text : ''
        };
      });
    }
  };

  /**
   * 用户联系方式
   * @readonly
   */
  @computed
  get userContactTypeList() {
    let contactTypeList = [];
    const { contact } = this.currentSubAccountInfo;
    if (!contact) {
      return [];
    }
    if (contact.mail || contact.mail.length > 0) {
      contactTypeList.push({ type: 'mail', value: contact.mail });
    }
    if (contact.phone && contact.phone.number) {
      contactTypeList.push({
        type: 'sms',
        value: contact.phone ? contact.phone.number : ''
      });
    }

    return contactTypeList;
  }

  @action
  setModifyModalVisible = isShow => {
    this.modifyModalVisible = isShow;
  };

  /**
   * 设置操作类型
   * need-wait: 暂不可操作
   *
   */
  @action
  setModalOpType = type => {
    this.modalOpType = type;
  };

  //#region -- 收款地址设置 Set Wallet Address --

  @action
  resetValidate = () => {
    this.validate = {
      newAddress: '',
      update: ''
    };
  };

  /**
   * 验证地址非空
   */
  @action
  validateAddress = newAddress => {
    if (!newAddress) {
      let { newAddress, ...others } = this.validate;
      newAddress = new Ts().getLocaleString('pages.settings.emptyAddress');
      this.validate = { newAddress, ...others };
      return false;
    }
    return true;
  };

  /**
   * 验证验证码非空
   */
  @action
  validateVerifyCode = verifyCode => {
    if (!verifyCode) {
      let { update, ...others } = this.validate;
      update = new Ts().getLocaleString('pages.settings.emptyCode');
      this.validate = { update, ...others };
      return false;
    }
    return true;
  };

  /**
   * 发送验证码
   */
  @action
  triggerVerifyCode = async (
    newAddress,
    contactType,
    successCallback,
    errorCallback
  ) => {
    try {
      if (!this.validateAddress(newAddress)) {
        return;
      }
      this.loadingCreate = true;
      const res = await axios.get(`/account/verify-code/${contactType}`, {
        params: {
          puid: this.puid
        }
      });
      if (res && res.data) {
        runInAction(() => {
          if (res.data.success) {
            this.verifyUuid = res.data.uuid;
            successCallback();
          } else {
            if (
              typeof res.data.status === 'string' &&
              res.data.retries_left > 0
            ) {
              /* 频繁发送验证码，接口返回格式为：⊙﹏⊙ 
                data:{
                  retries_left:30,
                  status:'发送频繁',
                  success:false,
                  uuid:'xxx'
                }
              */
              let { update, ...others } = this.validate;
              update = res.data.status;
              this.validate = { update, ...others };
            }
            errorCallback && errorCallback();
          }
        });
      }
    } catch (error) {
      errorCallback && errorCallback();
    }
  };

  /**
   * confirmUpdate
   */
  @action
  updateAddress = async (
    verify_code,
    verify_mode,
    new_address,
    successCallback,
    errorCallback
  ) => {
    try {
      const res = await axios.post(`/account/address/update`, {
        puid: this.puid,
        verify_code,
        verify_mode,
        verify_id: this.verifyUuid,
        new_address
      });
      if (res && res.data) {
        runInAction(() => {
          successCallback();
        });
      }
    } catch (error) {
      if (error.data.new_address) {
        let { newAddress, ...others } = this.validate;
        this.validate = { newAddress: error.msg, ...others };
      } else {
        let { update, ...others } = this.validate;
        this.validate = { update: error.msg, ...others };
      }
      if (errorCallback) {
        errorCallback(error.msg);
      }
    }
  };
  //#endregion

  //#region -- 起付金额设置 Set Min Pay --
  @action
  setMinPayValue = value => {
    this.minPayValue = value;
  };

  @action
  setMinPayForAll = isForAll => {
    this.minPayForAll = isForAll;
  };

  @action
  modifyMinPay = async (successCallback, errorCallback) => {
    try {
      const res = await axios.post(`/account/pay/limit`, {
        puid: this.puid,
        amount: this.minPayValue === 0.001 ? 0.005 : this.minPayValue,
        all_subaccount_limit: Number(this.minPayForAll)
      });
      if (res && res.data) {
        runInAction(() => {
          if (res.data.status === true) {
            successCallback();
          } else {
            errorCallback();
          }
        });
      }
    } catch (error) {
      errorCallback(error.msg);
    }
  };
  //#endregion

  //#region -- 观察者设置 Share to watcher
  @observable loadingWatcherList = false;
  @action
  getWatcherList = async () => {
    this.loadingWatcherList = true;
    const res = await axios.get(`/account/watcher/list`, {
      params: {
        puid: this.puid
      }
    });
    this.loadingWatcherList = false;
    if (res && res.data) {
      runInAction(() => {
        this.watcherList = res.data;
      });
    }
  };

  @action
  createWatcher = async (note, successCallback, errorCallback) => {
    try {
      const res = await axios.post(`/account/watcher/create`, {
        puid: this.puid,
        note
      });
      if (res && res.data) {
        runInAction(() => {
          if (res.data.status === true) {
            this.getWatcherList();
            successCallback();
          }
        });
      }
    } catch (error) {
      message.error(error.msg);
      errorCallback(error.msg);
    }
  };

  /**
   * 当前操作的观察者ID
   */
  @action
  setCurrentWatcherId = watcher_id => {
    this.currentWatcherId = watcher_id;
  };

  @action
  deleteWatcher = async successCallback => {
    try {
      const res = await axios.post(`/account/watcher/delete`, {
        puid: this.puid,
        watcher_id: this.currentWatcherId
      });
      if (res && res.data) {
        runInAction(() => {
          if (res.data.status === true) {
            this.getWatcherList();
            successCallback();
          }
        });
      }
    } catch (error) {
      //errorCallback();
    }
  };

  @observable accessKey = '';
  @observable loadingGenerateAK = false;
  @action
  getAK = async () => {
    const res = await axios.get(`/account/access-key/get`, {
      params: {
        puid: this.puid
      }
    });
    if (res && res.data) {
      runInAction(() => {
        this.accessKey = res.data.access_key;
      });
    }
  };

  @action
  generateAK = async () => {
    this.loadingGenerateAK = true;
    const res = await axios.post(`/account/access-key/set`);
    if (res && res.data) {
      runInAction(() => {
        this.accessKey = res.data.access_key;
        this.loadingGenerateAK = false;
      });
    }
  };

  //#endregion

  //#region -- 报警设置 Alert Setting
  @action
  getContactList = async () => {
    try {
      this.loadingContactList = true;
      const res = await axios.get(`/alert/contacts/my`, {
        params: {
          puid: this.puid
        }
      });
      if (res && res.data) {
        runInAction(() => {
          this.contactList = res.data;
          this.loadingContactList = false;
        });
      }
    } catch (error) {
      this.loadingContactList = false;
    }
  };

  @action
  setAlertModalOpType = opType => {
    this.alertModalOpType = opType;
  };

  @action
  setAlertModalVisible = isShow => {
    this.alertModalVisible = isShow;
  };

  /**
   * 修改报警项
   */
  @action
  updateAlertItem = async (alertItem, successCallback, errorCallback) => {
    try {
      const res = await axios.post(`/alert/setting/update`, {
        puid: this.puid,
        hashrate_enabled: alertItem.hashrateEnabled,
        hashrate: alertItem.hashrate,
        unit: alertItem.hashrateUnit,
        miners_enabled: alertItem.minersEnabled,
        miners: alertItem.miners,
        interval: alertItem.alertInterval
      });
      if (res && res.data) {
        runInAction(() => {
          if (res.data.status === true) {
            message.success(
              new Ts().getLocaleString('pages.settings.alertItemUpdateSuccess')
            );
            successCallback();
          }
        });
      }
    } catch (error) {
      message.error(error.msg);
      if (errorCallback) {
        errorCallback(error.msg);
      }
    }
  };

  /**
   * 新增报警联系人
   */
  @action
  createAlertContact = async ({ name: note, phone, phoneRegion, email }) => {
    try {
      const res = await axios.post(`/alert/contacts/create`, {
        puid: this.puid,
        note,
        email,
        phone,
        region_code: phoneRegion.replace('+', '')
      });
      if (res && res.data) {
        runInAction(() => {
          if (res.data.status === true) {
            this.getContactList();
            this.setAlertModalVisible(false);
          }
        });
      }
    } catch (error) {
      error.msg && message.error(error.msg);
    }
  };

  @action
  setCurrentUpdateAlertContact = data => {
    this.currentUpdateAlertContact = data;
  };

  /**
   * 编辑报警联系人
   */
  @action
  updateAlertContact = async ({
    id,
    name: note,
    phone,
    phoneRegion,
    email
  }) => {
    try {
      const res = await axios.post(`/alert/contacts/update`, {
        puid: this.puid,
        id,
        note,
        email,
        phone,
        region_code: phoneRegion.replace('+', '')
      });
      if (res && res.data) {
        runInAction(() => {
          if (res.data.status === true) {
            this.getContactList();
            this.setAlertModalVisible(false);
          }
        });
      }
    } catch (error) {
      error.msg && message.error(error.msg);
    }
  };

  /**
   * 删除报警联系人
   */
  @action
  deleteAlertContact = async ({ id }) => {
    try {
      const res = await axios.post(`/alert/contacts/delete`, {
        puid: this.puid,
        id
      });
      if (res && res.data) {
        runInAction(() => {
          if (res.data.status === true) {
            this.getContactList();
            this.setAlertModalVisible(false);
          }
        });
      }
    } catch (error) {}
  };
  //#endregion

  //#region -- 已btc结算 Pay with BTC

  @observable paywithModalVisible = false;
  @observable paywithModalOpType = 'paywithbtc-address-input';
  @observable paywithData = { enabled: 0 };
  @observable paywithCoin = 'btc';

  @observable coinCurrencyRate = null;

  /**
   * 获取当前币种(coinType)以某种币种(paywithCoin)支付的信息
   */
  @action
  getPaywithData = async (coinType, paywithCoin) => {
    //this.paywithData = { enabled: true, address: 'xx--xxx' };
    coinType = lowerCase(coinType);
    const res = await axios.get(`/otc/options`, {
      params: { otc_name: coinType, puid: this.puid }
    });
    if (res && res.data && res.data.list) {
      runInAction(() => {
        this.paywithData = res.data.list[paywithCoin]; // {enabled, pool_enabled, address}
      });
    }
  };

  /**
   * 获取当前币种的汇率
   */
  @action
  getCurrencyRate = async coinType => {
    coinType = lowerCase(coinType);
    const res = await axios.get(`/otc/rates`, {
      params: { otc_name: coinType, puid: this.puid }
    });
    if (res && res.data) {
      runInAction(() => {
        if (res.data.list && res.data.list[upperCase(coinType)]) {
          let rate = res.data.list[upperCase(coinType)];
          this.coinCurrencyRate = rate; // {BTC:{BTC, FIAT, CNY,USD,last_updated_at}}
        }
      });
    }
  };

  /**
   * 更新以某币种支付的信息 ：开启、地址
   */
  @action
  updatePaywithInfo = async (
    {
      verifyCode: verify_code,
      userContactType: verify_mode,
      paywithCoin,
      enabled,
      address
    },
    successCallback,
    errorCallback
  ) => {
    paywithCoin = lowerCase(paywithCoin);
    let verify_id = this.verifyUuid ? this.verifyUuid : undefined;
    try {
      const res = await axios.post(`/otc/options`, {
        puid: this.puid,
        verify_code,
        verify_mode,
        verify_id,
        coins: {
          [paywithCoin]: {
            enabled,
            address
          }
        }
      });
      if (res && res.data) {
        runInAction(() => {
          if (res.data.status === true) {
            successCallback && successCallback();
            this.setPaywithModalVisible(false);
          }
        });
      }
    } catch (error) {
      console.log(error);
      if (error.data.new_address) {
        let { newAddress, ...others } = this.validate;
        this.validate = { newAddress: error.msg, ...others };
      } else {
        let { update, ...others } = this.validate;
        this.validate = { update: error.msg, ...others };
      }
      errorCallback && errorCallback();
    }
  };

  @action
  setPaywithModalOpType = opType => {
    this.paywithModalOpType = opType;
  };

  @action
  setPaywithModalVisible = isShow => {
    this.paywithModalVisible = isShow;
  };

  //#endregion

  //#region 算力一键切换 One Switch

  /*
   oneSwitchModalOpType:selected-mode | switching | switched-failed | switched-sucess
   
   */
  @observable oneSwitchModalOpType = 'selected-mode';
  @observable oneSwitchModalVisible = false;
  @observable loadingSwitching = false;

  @action
  setOneSwitchModalOpType = opType => {
    this.oneSwitchModalOpType = opType;
  };

  @action
  setOneSwitchModalVisible = isShow => {
    this.oneSwitchModalVisible = isShow;
  };

  /**
   * 切换算力
   */
  @action
  switchHashrate = async (
    { source, dest, puid = this.puid },
    successCallback,
    errorCallback
  ) => {
    this.loadingSwitching = true;
    try {
      const res = await axios.post(`/change/hashrate`, {
        puid: puid,
        source,
        dest
      });
      this.loadingSwitching = false;
      if (res && res.data) {
        runInAction(() => {
          if (res.data.dest_puid) {
            successCallback && successCallback(res.data);
          }
        });
      }
    } catch (error) {
      let msg = error.msg || '';
      console.log(error);
      this.loadingSwitching = false;
      errorCallback && errorCallback(msg);
    }
  };
  //#endregion
}

const settingsStore = new SettingsStore();

export { settingsStore };
