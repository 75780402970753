import React from 'react';
import './index.scss';
import loaderImg from '../../../images/V2/sp.png';
const PageLoading = props => {
  return <img src={loaderImg} className="loader" alt="loading" />;
};

const loadingContainer = {
  marginTop: 100,
  marginLeft: 'auto',
  marginRight: 'auto',
  height: 360
};

const PageLoadingWrap = props => {
  const { loading, children } = props;
  return (
    <div>
      {loading ? (
        <div style={loadingContainer}>
          <PageLoading />
        </div>
      ) : (
        children
      )}
    </div>
  );
};
export default PageLoadingWrap;
