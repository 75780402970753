import { observable, action, runInAction } from 'mobx';
import axios from 'axios';
import { defaultListPage } from 'config';

class GuardianStore {
  //#region list
  @observable
  loading;

  @observable
  guardianlist;

  @observable
  guardianlistTotal;

  @observable
  guardianlistPage;
  //#endregion

  //#region create Guardian Link
  @observable
  createModalVisible;

  @observable
  loadingCreate;

  @observable
  createLinkStr;
  //#endregion

  //#region Link Op
  @observable
  opInListModalVisisble;

  @observable
  currentOpType;

  @observable
  updateRemarkValue;

  @observable
  currentOpLinkId;

  @observable.deep
  subAccountList;
  //#endregion

  //#region 已接收守护者链接 Other's share account accedpted by me
  @observable
  loadingAcceptedList;

  @observable
  acceptedGuardianList;

  @observable
  acceptedGuardianListTotal;

  @observable
  acceptedGuardianListPage;

  @observable
  opAcceptedModalVisisble;
  //#endregion

  constructor() {
    // List
    this.loading = false;
    this.guardianlist = [];
    this.guardianlistTotal = 0;
    this.guardianlistPage = defaultListPage;

    // Create Link
    this.createModalVisible = false;
    this.loadingCreate = false;
    this.createLinkStr = ''; //创建的链接串

    // Link Op
    this.opInListModalVisisble = false; // list 中操作弹窗
    this.currentOpType = 'manage'; // 'create':创建  'copy':复制链接  delete':删除  'update':'修改备注'  'mamage':管理
    this.currentOpLinkId = null;
    this.updateRemarkValue = '';
    this.subAccountList = [];

    //已接收守护者链接 Other's share account accedpted by me
    this.loadingAcceptedList = false;
    this.acceptedGuardianList = [];
    this.acceptedGuardianListTotal = 0;
    this.acceptedGuardianListPage = Object.assign({}, defaultListPage);
    this.opAcceptedModalVisisble = false;
    //#endregion
  }

  //#region 观察者邀请链接 List， share my account to others
  @action
  getGuardianList = async () => {
    this.loading = true;
    const res = await axios.get(`/guardian/invite/links`, {
      params: {
        ...this.guardianlistPage
      }
    });
    if (res && res.data) {
      runInAction(() => {
        this.guardianlist = res.data.list;
        this.guardianlistTotal = res.data.total_count;
        this.loading = false;
      });
    }
  };

  @action
  setListPage = (page, page_size) => {
    this.guardianlistPage = {
      page,
      page_size
    };
  };
  //#endregion

  //#region Create Link
  @action
  setCreateModalVisible = isShow => {
    this.createModalVisible = isShow;
  };

  @action
  createGuardianLink = async (note, successCallback, errorCallback) => {
    this.loadingCreate = true;
    try {
      const res = await axios.post(`/guardian/invite/link/create`, {
        note
      });
      if (res && res.data) {
        runInAction(() => {
          const { link, link_id } = res.data;
          this.currentOpLinkId = link_id;
          this.createLinkStr = `${window.location.origin}/guardian/bind?genre=invite&token=${link}`;

          this.loadingCreate = false;
          successCallback();
        });
      }
    } catch (error) {
      if (errorCallback) {
        errorCallback(error.msg);
      }
    }
  };
  //#endregion

  //#region Link Op
  /**
   *设置操作类型
   */
  @action
  setOpType = type => {
    this.currentOpType = type;
  };

  @action
  setUpdateRemarkValue = value => {
    this.updateRemarkValue = value;
  };

  /**
   *设置当前操作的Link id
   */
  @action
  setCurrentOpLinkId = id => {
    this.currentOpLinkId = id;
  };

  @action
  setOpInListModalVisisble = isShow => {
    this.opInListModalVisisble = isShow;
  };

  /**
   * 修改备注名称
   */
  @action
  updateGuardianLinkRemark = async (link_id, remark, callback) => {
    // this.loadingCreate = true;
    const res = await axios.post(`/guardian/invite/link/note`, {
      link_id,
      note: remark
    });
    if (res && res.data) {
      runInAction(() => {
        if (res.data.status === true) {
          this.getGuardianList();
          callback();
        }
      });
    }
  };

  /**
   * 删除守护者链接
   */
  @action
  deleteGuardianLink = async (link_id, successCallback, errorCallback) => {
    try {
      // this.loadingCreate = true;
      const res = await axios.post(`/guardian/invite/link/delete`, {
        link_id
      });
      if (res && res.data) {
        runInAction(() => {
          if (res.data.status === true) {
            this.getGuardianList();
            successCallback();
          }
        });
      }
    } catch (error) {
      if (errorCallback) {
        errorCallback(error.msg);
      }
    }
  };

  //#endregion

  //#region SubAccount Manage
  @action
  getSubAccountsByLinkId = async () => {
    const res = await axios.get(`/guardian/invite/link/subaccounts`, {
      params: {
        link_id: this.currentOpLinkId
      }
    });
    if (res && res.data) {
      runInAction(() => {
        this.subAccountList = res.data.map(s => ({
          loadingSwitch: false,
          ...s
        }));
      });
    }
  };

  @action
  setSubAccountItemGuard = async (is_guard, subPuid) => {
    this.updateSubAccountLoading(subPuid, true);
    const res = await axios.post(`/guardian/invite/link/subaccount`, {
      link_id: this.currentOpLinkId,
      puid: subPuid,
      is_guard
    });
    if (res && res.data) {
      runInAction(() => {
        if (res.data.status === true) {
          this.getSubAccountsByLinkId();
        }
      });
    }
  };

  @action
  updateSubAccountLoading = (subPuid, isLoading) => {
    let tempList = this.subAccountList.slice();
    tempList = tempList.map(t => ({
      ...t,
      loadingSwitch: t.puid === subPuid ? isLoading : t.loadingSwitch
    }));
    this.subAccountList = tempList;
  };
  //#endregion

  //#region 已接收守护者链接 Other's share account accedpted by me

  @action
  getAcceptGuardianList = async (triggerLoading = true) => {
    if (triggerLoading) {
      this.loadingAcceptedList = true;
    }
    const res = await axios.get(`/guardian/links`, {
      params: {
        ...this.acceptedGuardianListPage
      }
    });
    if (res && res.data) {
      runInAction(() => {
        this.acceptedGuardianList = res.data.list;
        this.acceptedGuardianListTotal = res.data.total_count;
        this.loadingAcceptedList = false;
      });
    }
  };

  /**
   * 删除已接收守护者链接
   */
  @action
  deleteAcceptedGuardianLink = async (link_id, callback) => {
    // this.loadingCreate = true;
    const res = await axios.post(`/guardian/link/delete`, {
      link_id
    });
    if (res && res.data) {
      runInAction(() => {
        if (res.data.status === true) {
          this.getAcceptGuardianList();
          callback && callback();
        }
      });
    }
  };

  /**
   * 显示/隐藏已接收守护者链接
   */
  @action
  toggleAcceptedGuardian = async (link_id, is_show, callback) => {
    this.acceptedGuardianList.forEach(item => {
      if (link_id === item.id) {
        item.loadingToggle = true;
      }
    });
    const res = await axios.post(`/guardian/link/show`, {
      link_id,
      is_show
    });
    if (res && res.data) {
      runInAction(() => {
        if (res.data.status === true) {
          this.getAcceptGuardianList(false);
          callback && callback();
        }
      });
    }
  };

  /**
   * 修改接收到的共享账户备注名称
   */
  @action
  updateAcceptedGuardianLinkRemark = async (link_id, remark, callback) => {
    const res = await axios.post(`/guardian/link/note`, {
      link_id,
      note: remark
    });
    if (res && res.data) {
      runInAction(() => {
        if (res.data.status === true) {
          this.getAcceptGuardianList(false);
          callback && callback();
        }
      });
    }
  };

  @action
  setAcceptGuardianListPage = (page, page_size) => {
    this.acceptedGuardianListPage = {
      page,
      page_size
    };
  };

  @action
  setOpAcceptedModalVisisble = isShow => {
    this.opAcceptedModalVisisble = isShow;
  };
  //#endregion
}

const guardianStore = new GuardianStore();

export { guardianStore };
