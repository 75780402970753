import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import Ts from 'localeTranslator';
import { Icon, Dropdown, Tooltip } from 'antd';
import './index.scss';
import HelpTip from './section/helpTip';
import UserListManageButton from './section/userBtn';
import AccountList from '../../../Account/list';
import { PageLoadingWrap } from '@/components/widget';
import { I18N_LANG } from '@/utils/constants';
import { upperCase } from '@/utils';
import sensors from 'sa-sdk-javascript';
import ReactGA from 'react-ga';
@inject('store')
@observer
class UserList extends Component {
  constructor(props) {
    super(props);
    this.appStore = this.props.store.appStore;
    this.userStore = this.props.store.userStore;
  }

  state = {
    visible: false
  };

  getUserOverlay = accountInfo => {
    const { algorithmAccounts } = this.userStore;
    return (
      <div className="header__user-content">
        <div className="user-content__title">
          <Ts
            id="pages.account.currentAccount"
            defaultMessage="Current Account"
          />
          {': '}
          {accountInfo.account.name}
        </div>
        <HelpTip />
        <UserListManageButton
          onClick={e => {
            this.setState({ visible: false });
          }}
        />
        <PageLoadingWrap loading={algorithmAccounts.firstTimeLoading}>
          <AccountList
            className={'user-content__user-list'}
            afterChangeAccount={this.afterChangeAccount}
          />
        </PageLoadingWrap>
      </div>
    );
  };

  afterChangeAccount = () => {
    this.setState({
      visible: false
    });
  };

  onUserClick = () => {
    this.setState({ visible: !this.state.visible });
  };

  onUserVisibleChange = visible => {
    this.setState({
      visible
    });
    if (visible) {
      this.userStore.getSubAccountList();
      const listDom = document.querySelector('.user-content__user-list');

      if (listDom) {
        setTimeout(() => {
          listDom.scrollTo(0, 0);
        }, 0);
      }
    }
  };

  accountDisplay() {
    const { accountInfo } = this.userStore;
    const { lang } = this.appStore;
    let name = accountInfo.name;
    if (name && name.length > 9) {
      name = `${name.slice(0, 3)}...${name.slice(name.length - 4)}`;
    }
    return (
      <span>
        <span title={accountInfo.name}>{name}</span>
        {accountInfo.coin_type && ' @' + accountInfo.coin_type}
        {accountInfo.region_name && accountInfo.region_name === 'bitdeer'
          ? ` - ${upperCase(accountInfo.region_name)}`
          : accountInfo.region_conf.text &&
            ` - ` +
              accountInfo.region_conf.text[
                lang === I18N_LANG.zh ? 'zh-cn' : 'en'
              ]}
      </span>
    );
  }

  clickEvent = eventName => {
    const { lang } = this.appStore;
    const { accountInfo } = this.userStore;
    this.clickRowTime = Date.now();
    const viewTimeCost = this.clickRowTime - this.startViewTime; // 载入到点击按钮时间差
    if (accountInfo.coin_type && accountInfo.region_conf.text) {
      sensors.track(eventName, {
        coin_type: accountInfo.coin_type,
        region:
          accountInfo.region_conf.text[lang === I18N_LANG.zh ? 'zh-cn' : 'en']
      });
      this.sendEvent(
        eventName,
        'TimeCostFromViewTo' + eventName,
        parseInt(viewTimeCost / 1000)
      );
    }
  };

  sendEvent = (eventAction, eventLabel, eventValue) => {
    ReactGA.event(
      {
        category: 'Account',
        action: eventAction,
        label: eventLabel,
        value: eventValue
      },
      ['poolGA']
    );
    global._hmt &&
      global._hmt.push([
        '_trackEvent',
        'Account',
        eventAction,
        eventLabel,
        eventValue
      ]);
  };

  readonlyUserDisplay(account) {
    const { isUserReadOnly, isWatcher, isShared } = this.userStore;
    if (isUserReadOnly) {
      if (isWatcher) {
        return (
          <Fragment>
            <span>
              {account} (
              <Ts id="common.header.watcher" /> )
            </span>
            <Tooltip title={<Ts id="common.header.watcherPermission" />}>
              <i className="icon-info gap-l" />
            </Tooltip>
          </Fragment>
        );
      } else if (isShared) {
        return (
          <Fragment>
            <span>
              {account} (
              <Ts id="common.header.guardian" /> )
            </span>
            <Tooltip title={<Ts id="common.header.guardianPermission" />}>
              <i className="icon-info gap-l" />
            </Tooltip>
          </Fragment>
        );
      }
    }
    return null;
  }

  componentDidMount() {
    this.startViewTime = Date.now();
  }

  render() {
    const { accountInfo, isUserReadOnly } = this.userStore;
    const account = this.accountDisplay();
    const readonlyUser = this.readonlyUserDisplay(account);

    if (!accountInfo.puid) return;
    // 小鹿分支只显示观察者
    return isUserReadOnly && readonlyUser ? (
      <div className="header-right-user"> {readonlyUser} </div>
    ) : null;
  }
}

export default UserList;
