import { appStore } from './AppStore';
import { playgroundStore } from './PlaygroundStore';
import { dashboardStore } from './DashboardStore';
import { minersStore } from './MinersStore';
import { minerInfoStore } from './MinerInfoStore';
import userStore from './UserStore';
import { earningHistoryStore } from './EarningHistoryStore';
import { poolStatsStore } from './PoolStatsStore';
import { guardianStore } from './GuardianStore';
import { guardianBindStore } from './GuardianBindStore';
import { subAccountStore } from './SubAccountStore';
import { settingsStore } from './SettingsStore';
import { homepageStore } from './HomepageStore';
import { transactionHistoryStore } from './TransactionHistoryStore';

export default {
  appStore,
  dashboardStore,
  playgroundStore,
  minersStore,
  minerInfoStore,
  userStore,
  earningHistoryStore,
  poolStatsStore,
  guardianStore,
  guardianBindStore,
  subAccountStore,
  settingsStore,
  homepageStore,
  transactionHistoryStore
};
