import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import { List, Icon } from 'antd';
import Ts from 'localeTranslator';
import { computed, reaction } from 'mobx';
import CoinAccountItem from './coinAccountItem';
import SwitchCoins from './switchCoins';
import './index.scss';
import { coinsConfig } from '@/utils/config';

@inject('store')
@observer
class CoinListCard extends Component {
  constructor(props) {
    super(props);
    this.appStore = this.props.store.appStore;
    this.userStore = this.props.store.userStore;
    reaction(
      () => {
        return this.props.showAll;
      },
      showAll => {
        this.setState({
          isShowAll: showAll
        });
      }
    );
  }
  static defaultProps = {
    showAll: true
  };

  state = {
    isShowAll: this.props.showAll
  };

  @computed
  get filteredCoinAccounts() {
    let result = [];
    const { algorithm } = this.props;
    const coinAccounts = algorithm.coin_accounts;
    if (coinAccounts) {
      result = coinAccounts;
      // .filter(account => {
      //   return account.is_current === 1 || this.state.isShowAll;
      // });
    }
    return result;
  }

  // 筛选可切换的币种
  @computed get filtedCoins() {
    const { algorithm } = this.props;
    return (
      algorithm.support_coins.filter(coin => {
        return (
          (coinsConfig[coin.toLowerCase()].showSwitch &&
            !['auto', 'bsv'].includes(coin.toLowerCase())) ||
          false
        );
      }) || []
    );
  }

  toggleOpen = isShowAll => {
    return () => {
      this.setState({
        isShowAll: !this.state.isShowAll
      });
      // this.store.setAccountOpen(
      //   value,
      //   data.name,
      //   data.region_text,
      //   algorithm_name
      // );
    };
  };

  onChangeAccount = coinAccount => {
    const { changeAccount } = this.userStore;
    changeAccount(coinAccount);
  };

  render() {
    const { algorithm, isShowSetting, isShowHidden, toggleWrap } = this.props;
    const { isShowAll } = this.state;

    return (
      <div className="user-list__inner">
        <List
          className="user-list__coin-list"
          size="small"
          header={
            <div className="user-list__coin-action">
              <span>
                <b>{algorithm.algorithm_text.toUpperCase()}</b>&nbsp;{' '}
                <Ts id="pages.account.algorithm" defaultMessage={'Algorithm'} />
              </span>
              {this.filtedCoins.length > 0 && !isShowHidden && (
                <Fragment>
                  <span>:</span>
                  <SwitchCoins
                    supportCoins={this.filtedCoins}
                    currentCoin={algorithm.current_coin}
                    currentPuid={algorithm.current_puid}
                    coin_accounts={algorithm.coin_accounts}
                    toggleWrap={toggleWrap}
                  />
                </Fragment>
              )}
            </div>
          }
          footer={
            <div
              className="user-list__footer"
              onClick={this.toggleOpen(isShowAll)}
            >
              <Icon type={isShowAll ? 'up' : 'down'} />
            </div>
          }
          bordered
          dataSource={this.filteredCoinAccounts}
          renderItem={(coinAccount, index) => (
            <Fragment>
              <List.Item
                style={{
                  display:
                    coinAccount.is_current === 1 || this.state.isShowAll
                      ? 'block'
                      : 'none'
                }}
              >
                <CoinAccountItem
                  key={index}
                  region_text={this.props.region_text}
                  coinAccount={coinAccount}
                  isShowSetting={isShowSetting}
                  isShowHidden={isShowHidden}
                  reloadList={this.props.reloadList}
                  afterChangeAccount={this.props.afterChangeAccount}
                />
              </List.Item>
            </Fragment>
          )}
        />
        {/* <div className="user-list__footer">
          <Icon type="up" />
        </div> */}
        {/* <div className="user-list__coin-list">
          {algorithm.coin_accounts.map((coinAccount, index) => {
            return (
              <CoinAccountItem
                key={index}
                coinAccount={coinAccount}
                isShowSetting={isShowSetting}
              />
            );
          })}
          <div
            height="25px"
            className={algorithm.isVisible ? 'act-show transform' : 'act-show'}
            onClick={this.toggleOpen(
              algorithm.isVisible,
              algorithm.algorithm_name
            )}
          />
        </div> */}
      </div>
    );
  }
}

export default CoinListCard;
