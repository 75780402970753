import { BigNumber } from 'bignumber.js';
import { default as coinsConfig } from './config/coins';
import { HASHRATE_UNIT_MAPPING } from '@/utils/constants';
import moment from 'moment';
import 'moment/locale/zh-cn';

export const isNull = s => {
  return s === null || s === '' || s === undefined;
};

/**
 * get hashrate unit by coin type : EH/s
 * @param {string} coinType
 */
export const hashrateUnit = (coinType, unit) => {
  let hashrateUnit = unit
    ? unit
    : coinsConfig[lowerCase(coinType)].hashrateScale;
  return hashrateUnit + coinsConfig[lowerCase(coinType)].hashrateUnit;
};

/**
 * get hashrate unit name by coin type : H/s
 * @param {string} coinType
 */
export const hashrateUnitName = coinType => {
  return coinsConfig[lowerCase(coinType)].hashrateUnit;
};

/**
 * 格式化数据，保留一定位小数(数据精度已超过js表示时使用)
 * @param {string|number} value 要格式化的值
 * @param {number} decimalPlaces 保留的小数位数
 */
export const formatBigNumber = (value, decimalPlaces) => {
  try {
    if (isNaN(value) || value === '' || value === null || value === undefined) {
      return '';
    }
    if (value === '0' || value === 0) {
      return 0;
    }
    let result = '';
    result = BigNumber(value);

    return result.toFormat(decimalPlaces).toString();
  } catch (error) {
    return '';
  }
};

/**
 * 格式化数据，保留一定位小数
 * @param {string|number} value 要格式化的值
 * @param {number} decimalPlaces 保留的小树位数
 */
export const formatNumber = (value, decimalPlaces) => {
  let result = '';
  try {
    result = parseFloat(value).toFixed(decimalPlaces);
  } catch (error) {}
  return result;
  // return '';
};

/**
 * 数字千分位
 */
export const numberWithCommas = x => {
  var parts = x.toString().split('.');
  if (parts.length > 0) {
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  }
  return '';
};

/**
 * 数字百分化
 * @param {number|string} n
 * @param {bool} isNeedMult100
 */
export const formatPercent = (n, decimalPlaces = 0, isNeedMult100 = false) => {
  if (n === 0 || n === '0' || isNull(n)) {
    return n;
  }
  return (
    formatNumber(isNeedMult100 ? Number(n) * 100 : Number(n), decimalPlaces) +
    '%'
  );
};

/**
 * 时间格式化
 * @param {*} time
 * @param {*} formatter
 */
export const formatDate = (
  time = undefined,
  formatter = 'YYYY-MM-DD HH:mm'
) => {
  if (
    time === undefined ||
    time === null ||
    time === '0' ||
    time === 0 ||
    time === ''
  ) {
    return '-';
  }
  try {
    return moment(time).format(formatter);
  } catch (error) {
    return '';
  }
};

/**
 * 时间格式化 2019-03-07T09:37:49Z -> 2019-03-07 09:37:49
 * @param {*} time
 */
export const formatDateStr = time => {
  return time.replace('T', ' ').replace('Z', '');
};

/**
 * 时间格式化 将后端返回的utc时间字符串转换为本地的时间字符串
 * 2019-03-07 00:00:00 -> 2019-03-07 08:00:00
 * @param {string} utcDateStr
 * @param {string} utcDateStr
 */
export const formatUtcDateStr2LocaleDate = (
  utcDateStr,
  formatter = 'YYYY-MM-DD HH:mm:ss'
) => {
  if (isNull(utcDateStr) || utcDateStr === '-') {
    return '-';
  }
  try {
    let date = new Date(`${utcDateStr} UTC`.replace(/-/g, '/')); // support safari
    return moment(date).format(formatter);
  } catch (error) {
    return '-';
  }
};
/**
 * 格式化算力（难度）
 * @param {*} v
 * @param {*} decimalPlaces
 */
export const formatHashrate = (v, decimalPlaces = 0) => {
  var unit = ['', 'K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];
  var index = 0;
  if (isNaN(v)) {
    return '';
  }

  if (v === 0 || v === '' || v === undefined) {
    v = 0;
  } else {
    while (v >= 1000) {
      v = v / 1000;
      index++;
    }
  }

  return parseFloat(v).toFixed(decimalPlaces) + ' ' + unit[index];
};

export const formatToHashrateScale = (v, targetScale, decimalPlaces = 0) => {
  var scaleUnits = ['', 'K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];
  var index = 0;
  const targetIndex = scaleUnits.indexOf(targetScale);
  if (isNaN(v)) {
    return '';
  }

  if (v === 0 || v === '' || v === undefined) {
    v = 0;
  } else {
    while (index < scaleUnits.length) {
      if (targetIndex === index) {
        break;
      }
      v = v / 1000;
      index++;
    }
  }

  return parseFloat(v).toFixed(decimalPlaces);
};

export const nearestHashrateScaleUnit = (
  v,
  type = 'bigger',
  returnType = 'unitName'
) => {
  if (type === 'bigger') {
    if (v >= HASHRATE_UNIT_MAPPING.maxScaleUnit.value) {
      if (returnType === 'unitName') {
        return HASHRATE_UNIT_MAPPING.maxScaleUnit.key;
      } else {
        return HASHRATE_UNIT_MAPPING.maxScaleUnit.value;
      }
    } else {
      // BigNumber(result)
      // .div(HASHRATE_UNIT_MAPPING[hashrateScale])
    }
  } else {
    if (v <= HASHRATE_UNIT_MAPPING.minScaleUnit.value) {
      if (returnType === 'unitName') {
        return HASHRATE_UNIT_MAPPING.minScaleUnit.key;
      } else {
        return HASHRATE_UNIT_MAPPING.minScaleUnit.value;
      }
    }
  }
};

/**
 * 获取一定时间范围和当前时间的时间差
 * @return 时间戳（秒）
 * @param {string} range
 * @param {string} type
 */
export const getCurrentDiff = (range, type) => {
  return Math.round(
    moment()
      .subtract(range, type)
      .valueOf() / 1000
  );
};

/**
 * 传入时间范围字符串 返回图表所需的起始时间，坐标点数，坐标格式化
 * @param {string} timeRange
 */
export const getTimeRangeMap = timeRange => {
  let mapper = {
    '1d': {
      start_ts: getCurrentDiff(30, 'days'),
      count: 30,
      xAixsFormatter: 'MM/DD',
      xAixsFormatterLong: 'YYYY/MM/DD'
    },
    '1h': {
      start_ts: getCurrentDiff(72, 'hours'),
      count: 72,
      xAixsFormatter: 'HH:mm',
      xAixsFormatterLong: 'YYYY/MM/DD HH:mm'
    }
  };
  return mapper[timeRange];
};

/**
 *  String lowerCase
 * @param {string} str
 */
export const lowerCase = (str = '') => {
  return str.toLowerCase();
};

/**
 *  String upperCase
 * @param {string} str
 */
export const upperCase = (str = '') => {
  return str.toUpperCase();
};

/**
 * 超过一定长度省略中间部分
 * @param {string} str
 * @param {number} length
 */
export const truncString = (str, length = 40, placeholder = '......') => {
  return str.length > length
    ? str.substr(0, length / 2) +
        placeholder +
        str.substr(str.length - length / 2, str.length)
    : str;
};

export const getCurrency = lang => {
  return lang === 'zh-CN' ? 'CNY' : 'USD';
};

/**
 * 兼容后端接口的语言格式 后端支持： zh-cn | en
 * @param {string} lang
 */
export const parseLang = lang => {
  return lowerCase(lang) === 'zh-cn' ? 'zh-cn' : 'en';
};

export const formatFromNowDate = (v, lang, fromOrTo = 'to') => {
  if (v === undefined || v.toString() === '0' || v === '') {
    return '-';
  } else {
    moment.locale(lowerCase(lang));

    return fromOrTo === 'to'
      ? moment(moment.unix(v), 'YYYYMMDD').to(true)
      : moment(moment.unix(v), 'YYYYMMDD').fromNow(true);
  }
};

/**
 * 从某个时间戳到现在的时间格式转换
 * @param {number} v :ms
 * @param {string} lang
 */
export const formatTimestampFrom = (v, lang) => {
  if (v === undefined || v.toString() === '0' || v === '') {
    return '-';
  } else {
    moment.locale(lowerCase(lang));

    return moment().from(v, true);
  }
};

/**
 *  判断币种是否是GRIN
 * @param {string} coinType
 */
export const isGrin = coinType => {
  return upperCase(coinType) === 'GRIN';
};

/**
 *  判断币种是否是BTC
 * @param {string} coinType
 */
export const isBTC = coinType => {
  return upperCase(coinType) === 'BTC';
};

/**
 *  判断币种是否是BEAM
 * @param {string} coinType
 */
export const isBeam = coinType => {
  return upperCase(coinType) === 'BEAM';
};

/**
 *  判断币种是否是 DCR
 * @param {string} coinType
 */
export const isDcr = coinType => {
  return upperCase(coinType) === 'DCR';
};

/**
 *  判断币种是否是 UBTC
 * @param {string} coinType
 */
export const isUbtc = coinType => {
  return upperCase(coinType) === 'UBTC';
};

/**
 *  校验判断是否是中国手机号
 * @param {string} phoneNum
 */
export const isChinesePhone = phoneNum => {
  // eslint-disable-next-line
  let reg = /^(?:\+?86)?1(?:3\d{3}|5[^4\D]\d{2}|8\d{3}|7(?:[35678]\d{2}|4(?:0\d|1[0-2]|9\d))|9[189]\d{2}|66\d{2})\d{6}$/;
  return reg.test(phoneNum);
};

/**
 *  校验判断是否是国际区号
 * @param {string} regionNum
 */
export const isIntlPhoneRegion = regionNum => {
  // eslint-disable-next-line
  let reg = /(?:\(?[0\+]?\d{1,3}\)?)[\s-]?/;
  return reg.test(regionNum);
};

/**
 *  校验邮箱格式
 * @param {string} phoneNum
 */
export const isEmail = email => {
  // eslint-disable-next-line
  var reg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(email);
};

/**
 *  获取所有币种配置Key值
 */
export const getAllCoins = () => {
  return Object.keys(coinsConfig);
};

/**
 *  获取图表上拒绝率坐标的最大值
 */
export const getChartMaxReject = rejectDatas => {
  let maxItem = Math.max.apply(null, rejectDatas);
  return maxItem > 10 ? 100 : 10;
};

/**
 * 轮询
 * @param {Function} func 轮询方法
 * @param {Number} interval 轮询时间
 */
export const pollingFunc = (func, interval = 3000) => {
  func();
  return setInterval(() => {
    func();
  }, interval);
};

/**
 * 显示短地址
 * @param {number} address 地址
 */
export const toShortAddr = (address, length = 6) => {
  if (address) {
    if (address.length > length * 2) {
      return `${address.substring(0, length)}...${address.substr(-length)}`;
    } else {
      return address;
    }
  }
  return '';
};
