import { observable, action, runInAction } from 'mobx';
import axios from 'axios';

class GuardianBindStore {
  //#region init data
  @observable
  loading = false;
  @observable
  initData = { genre: 'invite', token: null };
  @observable
  notAllowModalVisible = false;
  @observable
  notAllowMessage = '';
  //#endregion

  //#region guardian invite bind
  @observable
  bindLoading = true;

  @observable
  bindErrorMsg = '';
  //#endregion

  //#region guardian invite bind data init
  @action
  setInitData = ({ genre, token }) => {
    this.initData = { genre, token };
  };

  /**
   * 获取bind初始信息
   */
  @action
  getGuardianInviteBindInitData = async ({ genre, token }) => {
    try {
      this.loading = true;
      this.setInitData({ genre, token });
      const res = await axios.post(`/guardian/invite/link/bind/init`, {
        token
      });
      this.loading = false;
      if (res && res.data) {
        runInAction(() => {
          const { contact, mode } = res.data;
          this.initData = { ...this.initData, genre, token, contact, mode };
        });
      }
    } catch (error) {
      this.notAllowMessage = error.msg;
      this.showNotAllowModal();
    }
  };

  showNotAllowModal = () => {
    this.notAllowModalVisible = true;
  };

  hideNotAllowModal = () => {
    this.notAllowModalVisible = false;
  };
  //#endregion

  //#region guardian invite bind
  /**
   * 绑定守护者邀请链接
   */
  @action
  guardianInviteBind = async (note, successCallback) => {
    try {
      this.bindLoading = true;
      this.bindErrorMsg = '';
      const res = await axios.post(`/guardian/invite/link/bind`, {
        token: this.initData.token,
        note
      });
      if (res && res.data) {
        runInAction(() => {
          this.bindErrorMsg = '';
          successCallback && successCallback();
        });
      }
    } catch (error) {
      this.notAllowMessage = error.msg;
      this.showNotAllowModal();
    }
  };

  //#endregion
}

const guardianBindStore = new GuardianBindStore();

export { guardianBindStore };
