import { observable, action, reaction, runInAction } from 'mobx';
import axios from 'axios';
import { announcementQueryURL } from 'config';
import { getTimeRangeMap } from 'utils';
class DashboardStore {
  @observable
  announcementList = [];

  @observable
  hashrate = {
    shares_1d: 0,
    shares_1d_unit: '',
    shares_15m: 0,
    shares_15m_unit: ''
  };

  @observable showMinerTip = false;
  @observable minersStats = {
    workers_active: 0,
    workers_inactive: 0,
    workers_total: 0
  };

  @observable earnStats = {
    earnings_today: 0,
    earnings_yesterday: 0,
    hashrate_yesterday: {
      size: 0,
      unit: ''
    },
    amount_100t: 0,
    unpaid: 0,
    total_paid: 0,
    last_payment_time: '',
    pending_payouts: 0
  };

  @observable
  chartTimeRange = '1h';

  @observable
  loadingHashrateHistory = false;
  @observable
  hashrateHistoryChartData = [];
  @observable
  sharesUnit = '';

  @observable loadingMinerGroups = false;
  @observable
  minerGroups = [];

  @observable
  allMiningUrlList = [];

  @observable
  networkStatus = {};

  constructor() {
    reaction(
      () => this.chartTimeRange,
      chartTimeRange => {
        this.getHashrateHistory(chartTimeRange);
      }
    );
  }

  /**
   * 通知公告
   */
  @action
  getAnnouncement = async lang => {
    const res = await axios.get(`${announcementQueryURL[lang]}`, {
      baseURL: announcementQueryURL.baseURL
    });
    if (res) {
      runInAction(() => {
        let { articles } = res;
        this.filterAnnouncementList(articles, lang);
      });
    }
  };

  @action
  filterAnnouncementList = (announceList, lang) => {
    let hiddenNews = localStorage.getItem('hiddenNews')
      ? JSON.parse(localStorage.getItem('hiddenNews'))[lang]
      : [];

    announceList = announceList.filter(a => hiddenNews.indexOf(a.id) < 0);
    this.announcementList = announceList;
  };

  @observable loadingRealHashrate = false;
  /**
   * 实时算力
   */
  @action
  getHashrate = async (triggerLoading = true) => {
    if (triggerLoading) {
      this.loadingRealHashrate = true;
    }
    const res = await axios.get(`realtime/hashrate`);
    this.loadingRealHashrate = false;
    if (res && res.data) {
      runInAction(() => {
        this.hashrate = res.data;
      });
    }
  };

  @observable loadingMinersStats = false;
  /**
   * 矿机统计
   */
  @action
  getMinersStats = async (triggerLoading = true) => {
    if (triggerLoading) {
      this.loadingMinersStats = true;
    }
    const res = await axios.get(`/worker/stats`);
    this.loadingMinersStats = false;
    if (res && res.data) {
      runInAction(() => {
        this.minersStats = res.data;
        this.showMinerTip = this.minersStats.workers_total === 0;
      });
    }
  };

  @observable loadingEarnStats = false;
  /**
   * 收益统计
   */
  @action
  getEarnStats = async (triggerLoading = true) => {
    if (triggerLoading) {
      this.loadingEarnStats = true;
    }
    const res = await axios.get(`/account/earn-stats`, {
      params: {
        is_decimal: 1
      }
    });
    this.loadingEarnStats = false;
    if (res && res.data) {
      runInAction(() => {
        this.earnStats = res.data;
      });
    }
  };

  //#region 算力图表
  @action
  setChartTimeRange = timeRange => {
    this.chartTimeRange = timeRange;
  };

  /**
   * 算力图表
   * @param {boolean} triggerLoading  是否触发加载效果
   */
  @action
  getHashrateHistory = async (triggerLoading = true) => {
    if (triggerLoading) {
      this.loadingHashrateHistory = true;
    }

    let timeRangeMap = getTimeRangeMap(this.chartTimeRange);
    const { start_ts, count } = timeRangeMap;
    const res = await axios.get(`worker/share-history`, {
      params: {
        dimension: this.chartTimeRange,
        start_ts,
        real_point: 1,
        count
      }
    });
    if (res && res.data) {
      runInAction(() => {
        this.sharesUnit = res.data.shares_unit;
        this.hashrateHistoryChartData = res.data.tickers.map(t => {
          return {
            time: t[0] * 1000, //formatDate(t[0] * 1000, xAixsFormatter), //t[0] * 1000
            hashrate: Number(t[1]),
            reject_percent: Number(t[2]) * 100
          };
        });
        this.loadingHashrateHistory = false;
      });
    }
  };

  //#endregion

  //#region 矿机分组
  /**
   * 矿机分组
   * @param {boolean} triggerLoading  是否触发加载效果
   * @param {boolean} isQueryDayHash  是否请求日算力字段，由于后端接口性能问题，第一次请求不请求日算力会提高性能，在第一次请求成功后successCallback再请求一次加上算力
   */
  @action
  getMinerGroups = async (
    triggerLoading,
    isQueryDayHash = false,
    successCallback
  ) => {
    if (triggerLoading) {
      this.loadingMinerGroups = true;
    }
    const res = await axios.get(`/worker/groups`, {
      params: {
        page_size: 20,
        is_show_1d: isQueryDayHash ? 1 : 0
      }
    });
    this.loadingMinerGroups = false;
    if (res && res.data) {
      runInAction(() => {
        let tempList = res.data.list
          .filter(item => item.gid !== 0)
          .sort((a, b) => a.sort_id - b.sort_id);
        this.minerGroups = tempList;

        successCallback && successCallback();
      });
    }
  };

  //#endregion

  //#region 挖矿地址
  /**
   * 挖矿地址
   */
  @action
  getMinerUrlConfig = async () => {
    const res = await axios.get(`/pool/url-config`);
    if (res && res.data) {
      runInAction(() => {
        this.allMiningUrlList = res.data;
      });
    }
  };
  //#endregion

  //#region 网络状态
  /**
   * 网络状态
   * 以当前币种为btc为例：需要的networkStatus内容为：
  
   networkStatus:{
      btc:{
          hashrate,           //全网算力
          income_coin,        //BTC收益
          diff,               //当前难度
          next_diff,          //预测下次难度
          diff_adjust_time    //距离调整时间，时间戳
      },
      bch:{
          income_coin,        //BCH收益
      },
      ...
      pool_hashrate,          //矿池算力
      pool_hashrate_unit      //矿池算力单位
   }
   
   */
  @action
  getNetworkStatus = async coin_type => {
    //let networkStatus = {};

    const coinIncomeRes = await axios.get(`/coins-income`);
    if (coinIncomeRes && coinIncomeRes.data) {
      runInAction(() => {
        this.networkStatus = { ...coinIncomeRes.data };
      });
    }
    const poolStatsRes = await axios.get(`public/v1/pool/stats/merge`, {
      params: {
        coin_type
      }
    });
    if (poolStatsRes && poolStatsRes.data) {
      const { shares, reject } = poolStatsRes.data;

      runInAction(() => {
        if (shares) {
          this.networkStatus = {
            ...this.networkStatus,
            pool_hashrate: (shares.shares_15m * (1 - reject['15m'])).toFixed(2),
            pool_hashrate_unit: shares.shares_unit
          };
        }
      });
    }
  };
  //#endregion
}

const dashboardStore = new DashboardStore();

export { dashboardStore };
