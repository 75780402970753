import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { message, Tooltip, Icon, Typography } from 'antd';
import PropTypes from 'prop-types';
import Ts from 'localeTranslator';
import { coinsConfig } from '@/utils/config';
import LoadingWrap from '@/components/widget/LoadingWrap';
import Setting from '@/components/Header/section/setting';
import { I18N_LANG } from '@/utils/constants';
import { formatDate } from '@/utils';
const { Text } = Typography;
@inject('store')
@observer
class CoinAccountItem extends Component {
  static contextTypes = { intl: PropTypes.object };
  constructor(props) {
    super(props);
    this.appStore = this.props.store.appStore;
    this.userStore = this.props.store.userStore;
  }

  static defaultProps = {
    isShowSetting: false,
    isShowHidden: false
  };

  state = {
    menuVisible: null
  };

  getHashrate = coinAccount => {
    const { hashrateMiners } = this.userStore;
    const { hashrateScale, hashrateUnit } = coinsConfig[
      coinAccount.coin_type.toLowerCase()
    ];
    let accountHashrate = null;
    if (hashrateMiners) {
      // TODO: shares_1d_unit\hashrate_suffix算力单位应该跟币种配置来
      accountHashrate = {
        ...{
          shares_1d: 0,
          shares_1d_unit: hashrateScale,
          hashrate_suffix: hashrateUnit,
          workers_active: 0,
          workers: 0,
          is_current: 0,
          latest_alert: {
            trans: ''
          }
        },
        ...accountHashrate,
        ...hashrateMiners[coinAccount.puid]
      };
    }

    return accountHashrate;
  };

  onChangeAccount = coinAccount => {
    const { changeAccount } = this.userStore;
    const { lang } = this.appStore;
    if (this.props.afterChangeAccount) {
      this.props.afterChangeAccount();
    }
    const serverLang = lang === I18N_LANG.zh ? 'zh-cn' : 'en';
    changeAccount({
      ...coinAccount,
      region_conf: {
        text: {
          [serverLang]: this.props.region_text
        }
      }
    });
  };

  onRecoverAccount = async coinAccount => {
    const { formatMessage } = this.context.intl;

    try {
      const res = await this.userStore.recoverAccount(coinAccount.puid);

      if (res && res.data) {
        message.success(formatMessage({ id: 'common.operation.success' }));
        this.props.reloadList();
      }
    } catch (error) {
      message.error(formatMessage({ id: 'common.operation.failed' }));
    }
  };

  render() {
    const self = this;
    const { hashrateMiners } = this.userStore;
    const { coinAccount, isShowSetting, isShowHidden } = this.props;
    const accountHashrate = this.getHashrate(coinAccount);
    const isLoadingHashrate = !hashrateMiners[coinAccount.puid];

    let accountTrans = '-';
    const latestAlert = accountHashrate.latest_alert;
    if (coinAccount.is_current !== 0) {
      const time = formatDate(
        latestAlert.created_timestamp * 1000,
        'YYYY-MM-DD HH:mm:ss'
      );
      if (latestAlert.type) {
        let vals = {
          time: time,
          expect: latestAlert.expect,
          symbol: '<='
        };
        if (latestAlert.type === 'hashrate') {
          vals.unit = latestAlert.unit;
        }
        const actual = parseFloat(latestAlert.actual);
        const expect = parseFloat(latestAlert.expect);
        if (actual < expect) {
          vals.symbol = '<';
        } else if (actual === expect) {
          vals.symbol = '=';
        }

        accountTrans = (
          <Tooltip title={time}>
            <div>
              <Icon type="alert" theme="twoTone" twoToneColor="#FAAD14" />
              <Text>
                <Ts
                  type="html"
                  id={`pages.account.latestAlert.${latestAlert.type}`}
                  values={vals}
                />
              </Text>
            </div>
          </Tooltip>
        );
      } else if (latestAlert.trans) {
        accountTrans = latestAlert.trans;
      } else {
        accountTrans = (
          <Ts id="pages.account.hashAlert" defaultMessage="Running well" />
        );
      }
    }
    return (
      <div
        className="user-list__coin-item"
        onClick={() => {
          this.onChangeAccount(coinAccount);
        }}
      >
        <div className="user-list__coin-name">
          {coinAccount.is_current === 1 && <div className={'current-mining'} />}

          <img
            alt={coinAccount.coin_type.toLowerCase()}
            src={coinsConfig[coinAccount.coin_type.toLowerCase()].icon}
          />
          <span>{coinAccount.coin_type}</span>
        </div>
        <div className="user-list__hashrate">
          <div>
            {
              <LoadingWrap
                loading={isLoadingHashrate}
                children={`${accountHashrate.shares_1d} ${accountHashrate.shares_1d_unit}${accountHashrate.hashrate_suffix}`}
              />
            }
          </div>
        </div>
        <div>
          {
            <LoadingWrap
              loading={isLoadingHashrate}
              children={`${accountHashrate.workers_active}/${accountHashrate.workers}`}
            />
          }
        </div>
        <div>
          {<LoadingWrap loading={isLoadingHashrate} children={accountTrans} />}
        </div>
        {isShowSetting && (
          <Setting
            className={'manage-account-setting'}
            isManage={true}
            puid={coinAccount.puid}
            coinType={coinAccount.coin_type}
            reloadList={this.props.reloadList}
          />
        )}
        {isShowHidden && (
          <a
            className="ant-dropdown-link"
            onClick={e => {
              self.onRecoverAccount(coinAccount);
              e.stopPropagation();
            }}
          >
            <Ts id="pages.account.recover" />
          </a>
        )}
      </div>
    );
  }
}

export default CoinAccountItem;
