import { observable, action } from 'mobx';

class PlaygroundStore {
  @observable
  coinName;

  constructor() {
    this.coinName = 'BTC';
  }

  @action
  setCoinName = coinName => {
    this.coinName = coinName;
  };
}

const playgroundStore = new PlaygroundStore();

export { playgroundStore };
