import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Ts from 'localeTranslator';
import { Tooltip } from 'antd';

const Copy = props => {
  const { text = '', handleCopy, containerId, children } = props;
  return (
    <CopyToClipboard text={text} onCopy={handleCopy}>
      <Tooltip
        title={<Ts id="common.copied" />}
        trigger="click"
        getPopupContainer={
          containerId
            ? () => document.getElementById(containerId)
            : () => document.body
        }
      >
        {children ? children : <i className="icon-copy" />}
      </Tooltip>
    </CopyToClipboard>
  );
};
export default Copy;
