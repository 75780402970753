import React from 'react';
import { Icon } from 'antd';
const sizeMap = {
  md: 24,
  sm: 16,
  lg: 32
};
const LoadingIcon = ({ size = 'md' }) => (
  <Icon type="loading" style={{ fontSize: sizeMap[size] }} spin />
);
export default LoadingIcon;
