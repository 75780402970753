import flatten from 'flat';
const pageMessage = {
  pages: {
    playground: {
      goto: 'Go to dashboard'
    },
    dashboard: {
      EarningsM: '{coinType} Mining Earnings',
      accountEarning: 'Account Earnings',
      active: 'Active',
      activeMiner: 'Active',
      amoutPaid: 'Total Amout Paid',
      announcement: 'Announcement',
      cannotConnect: 'Cannot connect?',
      urlConfigGpu: ' supports normal GPU miner',
      urlConfigNicehash: ' optimized for Nicehash',
      urlConfigGpuDcr: ' supports Antminer DR3，Claymore，gominer',
      urlConfigNicehashDcr: ' supports Nicehash，Innosilicon miner',
      customerTable:
        ' <dl>\n                                <dt><a href="http://support.bitmain.com/" target="_blank">Ticket System</a></dt>\n                                <dd> Online Consultation with the after-sales</dd>\n                            </dl>\n                            <dl>\n                                <dt><a href=":feedback_link" target="_blank">Feedback</a></dt>\n                                <dd>Simply leave a message to engineers</dd>\n                            </dl>\n                            <dl>\n                                <dt>Email</dt>\n                                <dd>support@btc.com</dd>\n                            </dl>',
      customerTitle: 'After-sale Service',
      dailyHashrate: 'Daily',
      dashboard: 'Dashboard',
      earnMore: 'How much more I earned?',
      earning: '{coinType} Earnings',
      eventConnected: 'connected',
      eventDisconnected: 'disconnected',
      eventMiner: 'Miner',
      eventName: 'event',
      eventSkip: 'Skip',
      eventTitle: 'Events',
      eventlatestLogs: 'The events below are past',
      hastrateChart: 'Hashrate Chart',
      history: 'History',
      hour: '{n} H',
      hours: 'Hours',
      inActive: 'Inactive',
      lastPaymentTime: 'Last Payment Time',
      markAllRead: 'Mark All Read',
      minerContent:
        'Access to local network and login the background system of miners, fill in the miner name and save the profile. The miners will be added to BTC.com Pool in a few seconds.<br/>Miner ID settings: {sub_account}.001, {sub_account}.002, etc; each miner ID corresponds to a miner.',
      minerNameStand: `Miner's name standard: sub-account+'.'+number, for example, if your sub-account is {sub_account}, then your miners could be set up as {sub_account}.001, {sub_account}.002. Each miner has its own name.`,
      minerName:
        '* Set &lt;sub-account&gt;.&lt;miner_ID&gt; on your miners, like {sub_account}.001, {sub_account}.002, etc.',
      miner: 'Miner',
      miners: 'Miners',
      miningAddress: 'Mining Address',
      miningEarnings: '{coinType} Mining Earnings',
      minute: 'M',
      more: 'More',
      networkDiff: 'network Diff',
      networkHashrate: 'Network Hashrate',
      networkStatus: 'Network Status',
      nextDifficult: 'Estimated Next Diffculty',
      noMiner:
        'Add miners to BTC.com pool and start mining. Recommend the use of ',
      btcTools: 'BTC Tool.',
      noEvent: 'No event notification!',
      nowHashrate: 'Real-time',
      pendingPayouts: 'Pending Payment',
      poolHashrate: 'Pool Hashrate',
      realHashrate: '{coinType} Hashrate',
      realtime: 'Real Time',
      timeRemain: 'Time Left',
      title: 'Dashboard - BTC.com Pool',
      today: 'Today Estimate',
      unpaid: 'Balance',
      yesterday: 'Yesterday',
      yesterdayEarnTip: 'Earnings from UTC 00:00 to 24:00 yesterday',
      charts: {
        hashrate: 'Hashrate',
        minerCharts: 'Miner Hashrate Charts',
        myCharts: 'My Hashrate Charts',
        poolCharts: 'BTC.com Pool Hashrate Charts',
        reject: 'Rejected',
        time: 'Time'
      },
      yesterday_earn: 'Yesterday Earnings',
      yesterday_hashhrate: 'Yesterday Accepted Hashrate',
      earnings_per_100T: 'Earnings per 100T',
      notShowAddress:
        'The sub-account of the cloud hashrate platform does not display mining address.',
      //职责声明
      statement:
        '* You are not allowed to access (or attempt to access) this service by any means other than through the means we provide or have indicated to be used, and you specifically agree not to access (or attempt to access) this service via any illegal or unlawful means or use this service to support any illegal or unlawful activities or purpose. By entering this website and signing up for this service, you acknowledge and agree that you are a person to whom this service may be lawfully provided in accordance with the laws of jurisdiction in which you are located.'
    },
    subAccount: {
      title: 'Create a sub-account - BTC.com pool',
      createWorker: 'Create a sub-account',
      noNode: 'You didn’t choose the node yet!',
      noCoinType: 'You didn’t choose the mining coin yet!',
      selectRegion: 'Select your region',
      setWorker: 'Set a sub-account name',
      enterWorker: 'Enter the sub-account name',
      enterAddress: 'Enter the Address',
      coinTypeEx:
        'BTC.com Pool now supports mining {supportCoins}, other coins will come soon, just look forward it.',
      regionEx:
        'BTC.com Pool deploys servers around the world, and the nearest server will bring you the best experience.',
      workerEx:
        'This sub-account will be tied to the region above. You can create up to 2000 sub-accounts. Set <sub-account>.<miner_name> on your miners, like subaccount.001, subaccount.002, etc.',
      workerCaution:
        "* Caution: Each sub-account can mine in only one region. This sub-account you are creating can't be used in other regions. If you want to mine in other region, please create another sub-account in other region.",
      setAddress: 'Set a withdraw address',
      save: 'Create and Mining',
      verifyWorker: '3-20 letters or numbers',
      option: 'Optional',
      optional:
        'Optional. BCH address is similar to BTC‘s, Please pay attention and don‘t fill in the wrong address.',
      'optional-BTC':
        'Optional. BCH legacy address is similar to BTC‘s, Please pay attention and don‘t fill in the wrong address.',
      'optional-BCH':
        'Optional. BCH legacy address is similar to BTC‘s, Please pay attention and don‘t fill in the wrong address. recommend to use new address',
      'optional-UBTC':
        'Optional.BCH legacy address is similar to UBTC‘s,Please pay attention and don‘t fill in the wrong address.',
      'optional-SBTC':
        'Optional.BCH legacy address is similar to SBTC‘s,Please pay attention and don‘t fill in the wrong address.',
      'optional-coin':
        'Optional：Please pay attention and don‘t fill in the wrong address.',
      selectCoinType: 'Select your mining coin',
      automatic: 'Automatic',
      recommandWallet: 'we recommand you to use BTC.com Wallet',
      withdrawToExchange: 'Exchange help',
      withdrawToWallet: 'Wallet help'
    },
    tools: {
      // title: 'Tools - BTC.com Pool',
      // tools: 'Tools',
      // fppsPps: 'FPPS vs PPS Dynamic Comparison',
      // aboutFpps: 'What’s good about FPPS?',
      // charts: 'Charts can tell everything.',
      // watchCharts: 'Watch Charts',
      // miniCalc: 'Mining Profit Calculator',
      // miniCalcExpress:
      //   'Calculate the mining profit combined with the price, difficulty, hash rate, electricity costs, power use, etc',
      // miniMiniCalc: 'Mini Mining Profit Calculator',
      // miniMiniCalcExpress: 'Quickly calculate mining profit in the easiest way',
      // strat: 'Start',
      // btcTool: 'BTC Tool',
      // btcToolIntroduce: "Batch process for miners' configuration efficiently.",
      // download: 'Click to download',
      btcSmartAgent: 'BTC Smart Agent',
      agentExpress: 'Efficient and Transparent <br/>Single Worker Visible',
      agentExpressBanner:
        'Efficient and Transparent <i style="margin-right:20px"></i> Single Worker Visible',
      bit: 'bit',
      document: 'Windows Help Doc.',
      poolApp: 'Full Featured BTC.com Pool App',
      poolRealTime: 'View pool & miner data in real-time',
      poolOpeation: 'Delete/modify miners directly',
      poolComming: 'Event alert function coming soon',
      poolDownlaod: 'Click to download',
      title: 'Tools - BTC.com Pool',
      tools: 'Tools',
      fppsPps: 'FPPS vs PPS Dynamic Comparison',
      aboutFpps: 'What’s good about FPPS?',
      charts: 'Charts can tell everything.',
      watchCharts: 'Watch Charts',
      miniCalc: 'Mining Profit Calculator',
      miniCalcExpress:
        'Calculate the mining profit combined with the price, difficulty, hash rate, electricity costs, power use, etc',
      miniMiniCalc: 'Mini Mining Profit Calculator',
      miniMiniCalcExpress: 'Quickly calculate mining profit in the easiest way',
      start: 'Start',
      btcTool: 'BTC Tool',
      btcToolIntroduce: "Batch process for miners' configuration efficiently."
    },
    miners: {
      createGroup: 'Create a New Group',
      default: 'DEFAULT',
      addGroup: 'Create a New Group',
      deleteGroupTitle: 'Delete Group',
      editGroupTitle: 'Edit Group',
      addGroupInfo:
        'Note: The selected miners will be automatically added to the new group.',
      groupNameLabel: 'Group Name',
      addGroupEmpty: 'The group name cannot be empty.',
      groupRejected: 'Real time rejection rate',
      groupNameLimit: 'The group name must be between 3 and 20 digits',
      all: 'All',
      inactive: 'Inactive',
      active: 'Active',
      dead: 'Dead',
      moveTo: 'Move to',
      remove: 'Remove',

      allTooltip:
        'Includes: Active Miners ({active}) + Inactive Miners ({inactive})',
      inactiveTooltip:
        'The number of miners that did not submit the calculation results (Share) for more than 10 minutes.',
      activeTooltip:
        'The number of miners that normally submit the calculation results (Share)',
      deadTooltip:
        'The number of miners that have not submitted the calculation result (Share) for more than 24 hours',

      home: 'Home',
      miners: 'Miners',

      workerName: 'Worker Name',
      Hashrate: 'Real Time',
      Rejected: 'Rejection Rate',
      dailyHashrate: 'Daily',
      firstShare: 'First Connection',
      LastShare: 'Last Share',
      groupName: 'Group Name',

      delMillTitle: 'Are you sure you want to delete these miner information?',
      delMillContext:
        'This will delete all the miners you have selected, but the active mining machine will not be affected, ie the active mining machine cannot be deleted.',

      delGroupTitle: 'Are you sure to delete the {name} group?',
      delGroupContext: 'The miners in the group will all move to the default',
      delMillFailed: 'Failed to delete',

      moveTitle: 'Move miners',
      notChecked: 'Select miners and take batch operation.',
      moveContext:
        'This will move all selected miners to the {groupName} group.',
      moveFaild: 'Cannot move to the current group',

      searchPlaceholder: 'Enter the worker name',
      reportTime: 'Generation time',
      refresh: 'Refresh',
      editGroupName: 'Edit group name',
      workListEmpty: 'No miner detected in this group',
      exist: 'The group name has been taken.',

      goNewVersion: 'Return to new version',
      goOldVersion: 'Return to old version'
    },
    minerInfo: {
      title: 'Miners - BTC.com Pool',
      miners: 'Miners',
      hashrate: 'Single Hashrate',
      runningCondition: 'Running Condition',
      min: 'M',
      hour: '{n}H',
      hashrateAxis: 'Hashrate',
      rejected: 'Rejected',
      status: 'Status',
      lastShare: 'Last Share',
      lastShareIP: 'Last Share IP',
      hashrateChart: 'Hashrate Chart',
      realTime: 'Real Time',
      trans: {
        seconds: 's',
        mins: ' mins',
        hours: ' hours',
        ago: ' ago',
        ACTIVE: 'Active',
        INACTIVE: 'Inactive',
        DEAD: 'Dead'
      }
    },

    earningHistory: {
      title: 'Earnings History - BTC.com Pool',
      earn: 'Earnings History',
      paid: 'Total Amount  Paid',
      Unpaid: 'Balance',
      EarningsToday: 'Today Estimate',
      EarningsYesterday: 'Earnings Yesterday',
      Time: 'Mining Time',
      NetworkDifficulty: 'Diff',
      SettleHashrate: 'Hashrate',
      Earnings: 'Earnings',
      Payment: 'Payment Time',
      payWithOTCAmount: 'Pay with {otcType} : {amount}',
      Mode: 'Mode',
      Address: 'Address',
      PaidAmount: 'Earning',
      yesterday: 'Earnings from UTC 00:00 to 24:00 yesterday',
      unpaidReason: {
        PENDING_NON_ADDRESS:
          "If you haven't set the Bitcoin address, your earnings will be saved in balance",
        PENDING_CHANGED_ADDRESS:
          'The payment will be done in 48 hours if the Bitcoin address is changed. Warning: your account(email / phone number) probably has been attacked if the Bitcoin address is not changed by yourself. Please register a new account with other emails or phone numbers immediately and switch your miners to the new one.',
        PENDING_NOT_ENOUGH:
          'The amount is less than the minimum payment as you set(the default is :limit ), it will be deposited into your balance.',
        PENDING_POOL_SETTLE_FAULT:
          'As a result of the mine pool, this part of the amount will be issued by mistake and will not be deposited in the future.'
      },
      depositeAmount: 'Saved',
      'diff-chg': 'Diff Chg: ',
      'equals-pps': 'Equals PPS',
      Chg: 'Chg.',
      status: 'Status',
      paid: 'Paid',
      waitingPay: 'Waiting',
      reason: 'Why',
      fppsMoreEarn: 'FPPS Extra:',
      fppsContain:
        'FPPS Extra earnings {pps96} included in the amount {paid_amount}.',
      noEarningsInformation: 'No earnings information'
    },

    transactionHistory: {
      title: 'Transactions - BTC.com Pool',
      earn: 'Transactions',
      paid: 'Total Amount  Paid',
      Unpaid: 'Balance',
      EarningsToday: 'Today Estimate',
      EarningsYesterday: 'Earnings Yesterday',
      Time: 'Date',
      NetworkDifficulty: 'Diff',
      Earnings: 'Earnings',
      Payment: 'Payment Time',
      payWithOTCAmount: 'Pay with {otcType} : {amount}',
      Mode: 'Mode',
      Address: 'Address',
      PaidAmount: 'Transaction amount',
      Transactions: 'Transactions',
      yesterday: 'Earnings from UTC 00:00 to 24:00 yesterday',
      unpaidReason: {
        PENDING_NON_ADDRESS:
          "If you haven't set the Bitcoin address, your earnings will be saved in balance",
        PENDING_CHANGED_ADDRESS:
          'The payment will be done in 48 hours if the Bitcoin address is changed. Warning: your account(email / phone number) probably has been attacked if the Bitcoin address is not changed by yourself. Please register a new account with other emails or phone numbers immediately and switch your miners to the new one.',
        PENDING_NOT_ENOUGH:
          'The amount is less than the minimum payment as you set(the default is :limit ), it will be deposited into your balance.',
        PENDING_POOL_SETTLE_FAULT:
          'As a result of the mine pool, this part of the amount will be issued by mistake and will not be deposited in the future.'
      },
      depositeAmount: 'Saved',
      'diff-chg': 'Diff Chg: ',
      'equals-pps': 'Equals PPS',
      Chg: 'Chg.',
      status: 'Status',
      paid: 'Paid',
      waitingPay: 'Waiting',
      reason: 'Why',
      fppsMoreEarn: 'FPPS Extra:',
      fppsContain:
        'FPPS Extra earnings {pps96} included in the amount {paid_amount}.',
      noEarningsInformation: 'No earnings information',
      share_host_earn: 'BitDeer Cloud Hashrate (Proportional Revenue)',
      share_hosting: 'BitDeer Cloud Hosting (Proportional Revenue)',
      share_elec_fee: 'BitDeer Cloud Hashrate  (Electricity Fee)',
      hosting: 'BitDeer Cloud Hosting (Hosting Fee)',
      repair: 'BitDeer Cloud Hosting (Repair Fee)',
      onboard: 'BitDeer Cloud Hosting (Shelf Fee)',
      offboard: 'BitDeer Cloud Hosting (Off-shelf Fee)',
      transfer: 'BitDeer Cloud Hosting (Transfer Logistic fee)',
      service: 'BitDeer Cloud Hosting (Service Fee)'
    },
    //统计
    poolStats: {
      title: 'Pool Statistics - BTC.com Pool',
      poolStats: 'Pool Statistics',
      hashrate: 'Hashrate',
      miners: 'Miners',
      coinType: 'Coin Type',
      found: 'Found',
      hashrateChart: 'Hashrate Chart',
      blocksRelayed: 'Blocks Relayed',
      height: 'Height',
      time: 'Time',
      blocks: 'Blocks',
      blockHash: 'Block Hash',
      reward: 'Reward',
      day: 'Days',
      region: 'Region',
      lucky: 'Lucky',
      btc: 'BTC',
      nmc: 'NMC',
      zec: 'ZEC',
      bcc: 'Bitcoin Cash',
      bch: 'Bitcoin Cash',
      eth: 'Ethereum',
      dcr: 'Decred',
      etc: 'Ethereum Classic',
      sbtc: 'Super Bitcoin',
      ubtc: 'United Bitcoin',
      grin: 'Grin'
    },

    // 守护者
    guardian: {
      expiredDeadline: ' (:day Automatically deleted)',
      guardianRemark:
        'You can share all (or part) of your sub-accounts information(including hash rate, miner) to other user who can only view, but he cannot modify any settings.',
      createGuardian: 'Add Sharing User',
      remark: 'Remark:',
      user: 'User:',
      manage: 'Manage',
      status: 'Status:',
      theGuardian: 'Share Account:',
      creationDate: 'Creation Date:',
      bounded: 'Bounded',
      datePrevious: 'Date Of Previous Use:',
      delete: 'Delete',
      deleteGuardianLink: ' Delete The Share Account Link',
      createGuardianInvitation: 'Create The Sharing Account Link',
      guardianAccountManagement: 'The Share Account Management',
      modifyRemark: 'Modify Remark',
      validateRemarkEmpty: 'Remark can not be empty',
      enterRemark: 'Add Remark',
      noteInformation:
        'Note: This remark is used to distinguish multiple share accounts, we suggest using the contact information.',
      confirm: 'Confirm',
      create: 'Create',
      cancel: 'Cancel ',
      guardianSuccessfullyCreated:
        'The sharing account invitation link was successfully created',
      copyLink: 'Copy Link',
      authorized:
        'After deleting, others will lose management authorization for your sub-account.',
      noteSendGuardian:
        'Tip:  Please send this link to reliable people who have demand for miner management. This link can be used for once, if the link is expired, please recreate it. ',
      close: 'Close',
      subaccount: 'Subaccount',
      coinType: 'Coin Type',
      region: 'Region',
      guardianStatus: 'Sharing',
      guardianSelectExpress:
        "Through the switch in the sharing status, you can select whether other's account  can manage your subaccount or not. (The default setting is the guardian can visit all your subaccounts.)",
      invitTitle: 'Share My Account To Others',
      acceptTitle: "Other's Account Acceptd by Me",
      losePower:
        'If you delete it, you will lose the authority to manage the subaccount.',
      show: 'Show'
    },
    // 绑定守护者邀请链接
    guardianBind: {
      bindingGuardianInvitation: 'Binding The Share Account Invitation Link',
      advance: 'Advance',
      guardianAccountManagement: 'Binding The Share Account Link:',
      confirmGuardianBind: 'The Share Account Account Management',
      remark: 'Remark',
      enterRemark: 'Add Remark',
      suggestName:
        'We suggest using the name to make the management more convenient. If you leave the field blank, it means you will use phone number or email address by default.',
      guardian: 'Bind',
      cancel: 'Cancel',
      confirm: 'Confirm',
      manageView: 'to manage all of your subaccounts？',
      suggestUsingName:
        'We suggest using name to make management more convenient. If you leave the field blank, it means use phone number or email address by default.',
      sms: 'Phone',
      email: 'Email',
      code: '{mode} verification Code',
      send: 'Send'
    },
    account: {
      subAccountManage: 'Sub-account Manage',
      currentAccount: 'Current Account',
      locationRegion:
        'Your location is in <strong>{coin}</strong> <strong>{region}</strong> region',
      locationEx:
        'Each subaccount corresponds to a fixed icon and area, and the currency and area can not be modified once created. If there are other requirements, you can create a new subaccount.',
      general: 'General',
      advance: 'Advanced',
      create: 'Create',
      algorithm: ' Algorithm',
      oneButtonSwitch: 'One-button Switch',
      addOthersCoins: 'Add other coins',
      supportCoins: 'Support',
      hiddenSubAccounts: 'Hidden Sub-accounts Manage',
      hashAlert: 'Running well',
      viewHideSubAccount: 'View hide sub-account',
      addSubAccount: 'Add sub-account',
      recover: 'Recover',
      latestAlert: {
        hashrate: 'Hashrate {symbol} {expect} {unit}',
        miners: 'Miner count {symbol} {expect}'
      }
    },
    settings: {
      subAccount: 'Sub Account',
      currency: 'Currency',
      region: 'Region',
      walletAddressAndMinimumPayment: 'Wallet Address & Minimum Payment',
      walletAddress: 'Wallet Address',
      shareData: 'Share Data',
      alertSettings: 'Alert Settings',
      payWithBtc: 'Pay with BTC',
      //wallet address & minimum payment
      setAddress: 'Please set a {coinType} Address.',
      address: 'Address',
      modifyAddress: 'Modify your Wallet Address',
      modify: 'Modify',
      minPay: 'Minimum Payment',
      lastUpdateTime: 'Address update time',
      updateBitconAddressTip:
        'Notice: For security reason, your earnings will be saved as balance for 48 hours.',

      //watcher
      old: 'Old',
      new: 'New',
      continue: 'Continue',
      emptyAddress: 'Address can not be empty',
      addAddress: 'Set',
      enterCode: 'Enter the code',
      newBit: 'Your new address',
      way:
        ' We will send you a code to confirm this modification,how would you like to receive it?',
      sendCode: 'We have sent you a code to confirm this modification',
      sendVerifyCode:
        'We have {contactType} you a code to confirm this modification',
      sms: 'texted',
      mail: 'mailed',
      mailVia: 'Email me code via',
      smsVia: 'Text me code via',
      errorLater: 'please try again later ...',
      updateSuccess: 'The address update success!',
      //min pay
      currentMinPay: 'Current Minimum Payment:',
      minPayAllSubAccount: 'Minimum Payment is equal for all BTC sub-accounts',
      todayUpdateMinPay:
        'Minimum Payment of mining earnings has been changed from today: {minPay}',

      //Share to watcher
      watcher: 'Watcher',
      availableWatchers: 'Available Watchers',
      watcherExpress:
        '* Watchers can view your data via url below, but can not modify your account settings.',
      createWatcher: 'Create New Watcher',
      setWatcherName: 'Set a name for this watcher',
      notEmptyWatcherNote: 'wathcer name can not be empty',
      moreSettings: 'More Settings',
      guardianRemark:
        'You can share all (or part) of your sub-accounts information(including hash rate, miner) to other user  who can only view but not modify any settings.',
      guardian: 'Share Accounts',
      shareAccountsSetting: 'Share Accounts Setting',
      accessKey: 'Access Key',
      generateAK: 'Generate Access Key',
      puid: 'PUID',
      apiDoc: 'API Document',
      confirmDeleteWatcher: 'Confirm to delete the watcher?',

      // Alert setting
      alertItem: 'Alert Item',
      alertContacts: 'Alert Contacts',
      hashrateAlert: 'Hashrate Alert',
      minerAlert: 'Miner  Alert',
      alertFrequency: 'Alert Frequency',
      warnMeWhenHashrate: 'Hashrate alert line ≤',
      warnMeWhenActiveMiner: 'Warn me when active miner ≤',
      dontWarnMeTwice: "Don't warn me twice in ",
      hours: 'Hours',
      apply: 'Apply',
      operateFailed: ' Operation failed!',
      contactsList: 'Alert Contacts',
      cancel: 'Cancel',
      ok: 'OK',
      save: 'Save',
      reset: 'Reset',
      newContact: 'Add new contact',
      editContact: 'Edit contact',
      deleteContact: 'Delete contact',
      confirmDeleteContact: 'Confirm to delete the alert contact?',
      name: 'Name',
      phone: 'Phone',
      email: 'Mail',
      validateAlert: {
        nameEmpty: 'Name can not be empty',
        phoneRegionFormat: 'Invalid region code',
        phoneFormat: 'Invalid phone number',
        emailFormat: 'Invalid email',
        contactEmpty: 'The email field is required when phone is not present.'
      },
      max_email_contacts: 'Supporting max to 10 mail contacts',
      max_phone_contacts: 'Supporting max to 5 phone contacts',
      enabled_alert_need_hashrate:
        'No hashpower detected, can not enable alert.',
      alertItemUpdateSuccess: 'Alert item update success',

      done: 'Done',

      Okay: 'Okay',

      invalidAddress: 'Invalid address',
      emptyCode: 'Can not be empty',
      wrongCode: 'Wrong code',
      emailVia: 'Email me via',
      textVia: 'Text me via',
      help: 'Help',

      contact: 'Contact:',
      close: 'Close',
      receiveAddress: 'Address:',

      updateAddress: 'Address update time:',
      // pay with btc
      payWithBtcTip:
        '* After the currency conversion turned on, your mining profit will be converted into Bitcoin at a fixed time each day and paid to the following individual BTC address. Normally, it will be UTC time 4:00, and will be notified in special circumstances.',
      convertedCurrency: 'Converted Currency: ',
      setBtcAddressTip: 'Please set a BTC address',
      btcAddress: 'BTC Address: ',
      conversionRate: 'Reference Conversion Rate',
      convert: 'Convert',
      getValue: 'You will get ',
      conversionTip:
        '* Conversion rate is for reference only, subject to actual conversion result.',
      lastUpdate: 'Last updated: ',
      on: 'ON',
      off: 'OFF',
      turnOnPaywith: 'Are you sure you want to turn on the pay with BTC?',
      turnOffPaywith: 'Are you sure you want to turn off the pay with BTC?',
      payOn: 'Turn on',
      payOff: 'Turn off',
      resend: 'Resend',
      send: 'Send Code',
      resendTip:
        "If you didn't receive the verification code, please click Resend after the countdown ends.",

      // 一键切换 One Switch
      oneSwitch: {
        attention: 'Attention',
        optionCurrentMode: 'Please select the mode',
        switch: 'you are about to switch your hashrate to {destMode}',
        switchAnotherMining: 'You are switching to {destMode} mining.',
        hashsHaveSwitched:
          'Mining hashes have all switched to {destMode} pool.',
        ethSwitchWarning:
          'Warning: If your mining machine does not support switching DAG, it will result in zero revenue. Need to restart the miner manually,To return to normal. (It is known that the AntMiner E3 does not support it. Switching from ETC to ETH requires manual restart).',
        ethSwitchWarningLinkTxt: 'Details.',
        checkSubAccount:
          'Please check your sub-account {subAccountName}- {subAccountRegionNode}',
        enter: 'Enter'
      }
    },
    homepage: {
      btcSmartAgent: 'BTC Smart Agent',
      agentExpress: 'Efficient and Transparent Single Worker Visible',
      bit: 'bit',
      bit64: 'Win7 or above 64 bit',
      bit32: 'WinXP 32 bit',
      document: 'Windows Help Doc.',
      accessLinuxVersion: 'Access for Linux Version',
      cuttingEdgeTechnology: 'Cutting Edge Technology',
      latestArchitecture: 'Latest & Original Architecture',
      openSourceCode: 'Open Source Code',
      highTestPressure: 'Ultra-high Stress Test',
      lowOrphanedRate: 'Ultra-low Orphaned Block Rate',
      transparentAgent: 'Transparent Agent',
      standardsServices: 'New Standards for Mining Services',
      customerHotline: 'Customer Hotline',
      highHashrateConsultation: 'High Hashrate Consultation',
      professionalCustomerService: 'Professional Customer Service',
      bestVipServer: 'VIP Server with Ultra-high Specification',
      oneToOne: 'One to One Case Analysis',
      appAvailable: 'BTC.com Pool App Supports Managing Miners Easily',
      earningsTimelyEveryday: 'Receive Earnings Timely Everyday',
      transnationalTeamOperation: 'International Team Operation',
      currencies: 'Currencies',
      dailyEarnings: 'Daily Earnings',
      hashrate: 'Hashrate',
      prices: 'Price',
      earningsModels: 'Earnings Models',
      minPayment: 'Payment Threshold',
      help: 'Community',
      notePaytime: 'Remark: Earnings settlement time is 8:00AM (UTC +8)',
      yuanKwhElectric: 'USD/kW·h (Electricity Price) ',
      todayEarnings: "Today's Earnings:",
      piece: 'Piece',
      signIn: 'Sign In',
      signUp: 'Sign up',
      power: 'Power',
      hashrateProfitEst: 'Hashrate Profit Est.',
      miningProfitEst: 'Mining Profit Est.',
      fee: 'Fee'
    },
    advanceAccount: {
      classic: '(Classic)',
      title: 'Advance Account Management - BTC.com',
      advance: 'Advance',
      all: 'All',
      ofSubAccount: ' sub-account',
      updateAt: 'Update At',
      btc: 'BTC',
      bch: 'BCH',
      sbtc: 'SBTC',
      ubtc: 'UBTC',
      dcr: 'DCR',
      ltc: 'LTC',
      grin: 'GRIN',
      beam: 'BEAM',
      unit: 'Unit',
      index: 'Index',
      subAccount: 'Sub-account',
      coin: 'Coin',
      hashrate: 'Hashrate',
      guard: 'Note',
      allMiners: 'All Miners',
      activeMiners: 'Active',
      inactiveMiners: 'Inactive',
      deadMiners: 'Dead',
      region: 'Region',
      more: 'More',
      setting: 'Setting',
      totalHashrate: 'Total Hashrate',
      addressChange: 'Pay Address',
      minPay: 'Min Pay',
      setAlert: 'Alert Settings',
      setContact: 'Alert Contact',
      nmcChange: 'NMC Pay Address',
      allIncome: 'Earnings History',
      shareData: 'Share to Watcher',
      getAk: 'Get AccessKey and puid',
      search: 'Search',
      noMatch: 'No matching record'
    },
    getCoin: {
      tip: {
        btc:
          'From now on, you can get Namecoin(NMC), Elastos(ELA), VCash(VCASH) for free if you are mining Bitcoin or Bitcoin cash in BTC.com mining pool.',
        bch:
          'From now on, you can get Namecoin(NMC), Elastos(ELA), VCash(VCASH) for free if you are mining Bitcoin or Bitcoin cash in BTC.com mining pool.',
        ltc:
          'From now on, you can get Dogecoin（PPLNS） for free if you are mining Litecoin in BTC.com mining pool.'
      },
      beta: 'In closed beta',
      whatsThis: "What's this?",
      address: 'Address:',
      newAddress: 'New Address',
      addressComplete:
        'To protect your earnings, please submit your address for receiving the free token as soon as possible. '
    },
    watcher: {
      expired: 'Watcher authority expired',
      incorrect: 'Watcher link error'
    },
    activity: {
      pointsToday: 'Points Today',
      pointsSum: 'Sum',
      ranking: 'Ranking',
      earnings: 'Earnings'
    }
  }
};
const pageMessageFlatten = flatten(pageMessage);
export default pageMessageFlatten;
