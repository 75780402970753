import { observable, action, runInAction } from 'mobx';
import axios from 'axios';
import { defaultListPage } from 'config';
class TransactionHistoryStore {
  @observable
  loadingStats;

  @observable
  earningStatsInfo;

  @observable
  loadingList;

  @observable
  historyList;

  @observable
  historyListTotal;

  @observable
  historyListPage;

  constructor() {
    this.loadingStats = false;
    this.loadingList = false;
    this.historyList = [];
    this.earningStatsInfo = {
      total_paid: '',
      unpaid: '',
      earnings_today: '',
      earnings_yesterday: ''
    };
    this.historyListPage = defaultListPage;
    this.historyListTotal = 0;
  }

  @action
  getEarningHistoryList = async coinType => {
    this.loadingList = true;
    const res = await axios.get(`/account/hosting-bills`, {
      params: {
        ...this.historyListPage,
        reason: 1,
        is_decimal: 1,
        record_type: coinType
      }
    });
    if (res && res.data) {
      runInAction(() => {
        this.historyList = res.data.list;
        this.historyListTotal = res.data.total_count;
        this.loadingList = false;
      });
    }
  };

  @action
  getEarningStatsInfo = async coinType => {
    this.loadingStats = true;
    const res = await axios.get(`/account/earn-stats`, {
      params: {
        is_decimal: 1,
        record_type: coinType
      }
    });
    if (res && res.data) {
      runInAction(() => {
        this.earningStatsInfo = res.data;
        this.loadingStats = false;
      });
    }
  };

  @action
  setHistoryListPage = (page, page_size) => {
    this.historyListPage = {
      page,
      page_size
    };
  };
}

const transactionHistoryStore = new TransactionHistoryStore();

export { transactionHistoryStore };
