import React, { Component } from 'react';
import ReactGA from 'react-ga';

ReactGA.initialize(
  [
    // BTC.com-Pool Website
    {
      trackingId: 'UA-131353081-15',
      gaOptions: {
        name: 'poolGA',
        cookieName: '_poolGA',
        allowLinker: true
      }
    },
    // BTC.com Master
    {
      trackingId: 'UA-66176065-27',
      gaOptions: {
        name: 'globalGA',
        cookieName: '_globalGA',
        allowLinker: true
      }
    }
  ],
  {
    debug: process.env.NODE_ENV === 'development',
    alwaysSendToDefaultTracker: false
  }
);

const withTracker = (WrappedComponent, options = {}) => {
  const trackPage = page => {
    if (!page.includes('access_key')) {
      ReactGA.set({
        page,
        ...options
      });
      ReactGA.pageview(page, ['poolGA', 'globalGA']);
      global._hmt && global._hmt.push(['_trackPageview', page]);
    }
  };

  // eslint-disable-next-line
  const HOC = class extends Component {
    componentDidMount() {
      // eslint-disable-next-line
      const page = this.props.location.pathname + this.props.location.search;
      trackPage(page);
    }

    componentDidUpdate(prevProps) {
      const currentPage =
        prevProps.location.pathname + prevProps.location.search;
      const nextPage =
        this.props.location.pathname + this.props.location.search;

      if (currentPage !== nextPage) {
        trackPage(nextPage);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return HOC;
};

export default withTracker;
