import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { Button } from 'antd';
import Ts from 'localeTranslator';
import ToggleAccountShow from '@/components/Account/toggleAccountShow';

@inject('store')
@observer
class UserListManageButton extends Component {
  render() {
    return (
      <div className="user-content__btns">
        <div className="user-content__btns-left">
          <span>
            <Ts id="pages.account.subAccountManage" />
          </span>
          <div className="user-content__btns-group">
            <Link to="/manage-account">
              <Button
                type="primary"
                size="small"
                onClick={e => this.props.onClick(e)}
              >
                <Ts id="pages.account.general" />
              </Button>
            </Link>

            <Link to="/advance-account">
              <Button
                type="primary"
                size="small"
                onClick={e => this.props.onClick(e)}
              >
                <Ts id="pages.account.advance" />
              </Button>
            </Link>

            <Link to="/sub-account/create">
              <Button
                type="primary"
                size="small"
                onClick={e => this.props.onClick(e)}
              >
                <Ts id="pages.account.create" />
              </Button>
            </Link>
          </div>
        </div>
        <ToggleAccountShow />
      </div>
    );
  }
}

export default UserListManageButton;
