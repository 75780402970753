import React from 'react';
import { Button } from 'antd';
import './index.scss';

const BtcButton = ({ children, title, className, ...otherProps }) => (
  <div className="btc-button-container">
    <Button {...otherProps} className={`${className} btc-button`}>
      {children}
    </Button>
  </div>
);
export default BtcButton;
