import React, { Component } from 'react';
import Ts from 'localeTranslator';
import notFoundImage from '@/images/V2/icon_not_found.png';

export default class NotFound extends Component {
  render() {
    return (
      <div className="warning-page text-center">
        <img src={notFoundImage} alt="Not Found" />
        <p className="warning-tip minor-text font-xlg">
          <Ts id="common.notFoundTip" />
        </p>
      </div>
    );
  }
}
