import { helpBaseURL, HASHRATE_UNIT_ARRAY, I18N_LANG } from '../constants';
import config from './coinsConfig.js';

/**
 * 币种相关配置
 *
 * 浏览器地址：explorer
 * 块高度：blockHeight、blockHash
 * 地址：address
 * 交易：txHash
 * 算力量级单位：hashrateScale
 * 算力单位：hashrateUnit
 * 全名：fullName
 * 中文名：zhName
 * 价格单位：priceUnit
 * 算法：algorithm
 * zendesk帮助链接：zendeskLink
 * 挖矿计算机：miningCalculator
 * 对于blockHeight、blockHash、address、txHash
 * 1. 以'/'开头会合并上explorer
 * 2. 删除对应配置或者值为null，最后会返回''（如grin的txHash）
 * 3. 对于blockHeight\blockHash 如果没有配置会取对应的blockHash\blockHeight配置
 */
const DEFAULT_COIN = 'btc';

const coinsConfig = new Proxy(config, {
  get(target, key) {
    let coinName = key;
    // 如果币种配置不存在，则返回默认配置
    if (!(key in target)) {
      coinName = DEFAULT_COIN;
    }
    const coin = target[coinName];
    return coin;
  }
});

export default coinsConfig;

function getExplorerConfig(coin_type, type, value) {
  if (!coin_type) {
    return '';
  }
  coin_type = coin_type.toLowerCase();
  let result = config[coin_type][type];
  if (typeof result === 'undefined' || result === null) {
    // 对于blockHeight\blockHash 如果没有配置会取对应的blockHash\blockHeight
    if (type === 'blockHeight' && config[coin_type]['blockHash']) {
      return getExplorerConfig(coin_type, 'blockHash', value);
    } else if (type === 'blockHash' && config[coin_type]['blockHeight']) {
      return getExplorerConfig(coin_type, 'blockHeight', value);
    }
    result = '';
  } else if (result.startsWith('/')) {
    result = config[coin_type].explorer + result + value;
  } else {
    result += value ? value : '';
  }
  return result;
}

const coinExplorerConfig = {
  explorer: coin_type => {
    return getExplorerConfig(coin_type, 'explorer') || 'javascript:;';
  },
  block_height: (coin_type, height, is_uncle) => {
    return (
      getExplorerConfig(coin_type, 'blockHeight', height) || 'javascript:;'
    );
  },
  block_hash: (coin_type, hash, is_uncle) => {
    return getExplorerConfig(coin_type, 'blockHash', hash) || 'javascript:;';
  },
  address: (coin_type, address) => {
    return getExplorerConfig(coin_type, 'address', address) || 'javascript:;';
  },
  txhash: (coin_type, hash) => {
    return getExplorerConfig(coin_type, 'txHash', hash) || 'javascript:;';
  },
  zendeskLink: (coin_type, lang) => {
    let link = '';
    if (config[coin_type] && config[coin_type].zendeskLink) {
      link = config[coin_type].zendeskLink.replace(
        '{zenLang}',
        lang.toLowerCase()
      );
    }
    return link;
  },
  // 返回币种所有算力量级
  hashrateScaleList: coin_type => {
    const biggestHashrateScale = coinsConfig[coin_type].biggestHashrateScale;
    const keys = HASHRATE_UNIT_ARRAY;
    const index = keys.indexOf(biggestHashrateScale) + 1 || keys.length;
    return keys.slice(0, index);
  },
  //
  i18nName: (coin_type, lang) => {
    let name = '';
    const coin = config[coin_type];
    if (coin) {
      if (lang === I18N_LANG.zh) {
        name = coin.zhName;
      } else {
        name = coin.fullName;
      }
    }
    return name;
  }
};

export { coinExplorerConfig };
