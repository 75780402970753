import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import Ts from 'localeTranslator';
import { message } from 'antd';
import { formatNumber, truncString, isBTC } from 'utils';
import { Menu, Dropdown } from 'antd';
import { Copy, LoadingWrap } from '@/components/widget';
import SvgIcon from '@/components/SvgIcon';
import sensors from 'sa-sdk-javascript';
import ReactGA from 'react-ga';
import './index.scss';
import { I18N_LANG } from '@/utils/constants';

@withRouter
@inject('store')
@observer
class Setting extends Component {
  constructor(props) {
    super(props);
    this.appStore = this.props.store.appStore;
    this.userStore = this.props.store.userStore;
    this.settingsStore = this.props.store.settingsStore;
    this.dashboardStore = this.props.store.dashboardStore;
    this.state = {
      menuVisible: false
    };
    this.topContentRef = React.createRef();
  }

  static defaultProps = {
    isManage: false
  };

  componentDidMount() {
    window.addEventListener('click', this.handleClick);
    this.startViewTime = Date.now();
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleClick);
  }

  logout = () => {
    this.userStore.logout();
    this.props.history.push('/');
  };

  onHideAccount = async puid => {
    try {
      const res = await this.userStore.hideAccount(puid);

      if (res && res.data) {
        message.success(new Ts().getLocaleString('common.operation.success'));
        if (this.props.reloadList) {
          this.props.reloadList();
        }
      }
    } catch (error) {
      let err = new Ts().getLocaleString('common.operation.failed');
      if (error.msg) {
        err = error.msg;
      }
      message.error(err);
    }
  };

  handleMenuClick = (event, key) => {
    const { puid } = this.props;
    this.setState({ menuVisible: false });
    this.clickEvent(key);
    if (key === 'hide_menu') {
      this.onHideAccount(puid);
    }
    if (event) {
      event.stopPropagation();
    }
  };

  handleClick = e => {
    // 在设置下拉框顶部区域（地址、余额）点击时，不收起下拉框，否则收起
    if (
      this.topContentRef.current &&
      this.topContentRef.current.contains(e.target)
    ) {
      return;
    }
    this.setState({ menuVisible: false });
  };

  handleClickSettings = e => {
    this.setState(
      { menuVisible: !this.state.menuVisible, loadingAccount: true },
      () => {
        if (this.state.menuVisible) {
          this.userStore.getAccountInfo(() => {
            this.setState({ loadingAccount: false });
          });
        }
      }
    );
    this.clickEvent('Clicksetting');
    e.stopPropagation();
  };

  clickEvent = eventName => {
    const { lang } = this.appStore;
    const { accountInfo } = this.userStore;
    this.clickRowTime = Date.now();
    const viewTimeCost = this.clickRowTime - this.startViewTime; // 载入到点击按钮时间差
    if (accountInfo.coin_type && accountInfo.region_conf.text) {
      sensors.track(eventName, {
        coin_type: accountInfo.coin_type,
        region:
          accountInfo.region_conf.text[lang === I18N_LANG.zh ? 'zh-cn' : 'en']
      });
      this.sendEvent(
        eventName,
        'TimeCostFromViewTo' + eventName,
        parseInt(viewTimeCost / 1000)
      );
    }
  };

  sendEvent = (eventAction, eventLabel, eventValue) => {
    ReactGA.event(
      {
        category: 'Account',
        action: eventAction,
        label: eventLabel,
        value: eventValue
      },
      ['poolGA']
    );
    global._hmt &&
      global._hmt.push([
        '_trackEvent',
        'Account',
        eventAction,
        eventLabel,
        eventValue
      ]);
  };

  getMenu = () => {
    const { loadingAccount } = this.state;
    const { accountInfo } = this.userStore;
    const { earnStats } = this.dashboardStore;
    const { isManage, puid, coinType } = this.props;
    const balance = earnStats.unpaid
      ? earnStats.unpaid
      : formatNumber(accountInfo.unpaid / 1e8, 8);
    return (
      <div className="setting-content">
        {!isManage && (
          <div>
            <div className="address-info" ref={this.topContentRef}>
              <label>
                <Ts id="common.setting.balance" />
              </label>
              <LoadingWrap loading={loadingAccount}>
                <p>
                  <span>{balance}</span>
                  <span className="gap">{accountInfo.coin_type}</span>
                </p>
              </LoadingWrap>
              <label>
                <Ts
                  id="common.setting.coinAddress"
                  values={{ coinType: accountInfo.coin_type }}
                />
              </label>
              <LoadingWrap loading={loadingAccount}>
                <p>
                  {accountInfo.address ? (
                    <span>
                      {truncString(accountInfo.address)}{' '}
                      <Copy text={accountInfo.address}>
                        <SvgIcon iconName="icon_copy" clickable />
                        {''}
                      </Copy>
                    </span>
                  ) : (
                    <Link to={`/settings/address`}>
                      <Ts
                        id="common.setting.setAddress"
                        values={{ coinType: accountInfo.coin_type }}
                      />
                    </Link>
                  )}
                </p>
              </LoadingWrap>
            </div>
            <div className="line" style={{ marginBottom: 0 }} />
          </div>
        )}
        <Menu onClick={e => this.handleMenuClick(e.domEvent, e.key)}>
          {isManage && (
            <Menu.Item key="hide_menu">
              <Ts id="common.setting.menu.hide" />
            </Menu.Item>
          )}
          <Menu.Item key="clickChangeWithdrawAddr">
            <Link to={`/settings/address?puid=${puid}&coinType=${coinType}`}>
              <Ts id="common.setting.menu.address" />
            </Link>
          </Menu.Item>
          {isBTC(coinType) && (
            <Menu.Item key="clickChangeMinPay">
              <Link to={`/settings/address?puid=${puid}&coinType=${coinType}`}>
                <Ts id="common.setting.menu.minPay" />
              </Link>
            </Menu.Item>
          )}
          {/* 经讨论，设置里不显示收益 2019-05-21 */}
          {/* <Menu.Item>
            <Link to={`/earning-history?puid=${puid}&coinType=${coinType}`}>
              <Ts id="common.setting.menu.earingsHistory" />
            </Link>
          </Menu.Item> */}
          <Menu.Item key="clickShareData">
            <Link to={`/settings/share?puid=${puid}&coinType=${coinType}`}>
              <Ts id="common.setting.menu.shareData" />
            </Link>
          </Menu.Item>
          <Menu.Item key="clickAlertSettings">
            <Link to={`/settings/alert?puid=${puid}&coinType=${coinType}`}>
              <Ts id="common.setting.menu.alertSettings" />
            </Link>
          </Menu.Item>
          {/* {!isBTC(coinType) && (
            <Menu.Item key="payWithBtc">
              <Link to={`/settings/pay?puid=${puid}&coinType=${coinType}`}>
                <Ts id="common.setting.menu.payWithBtc" />
              </Link>
            </Menu.Item>
          )} */}
          {/* 经讨论，设置里不显示一键切换 2019-05-22 */}
          {/* {!isManage && (
            <Menu.Item onClick={this.handleShowOneSwitch}>
              <Ts id="common.setting.menu.onebuttonSwitch" />
            </Menu.Item>
          )} */}
          {!isManage && (
            <Menu.Item key="clickUserCenter">
              <a
                target="_blank"
                rel="nofollow noopener noreferrer"
                href={process.env.BTC_POOL_USER_CENTER}
              >
                <Ts id="common.setting.menu.userCenter" />
              </a>
            </Menu.Item>
          )}
          {!isManage && (
            <Menu.Item key="clickSignOut">
              <a
                target="_blank"
                rel="nofollow noopener noreferrer"
                onClick={this.logout}
              >
                <Ts id="common.header.SignOut" />
              </a>
            </Menu.Item>
          )}
        </Menu>
      </div>
    );
  };

  render() {
    const { isManage } = this.props;

    return (
      <div id="settingsContainer">
        <Dropdown
          getPopupContainer={() => document.getElementById('settingsContainer')}
          overlay={this.getMenu()}
          overlayClassName={`setting-dropdown-container ${
            this.props.className ? this.props.className : ''
          }`}
          placement="bottomRight"
          trigger={['click']}
          visible={this.state.menuVisible}
        >
          <a
            className={`ant-dropdown-link${!isManage ? ' font-lg' : ''}`}
            onClick={this.handleClickSettings}
          >
            <Ts id="common.header.setting" />
          </a>
        </Dropdown>
      </div>
    );
  }
}

export default Setting;
