import React from 'react';
import { Pagination } from 'antd';
import { defaultPageSizeOptions } from 'config';
import Ts from 'localeTranslator';
import './index.scss';

const BtcPagination = ({
  current,
  total,
  pageSize,
  onChange,
  isShowTotal = true,
  pageSizeOptions,
  align = 'right',
  showQuickJumper = true,
  showSizeChanger = true,
  isSimple, //only display prev/next
  hideOnSinglePage = false
}) => (
  <div className={`pagination align-${align}`}>
    <Pagination
      defaultCurrent={1}
      defaultPageSize={10}
      current={current}
      showQuickJumper={showQuickJumper}
      showSizeChanger={showSizeChanger}
      total={total}
      pageSize={pageSize}
      showTotal={(total, range) =>
        isShowTotal ? <Ts id="pagination.totalInfo" values={{ total }} /> : null
      }
      pageSizeOptions={
        pageSizeOptions ? pageSizeOptions : defaultPageSizeOptions
      }
      onChange={onChange}
      onShowSizeChange={(current, size) => {
        onChange(1, size);
      }}
      showLessItems={true}
      simple={isSimple}
      hideOnSinglePage={hideOnSinglePage}
    />
  </div>
);

export default BtcPagination;
