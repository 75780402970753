import React, { Component } from 'react';
import { Modal } from 'antd';
import './index.scss';
class BtcModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const {
      visible,
      title,
      onCancel,
      onOk,
      confirmLoading,
      children,
      hideCancelButton = false,
      hideOkButton = false,
      ...otherProps
    } = this.props;
    return (
      <Modal
        title={title}
        visible={visible}
        onOk={onOk}
        onCancel={onCancel}
        confirmLoading={confirmLoading}
        maskClosable={false}
        wrapClassName={`${hideOkButton ? 'modal-hide-ok' : ''} ${
          hideCancelButton ? 'modal-hide-cancel' : ''
        }`}
        {...otherProps}
      >
        {children}
      </Modal>
    );
  }
}

export default BtcModal;
