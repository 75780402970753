import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Button } from 'antd';
import Ts from 'localeTranslator';
import OneSwitchModal from './OneSwitchModal';
@inject('store')
@observer
export default class SwitchCoins extends Component {
  state = {
    activeCoin: '',
    switchCoin: '',
    switchModalVisible: false
  };

  constructor(props) {
    super(props);
    this.store = this.props.store.appStore;
    this.state.activeCoin = this.props.currentCoin;
    this.state.switchCoin = this.state.activeCoin;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.currentCoin !== prevState.activeCoin) {
      return {
        activeCoin: nextProps.currentCoin,
        switchCoin: nextProps.currentCoin
      };
    }
    return null;
  }

  onClickHandler = item => {
    return () => {
      const { currentCoin } = this.props;
      if (item === currentCoin) return;
      this.setState({
        switchCoin: item,
        switchModalVisible: true
      });
    };
  };

  handleSwitchModalOk = () => {
    // 切换成功后进入已切换的币种
    this.setState({
      switchModalVisible: false
    });
    window.location.reload();
  };

  handleSwitchModalCancel = () => {
    this.setState({
      switchModalVisible: false
    });
  };

  render() {
    const { supportCoins, currentPuid } = this.props;
    const { activeCoin, switchCoin, switchModalVisible } = this.state;
    return (
      <div className="user-list__coin-switch">
        <span>
          <Ts
            id="pages.account.oneButtonSwitch"
            defaultMessage={'One-button Switch'}
          />
        </span>
        {supportCoins.map((coinType, index) => {
          return (
            <Button
              key={index}
              type={activeCoin === coinType ? 'primary' : ''}
              size={'small'}
              onClick={this.onClickHandler(coinType)}
            >
              {coinType.toUpperCase()}
            </Button>
          );
        })}
        {switchModalVisible && (
          <OneSwitchModal
            visible={switchModalVisible}
            puid={currentPuid}
            defaultMode={activeCoin}
            currentMode={switchCoin}
            onOk={this.handleSwitchModalOk}
            onCancel={this.handleSwitchModalCancel}
          />
        )}
      </div>
    );
  }
}
