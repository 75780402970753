// import { helpBaseURL } from '../constants';
const helpBaseURL = 'https://help.pool.btc.com';
/**
 * 币种相关配置
 *
 * 浏览器地址：explorer
 * 块高度：blockHeight、blockHash
 * 地址：address
 * 交易：txHash
 * 算力量级单位：hashrateScale
 * 算力单位：hashrateUnit
 * 全名：fullName
 * 中文名：zhName
 * 价格单位：priceUnit
 * 算法：algorithm
 * zendesk帮助链接：zendeskLink
 * 挖矿计算机：miningCalculator
 * 对于blockHeight、blockHash、address、txHash
 * 1. 以'/'开头会合并上explorer
 * 2. 删除对应配置或者值为null，最后会返回''（如grin的txHash）
 * 3. 对于blockHeight\blockHash 如果没有配置会取对应的blockHash\blockHeight配置
 */
const coinsConfig = {
  btc: {
    symbol: 'BTC',
    fullName: 'Bitcoin',
    zhName: '比特币',
    priceUnit: 'BTC',
    algorithm: 'SHA256',
    hashrateScale: 'T',
    biggestHashrateScale: 'P',
    hashrateUnit: 'H/s',
    explorer: 'https://btc.com',
    blockHeight: '/',
    blockHash: '/',
    address: '/',
    txHash: '/',
    showMergeMining: true,
    showSwitch: true,
    zendeskLink: '{helpBaseURL}/hc/{zenLang}/articles/360020217972',
    miningCalculator: 'https://btc.com/tools/mining-calculator',
    socialGroups: {
      qq: 'QQ-BTC:BCH挖矿交流903550786@2x.png',
      qqLink:
        '<a rel="nofollow noopener noreferrer" target="_blank" href="https://jq.qq.com/?_wv=1027&k=5eEdXW3">903550786</a>',
      qqName: 'BTC/BCH挖矿交流',
      wechat: 'wechat-ASIC挖矿交流@2x.png',
      wechatName: 'ASIC矿工之家'
    }
  },
  bch: {
    symbol: 'BCH',
    fullName: 'Bitcoin Cash',
    zhName: '比特币现金',
    priceUnit: 'BCH',
    algorithm: 'SHA256',
    hashrateScale: 'T',
    biggestHashrateScale: 'P',
    hashrateUnit: 'H/s',
    explorer: 'https://bch.btc.com',
    blockHeight: '/',
    blockHash: '/',
    address: '/',
    txHash: '/',
    showMergeMining: true,
    showSwitch: true,
    zendeskLink: '{helpBaseURL}/hc/{zenLang}/articles/360020263272',
    miningCalculator: 'https://btc.com/tools/mining-calculator',
    socialGroups: {
      qq: 'QQ-BTC:BCH挖矿交流903550786@2x.png',
      qqLink:
        '<a rel="nofollow noopener noreferrer" target="_blank" href="https://jq.qq.com/?_wv=1027&k=5eEdXW3">903550786</a>',
      qqName: 'BTC/BCH挖矿交流',
      wechat: 'wechat-ASIC挖矿交流@2x.png',
      wechatName: 'ASIC矿工之家'
    }
  },
  bsv: {
    symbol: 'BSV',
    fullName: 'Bitcoin SV',
    zhName: 'Bitcoin SV',
    priceUnit: 'BSV',
    algorithm: 'SHA256',
    hashrateScale: 'T',
    biggestHashrateScale: 'P',
    hashrateUnit: 'H/s',
    explorer: 'https://bsv.btc.com',
    blockHeight: '/',
    blockHash: '/',
    address: '/',
    txHash: '/',
    showMergeMining: false,
    showSwitch: false,
    zendeskLink: '{helpBaseURL}/hc/{zenLang}/articles/360029465351'
  },
  ubtc: {
    symbol: 'UBTC',
    fullName: 'United Bitcoin',
    zhName: '比特币联储',
    priceUnit: 'UBTC',
    algorithm: 'SHA256',
    hashrateScale: 'T',
    biggestHashrateScale: 'P',
    hashrateUnit: 'H/s',
    explorer: 'https://block.bitbank.com',
    blockHeight: '/height/ubtc/',
    address: '/address/ubtc/',
    txHash: '/tx/ubtc/',
    showSwitch: true,
    zendeskLink: '{helpBaseURL}/hc/{zenLang}/articles/360020749491',
    socialGroups: {
      qq: 'QQ-矿工交流621211954@2x.png',
      qqLink:
        '<a rel="nofollow noopener noreferrer" target="_blank" href="https://jq.qq.com/?_wv=1027&k=5ne8tZy">621211954</a>',
      qqName: '矿工交流',
      wechat: 'wechat-矿工之家社区@2x.png',
      wechatName: '矿工之家'
    }
  },
  eth: {
    symbol: 'ETH',
    fullName: 'Ethereum',
    zhName: '以太坊',
    priceUnit: 'ETH',
    algorithm: 'ETHASH',
    hashrateScale: 'M',
    biggestHashrateScale: 'P',
    hashrateUnit: 'H/s',
    explorer: 'https://eth.btc.com',
    blockHeight: '/blockinfo/',
    address: '/accountinfo/',
    txHash: '/txinfo/',
    showSwitch: true,
    zendeskLink: '{helpBaseURL}/hc/{zenLang}/articles/360020218012',
    socialGroups: {
      qq: 'QQ-显卡挖矿交流 625476553@2x.png',
      qqLink:
        '<a rel="nofollow noopener noreferrer" target="_blank" href="https://jq.qq.com/?_wv=1027&k=5duv6iY">625476553</a>',
      qqName: '显卡挖矿交流',
      wechat: 'wechat-显卡矿工之家@2x.png',
      wechatName: '显卡矿工之家'
    },
    showSwitchTip: true
  },
  etc: {
    symbol: 'ETC',
    fullName: 'Ethereum Classic',
    zhName: '以太坊经典',
    priceUnit: 'ETC',
    algorithm: 'ETHASH',
    hashrateScale: 'M',
    biggestHashrateScale: 'P',
    hashrateUnit: 'H/s',
    explorer: 'http://gastracker.io',
    blockHeight: '/block/',
    blockHash: '/block/',
    address: '/addr/',
    txHash: '/tx/',
    showSwitch: true,
    zendeskLink: '{helpBaseURL}/hc/{zenLang}/articles/360020796391',
    socialGroups: {
      qq: 'QQ-显卡挖矿交流 625476553@2x.png',
      qqLink:
        '<a rel="nofollow noopener noreferrer" target="_blank" href="https://jq.qq.com/?_wv=1027&k=5duv6iY">625476553</a>',
      qqName: '显卡挖矿交流',
      wechat: 'wechat-显卡矿工之家@2x.png',
      wechatName: '显卡矿工之家'
    },
    showSwitchTip: true
  },
  dcr: {
    symbol: 'DCR',
    fullName: 'Decred',
    priceUnit: 'DCR',
    algorithm: 'BLAKE256R14',
    hashrateScale: 'T',
    biggestHashrateScale: 'P',
    hashrateUnit: 'H/s',
    explorer: 'https://mainnet.decred.org',
    blockHash: '/block/',
    address: '/address/',
    txHash: '/tx/',
    zendeskLink: '{helpBaseURL}/hc/{zenLang}/articles/360020263292',
    socialGroups: {
      qq: 'QQ-DCR挖矿交流：798936353@2x.png',
      qqLink:
        '<a rel="nofollow noopener noreferrer" target="_blank" href="https://jq.qq.com/?_wv=1027&k=5w0dnNK">798936353</a>',
      qqName: 'DCR挖矿交流',
      wechat: 'wechat-ASIC挖矿交流@2x.png',
      wechatName: 'ASIC矿工之家'
    }
  },
  sbtc: {
    symbol: 'SBTC',
    fullName: 'Super Bitcoin',
    priceUnit: 'SBTC',
    algorithm: 'SHA256',
    hashrateScale: 'T',
    biggestHashrateScale: 'P',
    hashrateUnit: 'H/s',
    explorer: 'http://block.superbtc.org',
    blockHeight: '/block/',
    blockHash: '/block/',
    address: '/address/',
    txHash: '/tx/'
  },
  ltc: {
    symbol: 'LTC',
    fullName: 'Litecoin',
    zhName: '莱特币',
    priceUnit: 'LTC',
    algorithm: 'Scrypt',
    hashrateScale: 'M',
    biggestHashrateScale: 'P',
    hashrateUnit: 'H/s',
    explorer: 'https://ltc.btc.com',
    blockHeight: '/',
    blockHash: '/',
    address: '/',
    txHash: '/',
    showMergeMining: true,
    zendeskLink: '{helpBaseURL}/hc/{zenLang}/articles/360020480372',
    socialGroups: {
      qq: 'QQ-LTC挖矿交流：906689772@2x.png',
      qqLink:
        '<a rel="nofollow noopener noreferrer" target="_blank" href="https://jq.qq.com/?_wv=1027&k=51XOi4H">906689772</a>',
      qqName: 'LTC挖矿交流',
      wechat: 'wechat-ASIC挖矿交流@2x.png',
      wechatName: 'ASIC矿工之家'
    }
  },
  doge: {
    symbol: 'DOGE',
    fullName: 'Dogecoin',
    zhName: '狗狗币',
    priceUnit: 'DOGE',
    algorithm: 'Scrypt',
    hashrateScale: 'M',
    biggestHashrateScale: 'P',
    hashrateUnit: 'H/s',
    explorer: 'https://dogechain.info',
    blockHeight: '/block/',
    blockHash: '/block/',
    address: '/address/',
    txHash: '/tx/',
    icon: '',
    zendeskLink: '{helpBaseURL}/hc/{zenLang}/articles/360026462972'
  },
  grin: {
    symbol: 'GRIN',
    fullName: 'Grin-c29',
    priceUnit: 'GRIN',
    algorithm: 'Cuckoo Cycle',
    hashrateScale: '',
    biggestHashrateScale: 'M',
    hashrateUnit: 'g/s',
    explorer: 'https://grinexplorer.net',
    blockHeight: '/block/',
    blockHash: '/block/',
    address: '',
    zendeskLink: '{helpBaseURL}/hc/{zenLang}/articles/360021371292',
    socialGroups: {
      qq: 'QQ-矿工交流621211954@2x.png',
      qqLink:
        '<a rel="nofollow noopener noreferrer" target="_blank" href="https://jq.qq.com/?_wv=1027&k=5ne8tZy">621211954</a>',
      qqName: '矿工交流',
      wechat: 'wechat-矿工之家社区@2x.png',
      wechatName: '矿工之家'
    }
  },
  grin29: {
    symbol: 'GRIN',
    fullName: 'Grin-c29',
    priceUnit: 'GRIN',
    algorithm: 'Cuckoo Cycle',
    hashrateScale: '',
    biggestHashrateScale: 'M',
    hashrateUnit: 'g/s',
    explorer: 'https://grinexplorer.net',
    blockHeight: '/block/',
    blockHash: '/block/',
    address: '',
    icon: '/static/images/coin/img_grin@2x.png',
    zendeskLink: '{helpBaseURL}/hc/{zenLang}/articles/360021371292',
    socialGroups: {
      qq: 'QQ-矿工交流621211954@2x.png',
      qqLink:
        '<a rel="nofollow noopener noreferrer" target="_blank" href="https://jq.qq.com/?_wv=1027&k=5ne8tZy">621211954</a>',
      qqName: '矿工交流',
      wechat: 'wechat-矿工之家社区@2x.png',
      wechatName: '矿工之家'
    }
  },
  grin31: {
    symbol: 'GRIN',
    fullName: 'Grin-c31',
    priceUnit: 'GRIN',
    algorithm: 'Cuckoo Cycle',
    hashrateScale: '',
    biggestHashrateScale: 'M',
    hashrateUnit: 'g/s',
    explorer: 'https://grinexplorer.net',
    blockHeight: '/block/',
    blockHash: '/block/',
    address: '',
    icon: '/static/images/coin/img_grin@2x.png',
    zendeskLink: '{helpBaseURL}/hc/{zenLang}/articles/360021371292',
    socialGroups: {
      qq: 'QQ-矿工交流621211954@2x.png',
      qqLink:
        '<a rel="nofollow noopener noreferrer" target="_blank" href="https://jq.qq.com/?_wv=1027&k=5ne8tZy">621211954</a>',
      qqName: '矿工交流',
      wechat: 'wechat-矿工之家社区@2x.png',
      wechatName: '矿工之家'
    }
  },
  nmc: {
    symbol: 'NMC',
    fullName: 'Namecoin',
    zhName: '域名币',
    priceUnit: 'NMC',
    algorithm: 'SHA256',
    hashrateScale: 'T',
    biggestHashrateScale: 'P',
    hashrateUnit: 'H/s',
    explorer: 'https://bchain.info/NMC',
    blockHeight: '/block/',
    blockHash: '/block/',
    address: '/addr/',
    txHash: '/tx/',
    icon: '',
    zendeskLink: '{helpBaseURL}/hc/{zenLang}/articles/360020805651'
  },
  beam: {
    symbol: 'BEAM',
    fullName: 'Beam',
    priceUnit: 'BEAM',
    algorithm: 'Equihash',
    hashrateScale: '',
    biggestHashrateScale: 'M',
    hashrateUnit: 'sol/s',
    explorer: 'https://explorer.beam.mw',
    blockHash: '/block/',
    address: '',
    zendeskLink: '{helpBaseURL}/hc/{zenLang}/articles/360023098252',
    socialGroups: {
      qq: 'QQ-显卡挖矿交流 625476553@2x.png',
      qqLink:
        '<a rel="nofollow noopener noreferrer" target="_blank" href="https://jq.qq.com/?_wv=1027&k=5duv6iY">625476553</a>',
      qqName: '显卡挖矿交流',
      wechat: 'wechat-显卡矿工之家@2x.png',
      wechatName: '显卡矿工之家'
    }
  },
  rsk: {
    symbol: 'RSK',
    fullName: 'RSK',
    zhName: '根链',
    priceUnit: 'RBTC',
    algorithm: 'SHA256',
    hashrateScale: '',
    biggestHashrateScale: '',
    hashrateUnit: 'H/s',
    explorer: 'https://explorer.rsk.co/',
    blockHash: '/block/',
    address: '',
    icon: '',
    zendeskLink: '{helpBaseURL}/hc/{zenLang}/articles/360020527351'
  },
  ela: {
    symbol: 'ELA',
    fullName: 'Elastos',
    zhName: '亦来云',
    priceUnit: 'ELA',
    algorithm: 'SHA256',
    hashrateScale: '',
    biggestHashrateScale: '',
    hashrateUnit: 'H/s',
    address: '',
    icon: '',
    zendeskLink: '{helpBaseURL}/hc/{zenLang}/articles/360026754171'
  },
  vcash: {
    symbol: 'VCASH',
    fullName: 'VCash',
    zhName: 'VCash',
    priceUnit: 'VCASH',
    algorithm: 'SHA256',
    hashrateScale: 'T',
    biggestHashrateScale: 'P',
    hashrateUnit: 'H/s',
    explorer: 'https://vcash.tech',
    blockHeight: '/block/',
    blockHash: '/block/',
    address: '/addr/',
    txHash: '/tx/',
    icon: '',
    zendeskLink: '{helpBaseURL}/hc/{zenLang}/articles/360029847871'
  },
  ckb: {
    symbol: 'CKB',
    fullName: 'Nervos',
    zhName: 'Nervos',
    priceUnit: 'CKB',
    algorithm: 'Eaglesong',
    hashrateScale: 'M',
    biggestHashrateScale: 'P',
    hashrateUnit: 'H/s',
    explorer: 'https://explorer.nervos.org',
    blockHeight: '/block/',
    blockHash: '/block/',
    address: '/address/',
    txHash: '/transaction/',
    zendeskLink: '{helpBaseURL}/hc/zh-cn/articles/360034146232',
    socialGroups: {
      qq: 'QQ-CKB挖矿交流：905634947@2x.png',
      qqLink:
        '<a rel="nofollow noopener noreferrer" target="_blank" href="https://jq.qq.com/?_wv=1027&k=5ttSOgI">905634947</a>',
      qqName: 'CKB挖矿交流',
      wechat: 'wechat-矿工之家社区@2x.png',
      wechatName: '矿工之家'
    }
  },
  trb: {
    symbol: 'TRB',
    fullName: 'Tellor Tributes',
    zhName: 'Tellor Tributes',
    priceUnit: 'TRB',
    algorithm: 'Keccak256',
    hashrateScale: 'G',
    biggestHashrateScale: 'T',
    hashrateUnit: 'H/s',
    explorer: '',
    blockHeight: '',
    blockHash: '',
    address: '',
    txHash: '',
    zendeskLink: ''
  },
  dash: {
    symbol: 'DASH',
    fullName: 'Dash',
    zhName: '达世币',
    priceUnit: 'DASH',
    algorithm: 'X11',
    hashrateScale: 'G',
    biggestHashrateScale: 'P',
    hashrateUnit: 'H/s',
    icon: '/static/images/coin/icon_dash@2x.png',
    explorer: 'https://blockchair.com',
    blockHeight: '/dash/block/',
    blockHash: '/dash/block/',
    address: '/dash/address/',
    txHash: '/dash/transaction/',
    zendeskLink: '',
    miningCalculator: 'http://www.wabi.com/mining-income/dash-calculator.html'
  }
};

const config = Object.entries(coinsConfig)
  .map(([key, coin]) => {
    if (coin) {
      const coinName = key;
      // 读取默认icon位置
      if (typeof coin.icon === 'undefined' && !coin.icon) {
        coin.icon = `/static/images/coin/img_${coinName}@2x.png`;
      }
      // 默认全名
      if (!coin.fullName) {
        coin.fullName = key;
      }

      // 中文名， 不存在默认取fullName
      if (!coin.zhName) {
        coin.zhName = coin.fullName;
      }

      if (coin.zendeskLink) {
        coin.zendeskLink = coin.zendeskLink.replace(
          '{helpBaseURL}',
          helpBaseURL
        );
        coin.zendeskLinkCN = coin.zendeskLink.replace('{zenLang}', 'zh-cn');
        coin.zendeskLinkEN = coin.zendeskLink.replace('{zenLang}', 'en-us');
      }
      // 社群
      if (!coin.socialGroups) {
        coin.socialGroups = {};
      }
      if (!coin.socialGroups.qq) {
        coin.socialGroups.qq = `QQ-矿工交流621211954@2x.png`;
      }

      if (!coin.socialGroups.qqLink) {
        coin.socialGroups.qqLink = `<a rel="nofollow noopener noreferrer" target="_blank" href=" https://jq.qq.com/?_wv=1027&k=5ne8tZy">621211954</a>`;
      }
      if (!coin.socialGroups.wechat) {
        coin.socialGroups.wechat = `wechat-矿工之家社区@2x.png`;
      }
      if (!coin.socialGroups.qq.startsWith('/static/images/socialGroups/')) {
        coin.socialGroups.qq =
          '/static/images/socialGroups/' + coin.socialGroups.qq;
      }
      if (!coin.socialGroups.qqName) {
        coin.socialGroups.qqName = '矿工交流社区';
      }
      if (!coin.socialGroups.wechatName) {
        coin.socialGroups.wechatName = '矿工之家';
      }
      if (
        !coin.socialGroups.wechat.startsWith('/static/images/socialGroups/')
      ) {
        coin.socialGroups.wechat =
          '/static/images/socialGroups/' + coin.socialGroups.wechat;
      }

      if (!coin.socialGroups.telegram) {
        coin.socialGroups.telegram = 'https://t.me/btccom_pool';
      }
    }

    return [key, coin];
  })
  .reduce((obj, [key, coin]) => {
    return {
      ...obj,
      [key]: coin
    };
  }, {});

export default config;
