import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import { Alert } from 'antd';
import Header from '@/components/Header';
import { Footer } from '@btccomfe/btccomfe-base-ui';
import HomePageHeader from '@/pages/HomePage/section/header';
import { CSSTransition } from 'react-transition-group';
import { I18N_LANG } from '@/utils/constants';
import { Helmet } from 'react-helmet';
import Ts from 'localeTranslator';
import './index.scss';
import { computed } from 'mobx';
const TIMEOUT = 600;
@inject('store')
@withRouter
class Layout extends Component {
  constructor(props) {
    super(props);
    this.appStore = this.props.store.appStore;
    this.homepageStore = this.props.store.homepageStore;
    // this.judgeIsHome();
  }

  state = {
    isHome: false,
    path: '',
    inProp: false,
    isShowAnnouncement: true
  };

  @computed get announcementConfig() {
    const { lang } = this.appStore;
    const { announcementInfos } = this.homepageStore;
    if (announcementInfos && announcementInfos.length > 0) {
      return announcementInfos.find(banner => {
        return banner.i18n === (lang === I18N_LANG.zh ? 1 : 2);
      }); // filter lang
    }
    return null;
  }

  judgeIsHome() {
    let isHome = false;
    if (
      this.props.location.pathname === '/' ||
      this.props.location.pathname === '/home' ||
      // i18n path
      Object.values(I18N_LANG).some(lang => {
        const pathname = this.props.location.pathname.toLowerCase();
        return (
          pathname === '/' + lang.toLowerCase() ||
          pathname === '/' + lang.toLowerCase() + '/home'
        );
      })
    ) {
      isHome = true;
    }

    if (this._mounted) {
      this.setState({
        isHome
      });
    } else {
      this.state.isHome = isHome;
    }
    this.homepageStore.setIsHome(isHome);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    const self = this;
    this.setState(state => ({
      inProp: true
    }));
    setTimeout(() => {
      self.setState(state => ({
        inProp: false
      }));
    }, TIMEOUT);
    this.judgeIsHome();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let path = nextProps.location.pathname;
    return {
      path
    };
  }

  onClose = e => {
    this.setState({
      isShowAnnouncement: false
    });
  };

  componentWillUnmount() {
    this._mounted = false;
  }

  componentDidMount() {
    this._mounted = true;
    this.props.history.listen((location, action) => {
      if (location) {
        this.judgeIsHome();
      }
    });
  }

  render() {
    const { lang } = this.appStore;
    const { isHome, isShowAnnouncement } = this.state;
    const pathname = this.props.location.pathname;
    const contentClass = ['/bitdeer', '/app-download'].includes(pathname)
      ? 'full-page-content'
      : '';

    return (
      <>
        <Helmet>
          <meta
            name="keywords"
            content={new Ts().getLocaleString('common.meta.keywords')}
          />
          <meta
            name="description"
            content={new Ts().getLocaleString('common.meta.description')}
          />
        </Helmet>
        {/* {isHome ? (
          <HomePageHeader
            style={{
              background: isHome && this.announcementConfig ? 'black' : null
            }}
          />
        ) : (
          <Header />
        )} */}
        <Header />
        {this.announcementConfig && isShowAnnouncement && (
          <Alert
            style={{ marginTop: isHome && isShowAnnouncement ? 40 : 0 }}
            message={this.announcementConfig.description}
            type="warning"
            closable
            onClose={this.onClose}
          />
        )}
        <div className={`page-content${' ' + contentClass}`}>
          {this.props.children}
          {/* <CSSTransition in={inProp} timeout={TIMEOUT} classNames="fade">
            {state => (
              <div className="fade">
                {this.props.children}
              </div>
            )}
          </CSSTransition> */}
        </div>
        <Footer
          lang={lang === 'zh-CN' ? 'zh-CN' : 'en'}
          explorerBaseUrl="http://btc.com"
        />
      </>
    );
  }
}

export default Layout;
