import { observable, action, runInAction, reaction, computed } from 'mobx';
import React from 'react';
import axios from 'axios';
import moment from 'moment';
import { parseLang } from 'utils';
import { defaultListPage } from 'config';
import { LIMIT_GROUP_NAME_LENGTH } from 'constants';
import Ts from 'localeTranslator';
import { message } from 'antd';
class MinersStore {
  //#region groups
  @observable
  loadingQueryGroups;

  @observable
  currentGroupId;

  @observable
  createEditModalVisible;

  @observable
  currentOpGroupItem;

  @observable
  modalType;

  @observable
  confirmModalVisible;

  @observable
  opGroupErrorMsg;

  @observable
  groupList;

  @observable
  loadingOpGroupStatus;

  //#endregion groups

  //#region miners list
  @observable
  loadingQueryMiners;

  @observable
  minersList;

  @observable
  minersListPage;

  @observable
  minersListTotal;

  @observable
  currentMinersStatus;

  @observable
  selectedMiners;

  @observable
  moveTargetGroupId;

  @observable
  confirmMinersOpType;

  @observable
  updateMinersConfirmVisible;

  @observable
  minerFilter; // 矿机搜索关键字

  @observable
  orderType;

  @observable
  orderField;

  @observable
  listReportTime; // 报告时间
  //#endregion

  constructor() {
    this.loadingQueryGroups = false;
    this.loadingQueryMiners = false;
    // groups
    this.createEditModalVisible = false;
    this.currentOpGroupItem = {};
    this.modalType = 'create'; // create:创建新分组|| move-create:移动时创建新分组 || move:移动到现有分组 || edit: 编辑
    this.confirmModalVisible = false;
    this.opGroupErrorMsg = null;
    this.groupList = [];
    this.currentGroupId = 0;
    this.loadingOpGroupStatus = false;

    // miners list
    this.minersList = [];
    this.minersListPage = { ...defaultListPage, page_size: 50 };
    this.minersListTotal = 0;
    this.minersStatus = ['all', 'inactive', 'active', 'dead'];
    this.currentMinersStatus = this.minersStatus[0];
    this.selectedMiners = [];
    this.moveTargetGroupId = null; // 要移动到的分组ID
    this.confirmMinersOpType = 'delete'; //delete|move
    this.updateMinersConfirmVisible = false;
    this.minerFilter = null;
    this.orderType = 1;
    this.orderField = 'worker_name';
    this.listReportTime = moment().format('YYYY-MM-DD HH:mm:ss');

    reaction(
      () => this.currentGroupId,
      currentGroupId => {
        this.getMiners(currentGroupId);
      }
    );
  }

  @observable lang = 'en-US';
  /**
   * 设置当前语言
   */
  @action
  setLang = lang => {
    this.lang = parseLang(lang);
  };

  //#region Group Section
  @action
  setCurrentGroupId = groupId => {
    this.currentGroupId = groupId;
    this.selectedMiners = [];
  };

  @computed
  get currentGroupData() {
    let cur = this.groupList.filter(
      g => g.gid + '' === this.currentGroupId + ''
    );
    return cur[0] || {}; // 必须显示调用groupList 才能在groupList触发的时候调用computed方法
    //return this.findGroup(this.currentGroupId);
  }

  @action
  findGroup = groupId => {
    let cur = this.groupList.filter(g => g.gid + '' === groupId + '');
    return cur[0] || {};
  };

  @action
  showCreateEditModal = (modalType, data) => {
    this.modalType = modalType;
    this.currentOpGroupItem = data || {};
    this.createEditModalVisible = true;
  };

  @action
  hideCreateEditModal = () => {
    this.createEditModalVisible = false;
    this.loadingOpGroupStatus = false;
    this.opGroupErrorMsg = null;
  };

  @action
  showConfirm = data => {
    this.currentOpGroupItem = data;
    this.confirmModalVisible = true;
  };

  @action
  hideConfirm = () => {
    this.confirmModalVisible = false;
  };

  @action
  getGroups = async loadGroups => {
    if (loadGroups) {
      this.loadingQueryGroups = true;
    }
    const res = await axios.get('worker/groups', {
      params: {
        page: 1,
        page_size: 52,
        lang: this.lang
      }
    });
    if (res && res.data) {
      runInAction(() => {
        this.groupList = res.data.list.sort(
          (item1, item2) => item1.sort_id - item2.sort_id
        );
        if (loadGroups) {
          this.loadingQueryGroups = false;
        }
      });
    }
  };

  @action
  addGroup = async ({ name: group_name }, successCallback) => {
    this.opGroupErrorMsg = null;

    if (!group_name) {
      this.opGroupErrorMsg = <Ts id="pages.miners.addGroupEmpty" />;
      return;
    } else if (group_name.length < LIMIT_GROUP_NAME_LENGTH.min) {
      this.opGroupErrorMsg = <Ts id="pages.miners.groupNameLimit" />;
      return;
    }

    try {
      this.loadingOpGroupStatus = true;
      const res = await axios.post('groups/create', {
        group_name
      });
      if (res && res.data) {
        const resData = res.data;
        if (resData.status) {
          runInAction(() => {
            const { gid, group_name: name, created_at } = res.data;
            const item = {
              gid,
              name,
              created_at,
              reject_percent: '0.00',
              shares_15m: '0',
              shares_unit: '',
              sort_id: this.groupList.length,
              workers_active: 0,
              workers_dead: 0,
              workers_inactive: 0,
              workers_total: 0
            };
            this.groupList.push(item);
            this.loadingOpGroupStatus = false;
            this.createEditModalVisible = false;

            // 创建并移动分组时，创建分组成功后执行
            successCallback && successCallback(gid);
          });
        } else if (resData === 'exist') {
          runInAction(() => {
            this.loadingOpGroupStatus = false;
            this.opGroupErrorMsg = <Ts id="pages.miners.exist" />;
          });
        } else if (typeof resData === 'string') {
          runInAction(() => {
            this.loadingOpGroupStatus = false;
            this.opGroupErrorMsg = resData;
          });
        }
      }
    } catch (error) {
      this.loadingOpGroupStatus = false;
      this.opGroupErrorMsg = typeof error.msg === 'string' ? error.msg : '';
      //message.error(error.msg);
    }
  };

  @action
  editGroup = async ({ gid, name: group_name }) => {
    try {
      this.opGroupErrorMsg = null;
      this.loadingOpGroupStatus = true;

      if (!group_name) {
        this.opGroupErrorMsg = <Ts id="pages.miners.addGroupEmpty" />;
        this.loadingOpGroupStatus = false;
        return;
      }

      const res = await axios.post(`groups/update/${gid}`, {
        group_name
      });
      if (res && res.data) {
        const { data } = res;
        if (data.status) {
          runInAction(() => {
            let tempList = this.groupList.slice();
            tempList.forEach(group => {
              if (group.gid + '' === gid + '') {
                group.name = group_name;
              }
            });
            this.groupList = tempList;

            this.loadingOpGroupStatus = false;
            this.createEditModalVisible = false;
          });
        } else {
          this.loadingOpGroupStatus = false;
          this.opGroupErrorMsg = <Ts id="pages.miners.exist" />;
        }
      }
    } catch (error) {
      if (error.msg && typeof error.msg === 'string') {
        this.opGroupErrorMsg = error.msg;
        this.loadingOpGroupStatus = false;
      }
    }
  };

  @action
  deleteGroup = async group_id => {
    const res = await axios.post(`groups/delete/${group_id}`, {});
    if (res && res.data) {
      runInAction(() => {
        if (res.data.status === true) {
          this.hideConfirm();
          this.getGroups();
          this.setCurrentGroupId('-1');
        }
      });
    }
  };
  //#endregion

  //#region Miner List Section

  @action
  setCurrentMinersStatus = status => {
    this.currentMinersStatus = status;
  };

  @action
  setMinersListPage = (page, page_size) => {
    this.minersListPage = {
      page,
      page_size
    };
  };

  @action
  getMiners = async () => {
    this.loadingQueryMiners = true;
    const res = await axios.get(`worker`, {
      params: {
        group: this.currentGroupId,
        status: this.currentMinersStatus,
        ...this.minersListPage,
        filter: this.minerFilter,
        asc: this.orderType === -1 ? 1 : this.orderType,
        order_by:
          this.orderField === undefined ? 'worker_name' : this.orderField // 默认按矿机名升序
      }
    });
    if (res && res.data) {
      runInAction(() => {
        this.minersList = res.data.data;
        this.minersListTotal = res.data.total_count;
        this.loadingQueryMiners = false;
        this.listReportTime = moment().format('YYYY-MM-DD HH:mm:ss');
      });
    }
  };

  /**
   * 设置要移动矿机的分组ID
   */
  @action
  setMoveTargetGroupId = targetGroupId => {
    this.moveTargetGroupId = targetGroupId;
  };

  /**
   * 删除矿机
   */
  @action
  deleteMiners = selectedMiners => {
    this.updateMiners(selectedMiners, 0);
  };

  /**
   * 移动矿机
   */
  @action
  updateMiners = async (selectedMiners, moveTargetGroupId) => {
    try {
      const res = await axios.post(`worker/update`, {
        worker_id: selectedMiners.join(','),
        group_id: moveTargetGroupId
      });
      if (res && res.data) {
        runInAction(() => {
          if (res.data.status === true) {
            this.selectedMiners = [];

            this.getGroups();
            this.getMiners();
          }
        });
      }
    } catch (error) {
      message.error(error.msg);
    }
  };

  @action
  setSelectedMiners = (selectedMiners = []) => {
    this.selectedMiners = selectedMiners;
  };

  @action
  showUpdateMinersConfirm = confirmMinersOpType => {
    this.confirmMinersOpType = confirmMinersOpType;
    this.updateMinersConfirmVisible = true;
  };

  @action
  hideUpdateMinersConfirm = () => {
    this.updateMinersConfirmVisible = false;
  };

  @action
  setMinersOrder = (orderType, orderField) => {
    this.orderType = orderType;
    this.orderField = orderField;

    this.selectedMiners = [];
    //this.minersListPage = defaultListPage;
    this.getMiners();
  };

  /**
   *搜索矿机
   */
  @action
  searchMiner = miner => {
    this.minerFilter = miner;
    this.minersListPage = { ...this.minersListPage, page: 1 }; // 搜索时，分页重置为第1页
    this.getMiners();
  };

  /**
   * 设置搜索关键字
   */
  @action
  setSearchFilter = miner => {
    this.minerFilter = miner;
  };

  /**
   * 重置搜索、排序、分页等过滤条件
   */
  @action
  resetFilters = () => {
    this.minerFilter = null;
    this.minersListPage = defaultListPage;
  };
  //#endregion
}

const minersStore = new MinersStore();

export { minersStore };
