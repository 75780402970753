import flatten from 'flat';
const commonMessage = {
  common: {
    locale: 'English',
    header: {
      home: 'Home',
      dashboard: 'Dashboard',
      getCoin: 'Get Coin',
      help: 'Help',
      miners: 'Miners',
      poolStats: 'Pool Stats',
      service: 'Service',
      tools: 'Tools',
      setting: 'Settings',
      guardian: 'Sharing',
      guardianPermission:
        'You are an authorized user who has the read-only access to the account.',
      watcher: 'Watcher',
      watcherPermission:
        'Watcher is an authorized user who has the read-only access to the sub-account.',
      userCenter: 'User Center',
      SignOut: 'Sign Out',
      scanToService: 'Scan QR code to get help',
      bitdeer: 'Bitdeer',
      hashnest: 'HashNest',
      app: 'App'
    },
    menu: {},
    setting: {
      balance: 'Current Balance',
      coinAddress: '{coinType} Address',
      setAddress: 'Please set a {coinType} Address.',
      menu: {
        hide: 'Hide',
        address: 'Modify Wallet Address',
        minPay: 'Modify Minimum Payment',
        payWithBtc: 'Pay with BTC',
        earingsHistory: 'Earnings',
        shareData: 'Share Data',
        alertSettings: 'Alert Settings',
        onebuttonSwitch: 'One-button Switch',
        userCenter: 'User Center',
        signOut: 'Sign Out'
      }
    },
    footer: {
      keep_touch: 'Keep In Touch With BTC.com Pool',
      social_network: 'COMMUNITY & SOCIAL NETWORKS',
      services: 'SERVICES',
      Helpfaq: 'Help & FAQs',
      ticket_system: 'Ticket System',
      Feedback: 'Feedback',
      Phone: 'Phone',
      about_btccom: 'About BTC.com',
      about_us: 'ABOUT US',
      what_btccom: "What's BTC.com",
      features: 'Technical Features',
      btc_Services: 'Services',
      blockchain: 'Blockchain Explorer',
      btc_pool: 'BTC.com Pool',
      Wallet: 'BTC.com Wallet',
      app: 'BTC.com App',
      pool_faqs: 'BTC.com Pool FAQs',
      create_account: 'CREATE AN ACCOUNT',
      not_receive_email: "I didn't receive the activation email",
      what_sub_account: 'What is a sub_account',
      mining_regions: 'About mining regions',
      get_wallet: 'Where can I get a bitcoin wallet',
      skill: 'ADVANCED SKILLS',
      set_events: 'Set alert events',
      set_watchers: 'Set watchers',
      start_mining: 'START MINING',
      get_miner: 'Where can I buy a bitcoin miner',
      connect_pool: 'How to connect my miner to pool',
      stable: 'Why my miners / hashrate not stable',
      build_farm: 'How to build a large farm',
      about_bitcoin: 'ABOUT MINING & BITCOIN',
      rejection_share: 'About rejection & share',
      profit: 'Why my profit reduces every 2 weeks',
      fee: 'About mining fee and Tx fee',
      what_pps: 'What is FPPS'
    },
    pageTitle: {
      home: 'BTC.com Pool, a better bitcoin mining pool',
      dashboard: 'Dashboard - BTC.com Pool',
      miners: 'Miners - BTC.com Pool',
      earningHistory: 'Earnings History - BTC.com Pool',
      poolStats: 'Pool Statistics - BTC.com Pool',
      guardian: 'The Guardian Invitation Link',
      guardianBind: 'Binding The Guardian Invitation Link - BTC.com Pool',
      subAccount: 'Create a sub-account - BTC.com pool',
      subAccountManage: 'Sub-account Manage - BTC.com pool',
      settings: 'Settings - BTC.com pool',
      getCoin: 'Get Coin - BTC.com pool',
      tools: 'Tools - BTC.com pool',
      bitdeer: 'Bitdeer - BTC.com Pool',
      watcherExpired: 'Watcher authority expired - BTC.com Pool',
      watcherIncorrect: 'Watcher link error - BTC.com Pool',
      app: 'App Download'
    },
    breadCrumbs: {
      home: 'Home',
      dashboard: 'Dashboard',
      playground: 'Playground',
      miners: 'Miners',
      earningHistory: 'Earnings',
      transactions: 'Transactions',
      poolStats: 'Pool Statistics',
      shareAccount: 'Share Accounts Setting',
      guardianBindingInvitation: 'Binding The Guardian Invitation Link',
      guardianBindingLink: 'The Guardian Account Management',
      subAccountManage: 'Sub-account Manage - {type}',
      hiddenSubAccounts: 'Hidden Sub-accounts Manage',
      settings: 'Settings',
      getCoin: 'Get Coin',
      tools: 'Tools'
    },
    currency: {
      currency: 'USD'
    },
    allSwitchMode: {
      btc: 'Bitcoin  (BTC)',
      bch: 'Bitcoin Cash  (BCH)',
      ubtc: 'United Bitcoin  (UBTC)',
      eth: 'Ethereum  (ETH)',
      etc: 'Ethereum Classic  (ETC)',
      dcr: 'Decred (DCR)',
      ltc: 'Litecoin (LTC)',
      grin: 'Grin',
      beam: 'BEAM'
    },
    operation: {
      name: 'Operation',
      success: 'Operation successful!',
      failed: 'Operation failed!'
    },
    meta: {
      keywords:
        'bitcoin,pool,mine,mining,btc.top,slushpool,btc,eth,etc,ltc,dcr,bsv,f2pool,antpool,slush,ghash,antminer,bw,pool,via,viabtc',
      description:
        'BTC.com pool is a whole new choice for bitcoin miners. BTC.com pool is with much more stable architecture, much better user experience, much lower fees and much stronger service.'
    },
    gotIt: 'Got it',
    showAll: 'Show All',
    foldMe: 'Fold All',
    copied: 'Copied',
    more: 'More',
    none: 'None',
    on: 'On',
    off: 'Off',
    all: 'All',
    modify: 'Modify',
    submit: 'Submit',
    ok: 'OK',
    cancel: 'Cancel',
    new: 'Add',
    address: 'Address',
    notFoundTip: 'Sorry, the page you are looking for does not exist'
  }
};
const commonMessageFlatten = flatten(commonMessage);
export default commonMessageFlatten;
