import { observable, action, computed } from 'mobx';
import axios from 'axios';
import history from '@/utils/history';
import { I18N_LANG } from '@/utils/constants.js';
class AppStore {
  @observable
  lang;

  constructor() {
    this.lang = this.getDefaultLang();
  }

  @computed get zenLang() {
    return this.lang.toLowerCase();
  }

  getDefaultLang() {
    let browserLang = navigator.language || navigator.userLanguage;
    let userSettingLang = localStorage.getItem('lang');
    let defaultLang =
      userSettingLang ||
      (browserLang.substr(0, 2) === 'en' ? 'en-US' : 'zh-CN');
    document.getElementsByTagName('html')[0].lang = defaultLang;
    if (!userSettingLang) {
      localStorage.setItem('lang', defaultLang);
    }
    return defaultLang;
  }

  @action
  setLocaleLang(lang) {
    this.lang = lang;
    document.getElementsByTagName('html')[0].lang = lang;
    localStorage.setItem('lang', lang);

    // 确保切换完后地址不带多语言，/zh-cn/tools 变成 /tools
    const location = history.location;
    const pathname = location.pathname.toLowerCase();
    if (!pathname.startsWith(`/${lang.toLowerCase()}`)) {
      let path = pathname;
      Object.values(I18N_LANG).forEach(ln => {
        if (pathname.startsWith(`/${ln.toLowerCase()}`) && ln !== lang) {
          path = path.substr(`/${ln.toLowerCase()}`.length);
        }
      });

      history.replace({ ...location, pathname: path });
    }
  }
  @action
  setAppLocale(appLocale) {
    this.appLocale = appLocale;
  }

  // 获取所有区base domain
  // TODO: 临时使用，分离完成后应该只有一个domain
  @action
  getAllUrlConfig = () =>
    new Promise(async (resolve, reject) => {
      // 获取所有的节点对应的信息
      try {
        const response = await axios.get(`public/v1/all-url-config`);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
}

const appStore = new AppStore();

export { appStore };
