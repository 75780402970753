import React from 'react';
import './index.scss';

const SvgIcon = ({
  iconName,
  className = '',
  size = 'sm',
  clickable = false
}) => {
  return (
    <svg
      className={`svg-icon ${size} ${
        clickable ? 'clickable' : ''
      } ${className}`}
      aria-hidden="true"
    >
      <use xlinkHref={`#${iconName}`} />
    </svg>
  );
};
export default SvgIcon;
