import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import Ts from 'localeTranslator';
import { Card } from 'antd';
import './index.scss';
import CoinListCard from './coinList/index';
import { computed } from 'mobx';
import { sortBy } from 'lodash';
@inject('store')
@observer
class UserList extends Component {
  constructor(props) {
    super(props);
    this.appStore = this.props.store.appStore;
    this.userStore = this.props.store.userStore;
  }
  static defaultProps = {
    isShowSetting: false,
    isShowHidden: false
  };

  state = {
    visible: false
  };

  // 对账户列表排序，确保用户选择的账户在第一个
  @computed get fiteredAlgorithmAccounts() {
    const { algorithmAccounts, accountInfo } = this.userStore;
    let accounts = algorithmAccounts.data;
    if (accountInfo && accountInfo.name) {
      accounts = sortBy(accounts, function(account) {
        return account.name === accountInfo.name ? 0 : 1;
      });
    }
    return accounts;
  }

  getExtraLink(account) {
    const { isShowHidden } = this.props;
    return (
      account.have_other_coins > 0 &&
      !isShowHidden && (
        <a
          className="user-list__card__add-coin"
          href={`/sub-account/create?subaccount_name=${account.name}`}
        >
          <Ts
            id="pages.account.addOthersCoins"
            defaultMessage={'Add other coins'}
          />
        </a>
      )
    );
  }

  render() {
    const { hiddenAlgorithmAccounts, accountAllOpen } = this.userStore;
    const { isShowSetting, isShowHidden } = this.props;
    const accountList = isShowHidden
      ? hiddenAlgorithmAccounts.data
      : this.fiteredAlgorithmAccounts;
    return (
      <div className={`user-list ${this.props.className}`}>
        {accountList.map((account, index) => {
          return (
            <Card
              key={index}
              className="user-list__card"
              size="small"
              title={
                <span>
                  {account.name}&ensp;{account.region_text}
                </span>
              }
              extra={this.getExtraLink(account)}
            >
              {account.algorithms.map((item, index) => {
                return (
                  <CoinListCard
                    key={index}
                    algorithm={item}
                    region_text={account.region_text}
                    showAll={accountAllOpen}
                    isShowSetting={isShowSetting}
                    isShowHidden={isShowHidden}
                    reloadList={this.props.reloadList}
                    afterChangeAccount={this.props.afterChangeAccount}
                  />
                );
              })}
            </Card>
          );
        })}
      </div>
    );
  }
}

export default UserList;
