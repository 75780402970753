import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { lowerCase } from 'utils';
import Ts from 'localeTranslator';
import UserList from './section/userList';
import Setting from './section/setting';
import GrinSwitch from './section/GrinSwitch';
import Navigation from '@/components/Navigation';
import './index.scss';

@withRouter
@inject('store')
@observer
class Header extends Component {
  constructor(props) {
    super(props);
    this.appStore = this.props.store.appStore;
    this.userStore = this.props.store.userStore;
  }

  state = {
    langVisible: 'none'
  };

  isShowGrinSwitch = coin_type => {
    // // 不显示grin switcher 的路由列表
    // const hideGrinSwitchPaths = [
    //   '/minerinfo',
    //   '/pool-stats',
    //   '/tools',
    //   '/bitdeer'
    // ];
    // console.log(this.props.location.pathname);
    // return (
    //   lowerCase(coin_type) === 'grin' &&
    //   !hideGrinSwitchPaths.some(
    //     item => this.props.location.pathname.indexOf(item) >= 0
    //   )
    // );
    return lowerCase(coin_type) === 'grin';
  };

  onLangClick = event => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    const { langVisible } = this.state;

    this.setState({
      langVisible: langVisible === 'none' ? 'block' : 'none'
    });
  };

  onItemLangClick = optLang => event => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    const { lang } = this.appStore;

    if (lang === optLang) return;
    this.appStore.setLocaleLang(optLang);
  };

  getAuthenticatedUserDisplay() {
    const {
      accountInfo,
      isAuthenticated,
      isUserReadOnly,
      hasSubAccount
    } = this.userStore;

    if (isAuthenticated && hasSubAccount) {
      return (
        <div className="header-content-right">
          {/* 如果是 grin 显示grin 算法切换 */}
          {this.isShowGrinSwitch(accountInfo.coin_type) && <GrinSwitch />}

          <UserList />
          {!isUserReadOnly && (
            <div className="header-right-setting">
              <Setting
                key={accountInfo.puid}
                puid={accountInfo.puid}
                coinType={accountInfo.coin_type}
              />
            </div>
          )}
        </div>
      );
    }
  }

  getLangDropdown() {
    const { lang } = this.appStore;
    const { langVisible } = this.state;
    return (
      <div className="lang" onClick={this.onLangClick}>
        <div className="lang-title">
          <Ts id={'common.locale'} />
          <span className="caret" />
        </div>
        <ul
          className="dropdown-menu lang-dropdown"
          style={{
            display: langVisible
          }}
        >
          <li
            className={`lang-option ${lang === 'en-US' ? 'disabled' : null}`}
            onClick={this.onItemLangClick('en-US')}
          >
            <span>English</span>
          </li>
          <li
            className={`lang-option ${lang === 'zh-CN' ? 'disabled' : null}`}
            onClick={this.onItemLangClick('zh-CN')}
          >
            <span>简体中文</span>
          </li>
        </ul>
      </div>
    );
  }

  render() {
    const { isUserReadOnly } = this.userStore;

    return (
      <div className="header">
        <div className="header-content">
          <div className="header-content-left">
            <h1 className="header-logo">
              <a
                href="https://pool.btc.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                BTC.com
              </a>
            </h1>
            <Navigation className="header-nav" />
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {this.getAuthenticatedUserDisplay()}
            {this.getLangDropdown()}
          </div>
        </div>
      </div>
    );
  }
}
export default Header;
