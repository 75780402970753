import React from 'react';
import { timeRangeOpitons } from 'config';
import './index.scss';

const getItems = (options, lang, onChange, defaultValue, currentValue) => {
  return options.map(item => (
    <li
      key={item.value}
      onClick={() => {
        onChange(item.value);
      }}
      className={`${
        currentValue === item.value ||
        (!currentValue && defaultValue === item.value)
          ? 'active'
          : ''
      }`}
    >
      <a href="javascript:void 0">{item[`name_${lang.substr(0, 2)}`]}</a>
    </li>
  ));
};
const GroupSelector = ({
  options = timeRangeOpitons,
  lang,
  defaultValue,
  currentValue,
  onChange,
  style,
  className
}) => {
  return (
    <div className={`group-selector-container ${className}`} style={style}>
      <ul>{getItems(options, lang, onChange, defaultValue, currentValue)}</ul>
    </div>
  );
};
export default GroupSelector;
