const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   exact: true,
  //   page: '@/pages/HomePage',
  //   component: () => import(/* webpackChunkName:'homePage' */ './HomePage')
  // },
  {
    path: '/home',
    name: 'Home',
    exact: true,
    page: '@/pages/HomePage',
    component: () => import(/* webpackChunkName:'homePage' */ './HomePage')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    exact: true,
    requiresAuth: true,
    isDependSubAccount: true, //依赖子账户，必须先创建子账户
    page: '@/pages/Dashboard',
    component: () => import(/* webpackChunkName:'dashboard' */ './Dashboard')
  },
  // {
  //   path: '/tools',
  //   name: 'PoolTools',
  //   component: () => import(/* webpackChunkName:'poolTools' */ './PoolTools')
  // },
  {
    path: '/miners/:groupid?',
    name: 'Miners',
    requiresAuth: true,
    isDependSubAccount: true, //依赖子账户，必须先创建子账户
    component: () => import(/* webpackChunkName:'miners' */ './Miners')
  },
  {
    path: '/minerinfo/:minerid',
    name: 'MinerInfo',
    requiresAuth: true,
    isDependSubAccount: true, //依赖子账户，必须先创建子账户
    component: () => import(/* webpackChunkName:'minerInfo' */ './MinerInfo')
  },
  {
    path: '/earning-history',
    name: 'EarningHistory',
    requiresAuth: true,
    isDependSubAccount: true, //依赖子账户，必须先创建子账户
    component: () =>
      import(/* webpackChunkName:'earningHistory' */ './EarningHistory')
  },
  {
    path: '/merge-mining-earning',
    name: 'MergeEarningHistory',
    requiresAuth: true,
    isDependSubAccount: true, //依赖子账户，必须先创建子账户
    component: () =>
      import(/* webpackChunkName:'earningHistory' */ './EarningHistory')
  },
  {
    path: '/transactions',
    name: 'TransactionHistory',
    requiresAuth: true,
    isDependSubAccount: true, //依赖子账户，必须先创建子账户
    component: () =>
      import(/* webpackChunkName:'TransactionHistory' */ './TransactionHistory')
  },
  // {
  //   path: '/pool-stats',
  //   name: 'PoolStats',
  //   component: () => import(/* webpackChunkName:'poolStats' */ './PoolStats')
  // },
  {
    path: '/guardian',
    name: 'Guardian',
    exact: true,
    requiresAuth: true,
    isDependSubAccount: true, //依赖子账户，必须先创建子账户
    rejectWatcher: true,
    component: () => import(/* webpackChunkName:'guardian' */ './Guardian')
  },
  {
    path: '/guardian/bind',
    name: 'GuardianBind',
    requiresAuth: true,
    rejectWatcher: true,
    component: () =>
      import(/* webpackChunkName:'guardianbind' */ './GuardianBind')
  },
  // {
  //   path: '/manage-account',
  //   name: 'ManageAccount',
  //   requiresAuth: true,
  //   rejectWatcher: true,
  //   component: () => import(/* webpackChunkName:'account' */ './Account')
  // },
  // {
  //   path: '/advance-account',
  //   name: 'AdvanceAccount',
  //   requiresAuth: true,
  //   rejectWatcher: true,
  //   component: () =>
  //     import(/* webpackChunkName:'advanceAccount' */ './AdvanceAccount')
  // },
  // {
  //   //创建子账户
  //   path: '/sub-account',
  //   name: 'SubAccount',
  //   requiresAuth: true,
  //   rejectWatcher: true,
  //   component: () => import(/* webpackChunkName:'subAccount' */ './SubAccount')
  // },
  // {
  //   path: '/settings',
  //   name: 'Settings',
  //   requiresAuth: true,
  //   isDependSubAccount: true, //依赖子账户，必须先创建子账户
  //   rejectWatcher: true,
  //   component: () => import(/* webpackChunkName:'settings' */ './Settings')
  // },
  // {
  //   path: '/merge/getCoin',
  //   name: 'MergeMining',
  //   requiresAuth: true,
  //   component: () =>
  //     import(/* webpackChunkName:'mergeMining' */ './MergeMining')
  // },
  // {
  //   path: '/bitdeer',
  //   name: 'Bitdeer',
  //   component: () => import(/* webpackChunkName:'bitdeer' */ './Bitdeer')
  // },
  // {
  //   path: '/app-download',
  //   name: 'AppDownload',
  //   component: () =>
  //     import(/* webpackChunkName:'AppDownload' */ './AppDownload')
  // },
  {
    path: '/watcher/expired',
    name: 'watcherExpired',
    component: () =>
      import(/* webpackChunkName:'watcherExpired' */ './Watcher/expired')
  },
  {
    path: '/watcher/incorrect',
    name: 'watcherIncorrect',
    component: () =>
      import(/* webpackChunkName:'watcherExpired' */ './Watcher/incorrect')
  }
];

export default routes;
