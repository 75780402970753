import { observable, action, runInAction, reaction } from 'mobx';
import axios from 'axios';
import { lowerCase } from 'utils';
import { defaultListPage } from 'config';
class PoolStatsStore {
  @observable
  loadingStats;

  @observable
  coinList;

  @observable
  currentCoin;

  @observable
  chartTimeRange;

  @observable
  currentHashrateData;

  //#region Lucky
  @observable
  loadingLucky;

  @observable
  luckyList;
  //#endregion

  //#region Lucky
  @observable
  loadingBlockList;

  @observable
  blockList;

  @observable
  blockListPage;
  //#endregion

  constructor() {
    this.loadingStats = false;
    this.coinList = [];
    this.chartTimeRange = '1h';

    this.currentCoin = { name: 'BTC' };
    this.currentHashrateData = {
      data: [],
      unit: ''
    };

    //Lucky
    this.loadingLucky = false;
    this.luckyList = [];

    //Block List
    this.blockList = [];
    this.blockListPage = defaultListPage;
    this.loadingBlockList = false;

    reaction(
      () => this.chartTimeRange,
      chartTimeRange => {
        this.getCurrentCoinHashrateData(chartTimeRange);
      }
    );
  }

  @action
  setCurrentCoin = coin => {
    if (this.coinList.length > 0 && coin) {
      this.currentCoin = this.coinList.filter(c => c.name === coin)[0];
    } else {
      this.currentCoin = { name: coin };
    }
  };

  @action
  getPoolStatsOverviewInfo = async (triggerLoading = true, successCallback) => {
    if (triggerLoading) {
      this.loadingStats = true;
    }
    const res = await axios.get(`/pool/multi-coin-stats`, {
      params: {
        dimension: '1h',
        no_share_history: 1,
        is_decimal: 1
      }
    });
    if (res && res.data) {
      if (res.data.bsv) {
        delete res.data.bsv;
      }
      runInAction(() => {
        let tempList = [];
        for (let c in res.data) {
          const { stats, coin_type, ...others } = res.data[c];
          let item = {
            name: coin_type,
            coin_type,
            ...others,
            ...stats
          };
          tempList.push(item);
        }
        this.coinList = tempList;
        this.loadingStats = false;
        successCallback && successCallback();
      });
    }
  };

  getAllByCoinType = lang => {
    this.getCurrentCoinHashrateData();
    this.getLuckyInfo();
    this.getBlockList(lang);
  };

  @action
  setChartTimeRange = timeRange => {
    this.chartTimeRange = timeRange;
  };

  @action
  getTimeRangeMap = timeRange => {
    let mapper = {
      '1d': {
        count: 168,
        xAixsFormatter: 'MM/DD'
      },
      '1h': {
        count: 72,
        xAixsFormatter: 'HH:mm'
      }
    };
    return mapper[timeRange];
  };

  @action
  getCurrentCoinHashrateData = async (triggerLoading = true) => {
    if (triggerLoading) {
      this.loadingChart = true;
    }
    let timeRangeMap = this.getTimeRangeMap(this.chartTimeRange);
    const { count } = timeRangeMap;
    const res = await axios.get(`public/v1/pool/share-history/merge`, {
      params: {
        coin_type: lowerCase(this.currentCoin.name),
        dimension: this.chartTimeRange,
        count,
        real_point: 1
      }
    });
    if (res && res.data) {
      runInAction(() => {
        const { tickers, unit } = res.data;
        this.currentHashrateData = {
          data: tickers.map(t => {
            return {
              time: t[0] * 1000,
              hashrate: t[1]
            };
          }),
          unit: unit
        };
        this.loadingChart = false;
      });
    }
  };

  @action
  getLuckyInfo = async (triggerLoading = true) => {
    if (triggerLoading) {
      this.loadingLucky = true;
    }
    const res = await axios.get(`/pool/lucky/merge`, {
      params: {
        coin_type: lowerCase(this.currentCoin.name)
      }
    });
    if (res && res.data) {
      runInAction(() => {
        let tempList = [];
        for (let c in res.data) {
          let item = {
            name: c,
            value: res.data[c]
          };
          tempList.push(item);
        }
        this.luckyList = tempList;
        this.loadingLucky = false;
      });
    }
  };

  //#region Block List
  @action
  getBlockList = async (lang, triggerLoading = true) => {
    if (triggerLoading) {
      this.loadingBlockList = true;
    }
    const res = await axios.get(`public/v1/pool/blocks/merge`, {
      params: {
        lang,
        coin_type: this.currentCoin.name.toLowerCase(),
        is_decimal: 1,
        ...this.blockListPage
      }
    });
    if (res && res.data) {
      runInAction(() => {
        const { data, total } = res.data.blocks;
        this.blockList = data;
        this.blockListTotalCount = total;
        this.loadingBlockList = false;
      });
    }
  };

  @action
  resetBlockListPage = () => {
    this.blockListPage = defaultListPage;
  };

  @action
  setBlockListPage = (page, page_size) => {
    this.blockListPage = {
      page,
      page_size
    };
  };
  //endregion
}

const poolStatsStore = new PoolStatsStore();

export { poolStatsStore };
