import React from 'react';
import LoadingIcon from '../LoadingIcon';
import './index.scss';
const LoadingWrap = ({ loading, children }) => (
  <div>
    {loading ? (
      <div className="loading-wrap">
        <div className="spin-holder">
          <LoadingIcon />
        </div>
        <div className="loading-content-container">{children}</div>
      </div>
    ) : (
      children
    )}
  </div>
);

export default LoadingWrap;
