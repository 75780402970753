import React, { PureComponent } from 'react';
import { Icon } from 'antd';
import './index.scss';
class BtcCollapse extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      expand: this.props.defaultExpand || false
    };
  }

  toggle = () => {
    this.setState({ expand: !this.state.expand });
  };
  render() {
    const { title, children } = this.props;
    const { expand } = this.state;
    return (
      <div className="btc-collapse-container">
        <div className="btc-collapse-header">
          <div className="collapse-title-wrap">
            <span className="collapse-title" onClick={this.toggle}>
              {title}
              {expand ? (
                <Icon className="collapse-toggle" type="up" />
              ) : (
                <Icon className="collapse-toggle" type="down" />
              )}
            </span>
          </div>
        </div>
        <div
          className={`btc-collapse-content 
          ${expand ? 'btc-collapse-expand' : 'btc-collapse-curlup'}
          `}
        >
          {children}
        </div>
      </div>
    );
  }
}

export default BtcCollapse;
