import React, { PureComponent } from 'react';
import Ts from 'localeTranslator';
import { BtcModal } from '@/components/widget';

class ServiceModal extends PureComponent {
  render() {
    const { visible, onCancel } = this.props;

    return (
      <BtcModal
        visible={visible}
        title={<Ts id="common.header.scanToService" />}
        hideOkButton={true}
        onCancel={onCancel}
        hideCancelButton={true}
      >
        <div className="header-service">
          <div className="header-service-content" />
        </div>
      </BtcModal>
    );
  }
}

export default ServiceModal;
