import flatten from 'flat';
const commonMessage = {
  common: {
    locale: '简体中文',
    header: {
      home: '首页',
      dashboard: '用户面板',
      getCoin: '挖矿送币',
      help: '帮助',
      miners: '矿机',
      poolStats: '统计',
      service: '客服',
      tools: '工具',
      setting: '设置',
      guardian: '共享',
      guardianPermission:
        '您仅享有账户数据的查看权限，但无法进行任何编辑操作。',
      watcher: '观察者',
      watcherPermission:
        '观察者享有子账户数据的查看权限，但无法进行任何编辑操作。',
      userCenter: '用户中心',
      SignOut: '退出登录',
      scanToService: '扫码获取帮助',
      bitdeer: 'Bitdeer专场',
      hashnest: '算力巢',
      app: 'App'
    },
    menu: {},
    setting: {
      balance: '当前余额',
      coinAddress: '{coinType} 收款地址',
      setAddress: '请点击设置{coinType}收款地址',
      menu: {
        hide: '隐藏',
        address: '修改收款地址',
        minPay: '修改最小起付金额',
        payWithBtc: '以BTC结算',
        earingsHistory: '收益',
        shareData: '共享数据',
        alertSettings: '报警设置',
        onebuttonSwitch: '一键切换',
        userCenter: '用户中心',
        signOut: '退出登录'
      }
    },
    footer: {
      keep_touch: '联系BTC.com矿池',
      social_network: '社区与社交网络',
      services: '服务',
      Helpfaq: '帮助页面与常见问题',
      ticket_system: '工单系统',
      Feedback: '反馈',
      Phone: '客服电话',
      about_btccom: '关于BTC.com',
      about_us: '联系我们',
      what_btccom: 'BTC.com简介',
      features: '技术优势',
      btc_Services: '服务',
      blockchain: '区块浏览器',
      btc_pool: 'BTC.com矿池',
      Wallet: 'BTC.com钱包',
      app: 'BTC.com浏览器 APP',
      pool_faqs: 'BTC.com 矿池常见问题',
      create_account: '创建账户',
      not_receive_email: '为什么没收到验证邮件',
      what_sub_account: '子账户是什么',
      mining_regions: '关于挖矿节点',
      get_wallet: '如何获取比特币钱包 ',
      skill: '已经推出贴心服务',
      set_events: '设置事件警报',
      set_watchers: '设置监护人',
      start_mining: '开始挖矿',
      get_miner: '如何购买比特币矿机 ',
      connect_pool: '如何连接矿池 ',
      stable: '为什么矿机算力不稳定 ',
      build_farm: '如何建设大型矿场 ',
      about_bitcoin: '关于挖矿&比特币',
      rejection_share: '关于拒绝率',
      profit: '为什么每两周收益会减少',
      fee: '挖矿手续费与交易手续费有什么区别',
      what_pps: 'FPPS是什么'
    },
    pageTitle: {
      home: 'BTC.com 矿池，更好的比特币矿池',
      dashboard: '用户面板 - BTC.com Pool',
      miners: '矿机管理 - BTC.com Pool',
      earningHistory: '收益历史 - BTC.com Pool',
      poolStats: '矿池统计 - BTC.com Pool',
      guardian: '守护者邀请链接 - BTC.com pool',
      guardianBind: '绑定守护者邀请链接 - BTC.com Pool',
      subAccount: '创建子账户 - BTC.com pool',
      subAccountManage: '子账户管理 - BTC.com pool',
      settings: '设置 - BTC.com pool',
      getCoin: '挖矿送币 - BTC.com pool',
      tools: '工具 - BTC.com pool',
      bitdeer: 'Bitdeer专场 - BTC.com Pool',
      watcherExpired: '观察者权限已过期 - BTC.com Pool',
      watcherIncorrect: '观察者链接错误 - BTC.com Pool',
      app: 'App下载'
    },
    breadCrumbs: {
      home: '首页',
      dashboard: '用户面板',
      playground: 'Playground',
      miners: '矿机',
      earningHistory: '收益记录',
      transactions: '收款记录',
      poolStats: '统计',
      shareAccount: '共享账户设置',
      guardianBindingInvitation: '绑定守护者邀请链接',
      guardianBindingLink: '绑定守护者链接',
      subAccountManage: '子账户管理 - {type}',
      hiddenSubAccounts: '隐藏子账户管理',
      settings: '设置',
      getCoin: '挖矿送币',
      tools: '工具'
    },
    currency: {
      currency: 'CNY'
    },
    allSwitchMode: {
      btc: '比特币(BTC)',
      bch: '比特币现金(BCH)',
      ubtc: '比特联储(UBTC)',
      eth: '以太坊(ETH)',
      etc: '以太坊经典(ETC)',
      dcr: 'Decred (DCR)',
      ltc: '莱特币(LTC)',
      grin: 'GRIN',
      beam: 'BEAM'
    },
    users: {
      userCenter: '用户中心',
      SignOut: '退出登录'
    },
    operation: {
      name: '操作',
      success: '操作成功!',
      failed: '操作失败!'
    },
    meta: {
      keywords:
        '比特币,矿池,挖矿,BTC,btc.top,bsv,eth,etc,dcr,ltc,grin,beam,鱼池,slushpool,蚁池,via,viabtc,f2pool,antpool,bw,pool,蚂蚁,矿机',
      description:
        'BTC.com 矿池是比特币挖矿的全新选择，相比传统矿池，BTC.com 矿池拥有更稳定的矿池系统，更好的用户体验，更优惠的费率，更强大的配套设施。'
    },
    gotIt: '我知道了',
    foldMe: '全部折叠',
    showAll: '全部展开',
    copied: '已复制',
    more: '更多',
    none: '无',
    on: '开启',
    off: '关闭',
    all: '全部',
    modify: '修改',
    submit: '提交',
    ok: '确认',
    cancel: '取消',
    new: '新建',
    address: '地址',
    notFoundTip: '对不起，您当前访问的页面不存在'
  }
};
const commonMessageFlatten = flatten(commonMessage);
export default commonMessageFlatten;
