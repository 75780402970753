import { helpBaseURL } from 'constants';
import { default as coinsConfig, coinExplorerConfig } from './coins';

const defaultPageSizeOptions = ['50', '100', '200', '500'];

const defaultListPage = {
  page: 1,
  page_size: 50
};

const timeRangeOpitons = [
  { value: '1d', name_zh: '1天', name_en: '1D' },
  { value: '1h', name_zh: '1小时', name_en: '1H' }
];

// Announcement query url from helpcenter
const announcementQueryURL = {
  baseURL: process.env.NODE_ENV === 'development' ? '/zendesk/' : helpBaseURL,
  'zh-CN':
    '/api/v2/help_center/zh-cn/categories/360001217931/articles.json?sort_by=created_at&sort_order=desc',
  'en-US':
    '/api/v2/help_center/zh-cn/categories/360001217931/articles.json?sort_by=created_at&sort_order=desc'
};

// chart colors map
const chartColors = ['#3182bd', '#fdae6b', '#2ec7c9', '#d87a80'];

export {
  coinsConfig,
  coinExplorerConfig,
  defaultPageSizeOptions,
  defaultListPage,
  timeRangeOpitons,
  announcementQueryURL,
  chartColors
};
