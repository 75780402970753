import { observable, action, runInAction, computed } from 'mobx';
import axios from 'axios';
import { coinsConfig } from '@/utils/config';
import { isEmpty } from 'lodash';

class HomepageStore {
  //#region Stats Info
  @observable
  loadingCoins = false;

  @observable
  activities = [];

  @observable
  coinList = {};

  @observable
  networkInfos = {};

  @observable
  bannerInfos = [];

  @observable
  announcementInfos = [];

  @observable
  isHome = false;

  constructor() {
    this.activities = this.getCachedActivities();
    this.coinList = this.getCachedCoinList();
    this.networkInfos = this.getCachedNetworkInfos();
    this.bannerInfos = this.getCachedBannerConfigs();
  }

  @computed get coinListWithNetwork() {
    const self = this;
    return new Proxy(this.coinList, {
      get(target, key) {
        let coin = target[key];
        let info = {};
        if (self.networkInfos[key]) {
          info = {
            ...self.networkInfos[key],
            price_cny:
              self.networkInfos[key].income_coin === 0
                ? 0
                : parseFloat(
                    self.networkInfos[key].income_cny /
                      self.networkInfos[key].income_coin
                  ),
            price_usd:
              self.networkInfos[key].income_coin === 0
                ? 0
                : parseFloat(
                    self.networkInfos[key].income_usd /
                      self.networkInfos[key].income_coin
                  )
          };
        }
        const defaultConfig = {
          income_cny: 0,
          income_coin: 0,
          income_usd: 0,
          unit: '',
          price_cny: 0,
          price_usd: 0,
          stats: {
            shares: {
              shares_15m: 0,
              shares_unit: coinsConfig[key.toLowerCase()].hashrateScale
            }
          }
        };
        // 还能返回数组？？？
        if (isEmpty(coin.stats)) {
          coin.stats = defaultConfig.stats;
        }
        return { ...defaultConfig, ...coin, ...info };
      },
      getOwnPropertyDescriptor(k) {
        return {
          enumerable: true,
          configurable: true
        };
      }
    });
  }

  @computed get bannerConfigs() {
    const now = new Date();
    return (this.bannerInfos || []).filter(config => {
      if (config.begin && now < new Date(config.begin * 1000)) {
        return false;
      }
      if (config.end && now > new Date(config.end * 1000)) {
        return false;
      }
      return true;
    });
  }

  @computed get activityConfigs() {
    const now = new Date();
    return (this.activities || []).filter(config => {
      if (config.begin && now < new Date(config.begin * 1000)) {
        return false;
      }
      if (config.end && now > new Date(config.end * 1000)) {
        return false;
      }
      return true;
    });
  }

  // 读取缓存的币种列表
  getCachedCoinList = () => {
    let coinList = localStorage.getItem('coinList');
    if (coinList) {
      coinList = JSON.parse(coinList);
      coinList = {
        ...this.coinList,
        ...coinList
      };
    } else {
      coinList = this.coinList;
    }
    return coinList;
  };

  // 读取缓存的币种网络相关列表
  getCachedNetworkInfos = () => {
    let networkInfos = localStorage.getItem('coinNetworkInfos');
    if (networkInfos) {
      networkInfos = JSON.parse(networkInfos);
      networkInfos = {
        ...this.networkInfos,
        ...networkInfos
      };
    } else {
      networkInfos = this.networkInfos;
    }
    return networkInfos;
  };

  @action
  getActivities = async lang => {
    const response = await axios.get(
      `poster/${process.env.API_ENV}/zendesk.json?t=${Date.now()}`
    );
    if (response && response.data) {
      runInAction(() => {
        this.activities = response.data || [];
        localStorage.setItem('activities', JSON.stringify(this.activities));
      });
    }
  };

  // 读取缓存的用户信息
  getCachedActivities = () => {
    let activities = localStorage.getItem('activities');
    if (activities) {
      activities = JSON.parse(activities);
    } else {
      activities = this.activities;
    }
    return activities;
  };

  // 获取首页币种列表
  @action
  getCoinList = async () => {
    const response = await axios.get('pool/multi-coin-stats', {
      params: { dimension: '1h', is_decimal: 1 }
    });
    if (response && response.data) {
      if (response.data.bsv) {
        delete response.data.bsv;
      }
      runInAction(() => {
        this.coinList = response.data;
        localStorage.setItem('coinList', JSON.stringify(this.coinList));
      });
    }
  };

  /**
   * 获取币种网站状况相关信息
   * 算力、价格、下次算力等
   * @memberof HomepageStore
   */
  @action
  getCoinsNetworkInfos = async () => {
    const response = await axios.get('coins-income');
    if (response && response.data) {
      runInAction(() => {
        this.networkInfos = { ...this.networkInfos, ...response.data };
        localStorage.setItem(
          'coinNetworkInfos',
          JSON.stringify(this.networkInfos)
        );
      });
    }
  };

  @action
  getBannerConfigs = async () => {
    const response = await axios.get(
      `poster/${process.env.API_ENV}/banner.json?t=${Date.now()}`
    );
    if (response && response.data) {
      runInAction(() => {
        this.bannerInfos = response.data || [];

        localStorage.setItem('bannerInfos', JSON.stringify(this.bannerInfos));
      });
    }
  };

  @action
  getCachedBannerConfigs = () => {
    let bannerInfos = localStorage.getItem('bannerInfos');
    if (bannerInfos) {
      bannerInfos = JSON.parse(bannerInfos);
    } else {
      bannerInfos = this.bannerInfos;
    }
    return bannerInfos;
  };

  @action
  setIsHome = isHome => {
    runInAction(() => {
      this.isHome = isHome;
    });
  };
}

const homepageStore = new HomepageStore();

export { homepageStore };
