import { observable, action, runInAction, computed } from 'mobx';
import axios from 'axios';
import { groupBy } from 'lodash';
import Cookies from 'js-cookie';
import queryString from 'qs';
import history from '@/utils/history';
import qs from 'qs';
import ReactGA from 'react-ga';
import { I18N_LANG } from '@/utils/constants';
import sensors from 'sa-sdk-javascript';
class UserStore {
  __default_account = {
    puid: 0,
    uid: 0,
    access_key: '',
    coin_type: '',
    current_coin: '',
    user_name: '',
    region: '',
    name: '',
    user_info: {
      mail: '',
      phone: {
        country: '',
        number: ''
      },
      avatar_pic: ''
    },
    // TODO: account 目前只能通过v1/account/sub-account/algorithms/morelist 获取
    account: {
      name: '', // 账户名
      have_default: false // 是否有默认子账户
    },
    region_conf: {
      text: {
        'zh-cn': '',
        en: ''
      }
    }
  };
  // 当前账户信息
  @observable accountInfo = this.__default_account;
  // 是否登录
  @observable isAuthenticated = false;
  // 是否是观察者,(是观察者情况下，isAuthenticated 会设为 true)
  @observable isWatcher = false;
  // 是否是被共享者,(是被共享者情况下，isAuthenticated 会设为 true)
  @observable isShared = false;
  // 子账户列表，账户区域列表 -> 算法列表 -> 子账户列表
  @observable algorithmAccounts = {
    loading: false,
    firstTimeLoading: true,
    errorMsg: '',
    data: []
  };
  // 隐藏子账户
  @observable hiddenAlgorithmAccounts = {
    loading: false,
    firstTimeLoading: true,
    errorMsg: '',
    data: []
  };

  // puid 对应的算力列表
  // TODO: 目前需要单独请求各个puid，完全没必要，合并到子账户列表中
  @observable hashrateMiners = {};

  // 子账户显示总开关
  @observable accountAllOpen = true;

  // 子账户 专业版
  @observable advanceAccount = {
    loading: false,
    errorMsg: '',
    data: []
  };

  @observable isRefreshingNewToken = false;

  @observable initializing = false;

  // 用户只读（观察者、共享者）
  @computed get isUserReadOnly() {
    // return this.isWatcher || this.isShared;
    return true; // 小鹿分支统一为true
  }

  // 子账户列表，由最小维度的子账户组成
  @computed get subAccounts() {
    let result = [];
    this.algorithmAccounts.data.forEach(regions => {
      regions.algorithms.forEach(algorithm => {
        result = result.concat(algorithm.coin_accounts);
      });
    });
    return result;
  }

  // 子账户列表，由最小维度的子账户组成
  @computed get hiddenSubAccounts() {
    let result = [];
    this.hiddenAlgorithmAccounts.data.forEach(regions => {
      regions.algorithms.forEach(algorithm => {
        result = result.concat(algorithm.coin_accounts);
      });
    });
    return result;
  }

  /**
   * 判断当前账户是否含有子账户
   */
  @computed
  get hasSubAccount() {
    let { puid } = this.accountInfo;
    return !!puid;
  }

  @computed
  get userContactType() {
    let contactType = '';
    const { contact } = this.accountInfo;
    if (!contact) {
      return '';
    }
    if (!contact.mail || contact.mail.length === 0) {
      contactType = 'sms';
    } else {
      contactType = 'mail';
    }
    return contactType;
  }

  // 重置store
  initStore = () => {
    runInAction(() => {
      this.accountInfo = this.__default_account;
      this.isAuthenticated = false;
      this.isWatcher = false;
      this.isShared = false;
      this.isRefreshingNewToken = false;
      this.algorithmAccounts = {
        loading: false,
        firstTimeLoading: true,
        errorMsg: '',
        data: []
      };
      this.hiddenAlgorithmAccounts = {
        loading: false,
        firstTimeLoading: true,
        errorMsg: '',
        data: []
      };
      this.hashrateMiners = {};
      this.advanceAccount = {
        loading: false,
        errorMsg: '',
        data: []
      };
    });
  };

  // 读取缓存的用户信息
  getCachedAccount = () => {
    let account = this.accountInfo;
    let storageAccount = localStorage.getItem('accountInfo');
    if (storageAccount) {
      storageAccount = JSON.parse(storageAccount);
      const isCurrent = this.checkCachedAccount(storageAccount);

      if (isCurrent) {
        account = {
          ...account,
          ...storageAccount
        };
      }
    }

    return account;
  };

  // 检查缓存用户是否是用户中心当前账户
  // 如果不是，清除缓存
  checkCachedAccount = account => {
    const uid = Cookies.get(process.env.BTC_POOL_USER_UID);
    const cachedUid = account.user_id || account.uid || 0;
    if (uid && uid !== cachedUid.toString()) {
      localStorage.removeItem('accountInfo');
      localStorage.removeItem('puid');
      return false;
    }
    return true;
  };

  // 获取默认账户是否显示
  getDefaultAccountShow = () => {
    // 子账户是展开还是折叠
    const account_show = localStorage.getItem('account_show');
    if (account_show && typeof account_show !== 'undefined') {
      this.accountAllOpen = account_show === '1';
    } else {
      localStorage.setItem('account_show', this.accountAllOpen ? '1' : '0');
    }
  };

  // 获取puid/access_key,从localStorage中获取，如果获取不到，跳转到登录页面
  // TODO: 用户中心改造完成后，采用JWT修改
  @action
  initAccount = () => {
    runInAction(() => {
      const self = this;
      this.accountInfo = {
        ...this.accountInfo,
        puid: 0,
        uid: 0
      };
      const isAuthenticated = this.checkLoginState();
      if (isAuthenticated) {
        if (!this.isUserReadOnly) {
          this.accountInfo = this.getCachedAccount();
        }
        // check login state every 10 min
        // TODO: maybe need shorter
        setInterval(() => {
          self.checkLoginState();
        }, 1000 * 60 * 10);
        this.getDefaultAccountShow();
      }
    });
  };

  /**
   * 检查登录状态
   * 1. 未登录或者用户中心jwt已过期(15天)
   * 2. 矿池校验jwt时间窗口已失效(3小时)
   * 3. 已登录
   *
   * @memberof UserStore
   */
  @action
  checkLoginState = async () => {
    const jwtToken = Cookies.get(process.env.BTC_POOL_USER_JWT_TOKEN);
    const userRefreshToken = Cookies.get(
      process.env.BTC_POOL_USER_REFRESH_TOKEN
    );
    const isWatcher = this.checkWatcher();
    const isShared = this.checkShared();
    if (isWatcher) {
      runInAction(() => {
        this.isAuthenticated = true;
        this.isWatcher = true;
      });
    } else if (isShared) {
      runInAction(() => {
        this.isAuthenticated = true;
        this.isShared = true;
      });
    } else if (!jwtToken && !userRefreshToken) {
      Cookies.remove('user-jwt-expire');
      Cookies.remove('pool-jwt-expire');
      // this.jumpToLogin();
      return this.isAuthenticated;
    } else if (userRefreshToken && !jwtToken) {
      // 存在user-refresh-token 但没有 user-jwt-token
      runInAction(() => {
        this.isAuthenticated = true;
      });
      await this.requestJWTToken();
    } else {
      runInAction(() => {
        this.isAuthenticated = true;
      });
    }

    return this.isAuthenticated;
  };

  /**
   * 通过url初步判断是否是观察者
   * 保存access_key到全局变量中
   *
   * @memberof UserStore
   */
  @action
  checkWatcher = () => {
    let params = queryString.parse(history.location.search, {
      ignoreQueryPrefix: true
    });
    if (params && params.access_key) {
      if (params.access_key.startsWith('r_')) {
        global.access_key = params.access_key;
        if (params.puid) {
          global.puid = params.puid;
        }
        return true;
      }
    }

    return false;
  };

  /**
   * 通过url初步判断是否是共享者
   * 保存puid到全局变量中
   *
   * @memberof UserStore
   */
  @action
  checkShared = () => {
    let params = queryString.parse(history.location.search, {
      ignoreQueryPrefix: true
    });
    if (params && params.puid) {
      if (params.shared === 'true') {
        global.puid = params.puid;
        return true;
      }
    }

    return false;
  };

  /**
   * 由于用户中心登录现采用iframe 登录，矿池这边只用关系post-message后cookie即可
   * 用户中心登录后set user-jwt-token cookie 15天
   * 矿池前端需要每3小时获取新的user-jwt-token
   * 然后设置axios header
   * @memberof UserStore
   */
  @action
  login = callback => {
    const inThreeHours = new Date(new Date().getTime() + 3 * 60 * 60 * 1000);
    Cookies.set('pool-jwt-expire', inThreeHours.getTime(), {
      expires: inThreeHours
    });
    runInAction(() => {
      this.isAuthenticated = true;
    });
    this.getAccount(callback);
  };

  // 清除缓存
  @action
  cleanCache = () => {
    this.initStore();
    localStorage.removeItem('puid');
    localStorage.removeItem('accountInfo');
    Cookies.remove(process.env.BTC_POOL_USER_JWT_TOKEN, {
      path: '',
      domain: '.btc.com'
    });
    Cookies.remove(process.env.BTC_POOL_USER_REFRESH_TOKEN, {
      path: '',
      domain: '.btc.com'
    });
    Cookies.remove('user-jwt-expire');
    Cookies.remove('pool-jwt-expire');
  };
  // 登出
  @action
  logout = () => {
    this.cleanCache();
    // axios.get(`auth/api/logout/do`, {
    //   baseURL: process.env.BTC_POOL_USER_CENTER
    // });
    // TODO: 由于👆cors问题没解决，临时采用iframe方式
    (function() {
      var i = document.createElement('iframe');
      i.style.display = 'none';
      i.onload = function() {
        i.parentNode.removeChild(i);
      };
      i.src = process.env.BTC_POOL_USER_CENTER + '/auth/logout';
      document.body.appendChild(i);
    })();

    this.jumpToLogin();
    ReactGA.event(
      {
        category: 'Account',
        action: 'SignOut',
        label: 'pool.btc.com'
      },
      ['globalGA']
    );
    global._hmt &&
      global._hmt.push(['_trackEvent', 'Account', 'SignOut', 'pool.btc.com']);
  };

  // 跳转到登录页面
  jumpToLogin = () => {
    const loginPath = '/';
    if (history.location.pathname !== loginPath) {
      history.push(loginPath);
    }
    runInAction(() => {
      this.isAuthenticated = false;
    });
  };

  // 重新请求新的jwttoken
  requestJWTToken = async (isForce = false) => {
    let refreshToken = Cookies.get(process.env.BTC_POOL_USER_REFRESH_TOKEN);
    const jwtToken = Cookies.get(process.env.BTC_POOL_USER_JWT_TOKEN);
    let jwtExpireTime = Cookies.get('pool-jwt-expire');
    let jwtTokenExpire = Cookies.get('user-jwt-expire');

    if (refreshToken) {
      if (isForce || !jwtExpireTime || !jwtToken) {
        // TODO: 请求用户中心接口拿到新jwtToken
        try {
          runInAction(() => {
            this.isRefreshingNewToken = true;
          });
          const response = await axios.post(
            `/cas/jwt/refresh-user-token`,
            qs.stringify({
              'user-refresh-token': refreshToken
            }),
            {
              baseURL: process.env.BTC_POOL_USER_CENTER,
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              }
            }
          );
          if (response && response.data) {
            if (response.data.err_no > 0) {
              if (response.data.err_msg) {
                throw new Error(response.data.err_msg);
              }
            }
            let expires = 15;
            if (jwtTokenExpire) {
              expires = new Date(jwtTokenExpire);
            }
            const data = response.data.data;
            if (data && data.jwt_token) {
              // user-jwt-token
              Cookies.set(process.env.BTC_POOL_USER_JWT_TOKEN, data.jwt_token, {
                expires: expires,
                domain: '.btc.com'
              });
              // user-refresh-token user center 用
              Cookies.set(
                process.env.BTC_POOL_USER_REFRESH_TOKEN,
                data['refresh-token'],
                {
                  expires: expires,
                  domain: '.btc.com'
                }
              );
              const jwtTokenExpire = Cookies.get('user-jwt-expire');
              if (!jwtTokenExpire) {
                const expires = new Date(
                  new Date().getTime() + 15 * 24 * 60 * 60 * 1000
                );
                Cookies.set('user-jwt-expire', expires.getTime(), {
                  expires: expires
                });
              }
            }
            this.login();
            if (history.location.state && history.location.state.from) {
              const location = history.location.state.from;
              if (location) {
                if (
                  location.pathname &&
                  location.search &&
                  location.pathname === '/guardian/bind'
                ) {
                  history.push({
                    pathname: location.pathname,
                    search: location.search
                  });
                }
              }
            }
            this.isRefreshingNewToken = false;
          }
        } catch (error) {
          console.log('error', error);
          this.logout();
        }
      }
    } else {
      this.logout();
    }
  };

  // 获取账户信息
  @action
  getAccount = async callback => {
    if (!this.isAuthenticated) {
      return;
    }

    // // accountInfo 缓存没有或者删除后，重新获取
    // if (this.isUserReadOnly || !account.uid) {
    //   await this.getSubAccountInfo();
    // }
    // // 有子账户执行，没有不执行
    await this.getSubAccountInfo(callback);
    await this.getAccountInfo();
  };

  // 账户信息， 首先必须创建子账户
  // 只在设置的地方调用
  @action
  getAccountInfo = async successCallback => {
    if (
      !this.isAuthenticated ||
      (!this.accountInfo.puid || this.accountInfo.puid === 0)
    ) {
      return;
    }
    const response = await axios.get(`account/info`);
    if (response && response.data) {
      runInAction(() => {
        this.accountInfo = {
          ...this.accountInfo,
          ...response.data
        };
        if (!this.isUserReadOnly) {
          localStorage.setItem('accountInfo', JSON.stringify(this.accountInfo));
        }

        successCallback && successCallback();
      });
    }
  };

  /**
   * 获取子账户信息
   * 该方法可以不传puid, 所以登录后得通过这个获取puid
   * callback: 用处1. 注册、登陆后没有子账户的，跳转创建子账户页面
   * TODO: 最好合并到accout/info接口中
   * @memberof UserStore
   */
  @action
  getSubAccountInfo = async callback => {
    if (!this.isAuthenticated) {
      return;
    }
    const response = await axios.get(`account/sub-account/info`);
    if (response && response.data) {
      runInAction(() => {
        this.accountInfo = {
          ...this.accountInfo,
          ...response.data
        };
        if (this.isUserReadOnly) {
          global.puid = this.accountInfo.puid;
        } else {
          localStorage.setItem('accountInfo', JSON.stringify(this.accountInfo));
          if (this.accountInfo.puid) {
            localStorage.setItem('puid', this.accountInfo.puid);
          }
        }
        if (callback) {
          callback(response.data);
        }
      });
    }
  };

  // 切换子账户
  @action
  changeAccount = (account, isJump = true) => {
    if (account.puid) {
      if (!this.isUserReadOnly) {
        localStorage.setItem('puid', parseInt(account.puid, 10));
      }

      runInAction(() => {
        if (account.coin_type && account.name) {
          this.accountInfo = {
            ...this.accountInfo,
            ...account,
            region: account.region_text
          };
          if (!this.isUserReadOnly) {
            localStorage.setItem(
              'accountInfo',
              JSON.stringify(this.accountInfo)
            );
          }
        } else {
          this.accountInfo.uid = 0;
        }
        this.getAccount();
        if (isJump) {
          history.push('/dashboard');
        }
      });
    }
  };

  // 切换所以账号是否显示
  toggleAccountAllOpen = showAll => {
    localStorage.setItem('account_show', showAll ? '1' : '0');
    runInAction(() => {
      this.accountAllOpen = showAll;
    });
  };

  // 切换账号后，重新加载数据
  // TODO: 目前暴力刷新，应该可以更好的实现
  @action
  reloadAccount = () => {
    this.initAccount();
    this.getAccount();
  };

  //隐藏账户
  @action
  hideAccount = async hidden_puid => {
    return axios.get(`account/hidden/set`, {
      params: {
        hidden_puid,
        puid: hidden_puid
      }
    });
  };

  @action
  recoverAccount = async cancle_hidden_puid => {
    return axios.get(`account/hidden/cancle`, {
      params: {
        cancle_hidden_puid
      }
    });
  };

  // 子账户列表
  @action
  getSubAccountList = async (isHidden = false) => {
    if (isHidden) {
      this.hiddenAlgorithmAccounts = {
        ...this.hiddenAlgorithmAccounts,
        loading: true
      };
    } else {
      this.algorithmAccounts = { ...this.algorithmAccounts, loading: true };
    }
    const response = await axios.get(
      `account/sub-account/algorithms/morelist`,
      {
        params: {
          is_hidden: isHidden ? 1 : 0
        }
      }
    );
    if (response && response.data) {
      runInAction(async () => {
        this.accountInfo.account.name = response.data.account;
        this.accountInfo.account.have_default = response.data.have_default;
        if (isHidden) {
          this.hiddenAlgorithmAccounts = {
            ...this.hiddenAlgorithmAccounts,
            data: response.data.subaccounts,
            loading: false
          };
          if (this.hiddenAlgorithmAccounts.firstTimeLoading) {
            this.hiddenAlgorithmAccounts.firstTimeLoading = false;
          }
        } else {
          this.algorithmAccounts = {
            ...this.algorithmAccounts,
            data: response.data.subaccounts,
            loading: false
          };
          if (this.algorithmAccounts.firstTimeLoading) {
            this.algorithmAccounts.firstTimeLoading = false;
          }
        }

        await this.getHashrateMiners(
          isHidden ? this.hiddenSubAccounts : this.subAccounts,
          res => {
            if (res && res.data) {
              this.hashrateMiners = { ...this.hashrateMiners, ...res.data };
              const hashrate = this.hashrateMiners[this.accountInfo.puid];

              // sensor profile
              if (hashrate) {
                const has_hashrate = hashrate.shares_1d_pure > 0;
                let oncehas_hashrate = false;
                if (!has_hashrate) {
                  oncehas_hashrate = hashrate.workers_dead > 0;
                }
                sensors.setProfile({
                  account: this.accountInfo.account.name,
                  sub_account: this.accountInfo.name,
                  email: this.accountInfo.user_info.mail,
                  phone_number: this.accountInfo.user_info.phone.number,
                  has_hashrate,
                  oncehas_hashrate,
                  hashrate_now: hashrate.shares_1d_pure,
                  IP_source: '',
                  is_VIP: false
                });
              }
            }
          }
        );
        // if (hashrateArr) {
        //   this.loadingAlgorithmAccounts = false;
        // }
      });
    }
  };

  // 通过获取的子账户列表，请求算力信息
  // 目前子账户管理、高级子账户管理、子账户下拉列表均需要
  // TODO: 合并接口后，应该删除
  getHashrateMiners = async (subAccounts, callback) => {
    // 按照币种分组，分组后结果为{ '1': [...], '2': [...] }
    const regionGroups = groupBy(subAccounts, account => {
      return account.region_id;
    });

    Object.keys(regionGroups).forEach(async regionId => {
      const puids = regionGroups[regionId].map(account => {
        return account.puid;
      });
      var data = await this.getHashrateMinersByRegion(puids, regionId);
      if (callback) {
        callback(data);
      }
    });
  };

  // 获取子账户算力信息
  // TODO: 合并接口后，应该删除
  @action
  getHashrateMinersByRegion = (puids, regionId) => {
    return new Promise(async (resolve, reject) => {
      const response = await axios.get(`account/sub-account/hashrate-miners`, {
        params: {
          puids: puids.join(','),
          puid: '',
          region_id: regionId
        }
      });
      resolve(response);
    });
  };

  // 获取高级子账户详细列表
  @action
  getDetailSubAccountList = async (is_guardian = 1) => {
    this.advanceAccount = { ...this.advanceAccount, loading: true };
    const response = await axios.get(`account/sub-account/morelist`, {
      params: {
        is_guardian
      }
    });
    runInAction(async () => {
      if (response.data) {
        const data = response.data;

        const { display, hidden } = data;
        // 只显示当前正在挖的币种
        // filter hidden guard
        let allAccounts = [...display, ...hidden].filter(account => {
          return (
            account.is_current === 1 && !(account.is_guard && account.is_hidden)
          );
        }); // 合并显示、隐藏账户列表
        this.advanceAccount = { loading: false, data: allAccounts };

        await this.getHashrateMiners(allAccounts, res => {
          if (res && res.data) {
            this.hashrateMiners = { ...this.hashrateMiners, ...res.data };
          }
        });
      }
      if (response.errorMsg) {
        this.advanceAccount = {
          ...this.advanceAccount,
          loading: false,
          errorMsg: response.errorMsg
        };
      }
    });
  };

  // 修改联合挖矿地址
  @action
  updateMergedMiningAddress = async (coin, address) => {
    return axios.post(`/account/merged-mining/${coin}/address/update`, {
      new_address: address
    });
  };

  @action
  watcherExpired = () => {
    const location = history.location;
    history.push({
      pathname: '/watcher/expired',
      search: location.search
    });
  };

  @action
  watcherIncorrect = () => {
    const location = history.location;
    history.push({
      pathname: '/watcher/incorrect',
      search: location.search
    });
  };
}

const userStore = new UserStore();
userStore.initAccount();
export default userStore;
