import React from 'react';
import { Skeleton } from 'antd';
import './index.scss';
const BtcCardFormItem = props => {
  const {
    label,
    value,
    suffix,
    isLargeText = false,
    align = 'right',
    isHtml = false
  } = props;
  return (
    <dl className={`card-form-item ${isLargeText ? 'larget-text' : ''}`}>
      <dt className="secondary-text">{label}</dt>
      <dd style={{ textAlign: align }}>
        {!isHtml ? value : <div dangerouslySetInnerHTML={{ __html: value }} />}

        {suffix && <span className="secondary-text suffix">{suffix}</span>}
      </dd>
    </dl>
  );
};
export { BtcCardFormItem };

const BtcCard = props => {
  const {
    style,
    ghost = false,
    className,
    title,
    size = 'md', //others:sm
    showHeader = true,
    extra,
    children,
    cardBodyStyle,
    loading
  } = props;
  return (
    <div
      className={`btc-card btc-card-${size} ${
        ghost ? 'btc-card-ghost' : ''
      } ${className}`}
      style={{ width: '100%', height: 'auto', ...style }}
    >
      {showHeader && (
        <div className="btc-card-header">
          <div className="btc-card-title">{title}</div>
          {extra && <div className="btc-card-extra link">{extra}</div>}
        </div>
      )}
      <div className="btc-card-body" style={cardBodyStyle}>
        <Skeleton loading={loading ? loading : false} active>
          {children}
        </Skeleton>
      </div>
    </div>
  );
};
export { BtcCard };

export default BtcCard;
