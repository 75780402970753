import React, { Component } from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import { reaction } from 'mobx';
import routes from './pages';
import Layout from '@/components/Layout';
import NotFound from './pages/Warning/NotFound';
import { ConfigProvider } from 'antd';
import MetaTags from 'react-meta-tags';
import zhCN from 'antd/lib/locale-provider/zh_CN';
import enUS from 'antd/lib/locale-provider/en_US';
import * as Sentry from '@sentry/browser';
import './styles/main.scss';
import imported from 'react-imported-component';
import withTracker from '@/components/Tracker';
import { I18N_LANG } from '@/utils/constants.js';
import history from '@/utils/history';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: process.env.BTC_POOL_SENTRY_DSN
  });
}
function PrivateRoute({
  component: Component,
  userStore,
  isDependSubAccount,
  isUserReadOnly,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={props => {
        if (isUserReadOnly) {
          if (rest.rejectWatcher) {
            return (
              <Redirect
                key={Math.random()}
                to={{
                  pathname: '/dashboard',
                  search: props.location.search,
                  state: { from: props.location }
                }}
              />
            );
          } else {
            return <Component {...props} />;
          }
        }
        return userStore.isAuthenticated ? (
          isDependSubAccount && !userStore.hasSubAccount ? (
            <Redirect
              to={{
                pathname: '/sub-account'
              }}
            />
          ) : (
            <Component {...props} />
          )
        ) : (
          <Redirect
            to={{
              pathname: '/',
              hash: '#login',
              state: { from: props.location }
            }}
          />
        );
      }}
    />
  );
}

@withRouter
@inject('store')
class App extends Component {
  constructor(props) {
    super(props);
    this.store = this.props.store.appStore;
    this.userStore = this.props.store.userStore;
    this.currentPuid = 0;
    // this.state = { error: null };
    reaction(
      () => this.userStore.accountInfo.puid,
      puid => {
        if (this.currentPuid.toString() !== puid.toString()) {
          this.currentPuid = puid;
          if (this._mounted) {
            this.forceUpdate();
          }
        }
      }
    );
  }

  // static getDerivedStateFromError(error) {
  //   // Update state so the next render will show the fallback UI.
  //   return { error: true };
  // }

  componentDidCatch(error, errorInfo) {
    if (error && error.err_no) {
      return;
    }
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  getAccountInfo() {
    const location = history.location;
    const pathname = location.pathname.toLowerCase();
    let filterPath = routes.filter(item =>
      item.path.startsWith(pathname.replace('/zh-cn', '').replace('/en-us', ''))
    );

    if (filterPath && filterPath.length > 0) {
      this.userStore.getAccount(() => {
        this.currentPuid = this.userStore.accountInfo.puid;
      });
      this._mounted = true;
      this.localeRouterJump();
    }
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  componentDidMount() {
    const location = history.location;

    if (location.search.indexOf('access_key') < 0) {
      history.replace('/');
      return;
    }
    this.getAccountInfo();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      if (this.props.location.search.indexOf('access_key') < 0) {
        history.replace('/');
        return;
      }
    }
    this.getAccountInfo();
  }

  // 根据url更新本地语言
  localeRouterJump() {
    const location = history.location;
    const pathname = location.pathname.toLowerCase();
    const localLang = localStorage.lang;
    Object.values(I18N_LANG).forEach(lang => {
      if (pathname.startsWith(`/${lang.toLowerCase()}`)) {
        if (localLang !== lang) {
          this.store.setLocaleLang(lang);
          return;
        }
      }
    });
  }

  // 区分需要登录的route
  innerRoutes(routes, lang = '') {
    const { accountInfo, isUserReadOnly } = this.userStore;

    return routes.map((route, index) => {
      const path = `${lang ? '/' : ''}${lang}${route.path}`;

      route = {
        ...route,
        path,
        component: withTracker(imported(route.component), {
          /* additional attributes */
        })
      };
      if (route.requiresAuth) {
        return (
          <PrivateRoute
            userStore={this.userStore}
            key={Math.random()}
            puid={accountInfo.puid}
            isUserReadOnly={isUserReadOnly}
            {...route}
          />
        );
      } else {
        return <Route key={Math.random()} puid={accountInfo.puid} {...route} />;
      }
    });
  }

  render() {
    const { lang } = this.store;
    return (
      <ConfigProvider locale={lang === I18N_LANG.zh ? zhCN : enUS}>
        <Layout>
          <MetaTags>
            {/* <!-- HTML Meta Tags --> */}
            <meta
              name="description"
              content="BTC.com pool is a whole new choice for bitcoin miners. BTC.com pool is with much more stable architecture, much better user experience, much lower fees and much stronger service."
            />

            {/* <!-- Google / Search Engine Tags --> */}
            <meta
              itemProp="name"
              content="BTC.com Pool, a better bitcoin mining pool"
            />

            <meta itemProp="image" content="" />

            {/* <!-- Facebook Meta Tags --> */}
            <meta property="og:url" content="https://pool.btc.com" />
            <meta property="og:type" content="website" />
            <meta
              property="og:title"
              content="BTC.com Pool, a better bitcoin mining pool"
            />
            <meta
              property="og:description"
              content="BTC.com pool is a whole new choice for bitcoin miners. BTC.com pool is with much more stable architecture, much better user experience, much lower fees and much stronger service."
            />
            <meta property="og:image" content="" />

            {/* <!-- Twitter Meta Tags --> */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta
              name="twitter:title"
              content="BTC.com Pool, a better bitcoin mining pool"
            />
            <meta
              name="twitter:description"
              content="BTC.com pool is a whole new choice for bitcoin miners. BTC.com pool is with much more stable architecture, much better user experience, much lower fees and much stronger service."
            />
            <meta name="twitter:image" content="" />
          </MetaTags>
          <Switch>
            {this.innerRoutes(routes)}
            {Object.values(I18N_LANG).map(lang => {
              return this.innerRoutes(routes, lang);
            })}
            <Route key="404" component={NotFound} />
          </Switch>
        </Layout>
      </ConfigProvider>
    );
  }
}

export default App;
