import React from 'react';
import QRCode from 'qrcode.react';
import { Popover } from 'antd';
/**
 * 二维码Popup ,传入值生成二维码弹出框
 * @param {*} props
 */
const QRPop = ({ value, title, children, containerId, size = 120 }) => {
  return (
    <div
      className="qr-pop"
      style={{ display: 'inline-block', cursor: 'pointer' }}
    >
      <Popover
        content={<QRCode value={value} size={size} />}
        title={title}
        getPopupContainer={() => document.getElementById(containerId)}
      >
        {/* <i className="cell-icon qr-thumbnail-icon" /> */}
        {children}
      </Popover>
    </div>
  );
};
export default QRPop;
