import React from 'react';
const rateLabel = {
  display: 'inline-block'
};
/**
 * 变化率文本标签 红绿颜色和+-符号来指示变化率
 * @param {*} props
 */
const RateLabel = ({ changeRate, style }) => {
  if (!changeRate) {
    return null;
  }
  let cls = changeRate === 0 ? '' : changeRate > 0 ? 'increase' : 'decline';
  let rate = changeRate === 0 ? 0 : Math.abs(changeRate).toFixed(2) + '%';
  return (
    <span style={rateLabel}>
      <span className={`${cls}-text`}>
        {cls === 'increase' ? '+' : '-'}
        {rate}
      </span>
    </span>
  );
};
export default RateLabel;
