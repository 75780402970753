import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Alert } from 'antd';
import Ts from 'localeTranslator';
import { I18N_LANG } from '@/utils/constants';
@inject('store')
@observer
class HelpInfo extends Component {
  constructor(props) {
    super(props);
    this.appStore = this.props.store.appStore;
    this.userStore = this.props.store.userStore;
    this.state.visible = localStorage.getItem('user-help-tip') !== 'hide';
  }

  state = {
    visible: true
  };

  onCloseAlert = () => {
    localStorage.setItem('user-help-tip', 'hide');
  };

  render() {
    const { accountInfo } = this.userStore;
    const { lang } = this.appStore;
    return (
      this.state.visible && (
        <div className="user-content__help-tip">
          <Alert
            message={
              <Ts
                id="pages.account.locationRegion"
                type="html"
                values={{
                  coin: `${accountInfo.coin_type}`,
                  region: `${
                    accountInfo.region_conf.text[
                      lang === I18N_LANG.zh ? 'zh-cn' : 'en'
                    ]
                  }`
                }}
              />
            }
            description={<Ts id="pages.account.locationEx" />}
            type="white"
            closeText={<Ts id="common.gotIt" />}
            onClose={this.onCloseAlert}
          />
        </div>
      )
    );
  }
}

export default HelpInfo;
