import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { GRIN_DEFAULT_ALGORITHM } from 'constants';
import { Button } from 'antd';
import debounce from 'lodash.debounce';
import './index.scss';

const ButtonGroup = Button.Group;

const grinAlgorithms = [
  { name: 'Grin-c29', value: '29' },
  { name: 'Grin-c31', value: '31' }
];

@withRouter
@inject('store')
@observer
class GrinSwitch extends Component {
  constructor(props) {
    super(props);
    this.appStore = this.props.store.appStore;
    this.userStore = this.props.store.userStore;
    this.settingsStore = this.props.store.settingsStore;

    this.state = {
      grin_algorithm: localStorage.getItem('grin_algorithm')
        ? localStorage.getItem('grin_algorithm')
        : GRIN_DEFAULT_ALGORITHM
    };

    this.handleChangeGrin = debounce(this.handleChangeGrin, 800);
  }

  handleChangeGrin = grin_algorithm => {
    this.setState({
      grin_algorithm
    });
    localStorage.setItem('grin_algorithm', grin_algorithm);
    setTimeout(() => {
      if (this.isLocateDashboard()) {
        window.location.reload();
      } else {
        this.props.history.push('/dashboard');
      }
    }, 800);
  };

  // 当前在dashboard页面
  isLocateDashboard = () => {
    try {
      return this.props.location.pathname.indexOf('/dashboard') >= 0;
    } catch (error) {
      return false;
    }
  };

  render() {
    const { grin_algorithm } = this.state;
    return (
      <div className="grin-switch-container">
        <ButtonGroup>
          {grinAlgorithms.map(g => (
            <Button
              key={g.value}
              onClick={() => {
                this.handleChangeGrin(g.value);
              }}
              className={`${grin_algorithm === g.value ? 'on' : ''}`}
            >
              {g.name}
            </Button>
          ))}
        </ButtonGroup>
      </div>
    );
  }
}

export default GrinSwitch;
