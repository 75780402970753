import flatten from 'flat';
const pageMessage = {
  pages: {
    playground: {
      goto: '去往 dashboard'
    },
    dashboard: {
      EarningsM: '{coinType} 每M收益',
      accountEarning: '账户收益',
      active: '活跃',
      activeMiner: '活跃矿机',
      amoutPaid: '已支付',
      announcement: '通知公告',
      cannotConnect: '无法连接?',
      urlConfigGpu: ' 支持普通显卡矿机',
      urlConfigNicehash: ' 为Nicehash特别优化',
      urlConfigGpuDcr: ' 已知支持蚂蚁矿机DR3，Claymore，gominer',
      urlConfigNicehashDcr: ' 已知支持Nicehash，芯动科技等矿机',
      customerTable:
        '<dl>\n                                <dt><a href="https://bitmain.kf5.com/" target="_blank">工单系统</a></dt>\n                                <dd> 与在线客服一对一交谈</dd>\n                            </dl>\n                            <dl>\n                                <dt><a href=":feedback_link" target="_blank">吐槽</a></dt>\n                                <dd>给开发人员留言</dd>\n                            </dl>\n                            <dl>\n                                <dt>QQ群</dt>\n                                <dd>691890335</dd>\n                            </dl>\n                            <dl>\n                                <dt>客服电话</dt>\n                                <dd>400-890-8855 语音提示后选1再选3</dd>\n                            </dl>\n                            <dl>\n                                <dt>VIP服务专员</dt>\n                                <dd>请拨打客服电话，提供用户名或算力证明后让客服转接</dd>\n                            </dl>\n                            <hr/>\n                            <div>* BTC.com 矿池向大算力用户提供独立服务器和私密IP地址，可100%抵御Ddos攻击。</div>',
      customerTitle: '售后服务',
      dailyHashrate: '日算力',
      dashboard: '用户面板',
      earnMore: 'BTC.com矿池比别池多赚多少',
      earning: '{coinType} 收益',
      eventConnected: '已连接',
      eventDisconnected: '断开连接',
      eventMiner: '矿机',
      eventName: '条消息',
      eventSkip: '已跳过',
      eventTitle: '事件通知',
      eventlatestLogs: '以下为历史消息',
      hastrateChart: '算力图表',
      history: '支付记录',
      hour: '{n} 小时',
      hours: '小时',
      inActive: '非活跃',
      lastPaymentTime: '上一次支付时间',
      markAllRead: '全部标记为已读',
      minerContent:
        '进入挖矿软件配置界面，填入您的子账户（密码建议留空）和挖矿地址，保存后运行exe程序即可，矿机将在一分钟内自动添加到矿池网站页面。',
      minerNameStand:
        '矿机名规则为：子账户+英文句号+编号，例如，您的子账户是{sub_account}, 可以设置矿机名为{sub_account}.001、{sub_account}.002，以此类推，一个矿机名对应一台矿机。',
      minerName: '矿机名设置参考："{sub_account}.001", "{sub_account}.002".',
      miner: '矿机',
      miners: '矿机数',
      miningAddress: '挖矿地址',
      miningEarnings: '{coinType} 算力收益',
      minute: '分钟',
      more: '更多',
      networkDiff: '当前难度',
      networkHashrate: '全网算力',
      networkStatus: '网络状态',
      nextDifficult: '预测下次难度',
      noMiner: '您需要添加矿机到矿池，然后开始挖矿。推荐使用BTC.com 矿池',
      btcTools: '代理软件。',
      noEvent: '暂无事件通知!',
      nowHashrate: '当前算力',
      pendingPayouts: '待确认支付',
      poolHashrate: '矿池算力',
      realHashrate: '{coinType} 实时算力',
      realtime: '实时',
      timeRemain: '距离调整还剩',
      title: '用户面板 - BTC.com Pool',
      today: '今日预估',
      unpaid: '余额',
      yesterday: '昨日实收',
      yesterdayEarnTip:
        '昨日收益为国际标准时间昨日0:00-24:00（北京时间昨日8:00到今日8:00）之间的收益。',
      charts: {
        hashrate: '算力',
        minerCharts: '矿机算例曲线',
        myCharts: '我的算力曲线',
        poolCharts: '矿池算力曲线',
        reject: '拒绝率',
        time: '时间'
      },
      yesterday_earn: '昨日收益',
      yesterday_hashhrate: '昨日有效算力(不含拒绝)',
      earnings_per_100T: '折合每100T收益',
      notShowAddress: '云算力平台的子账号不展示挖矿地址',
      //职责声明
      statement:
        '* 您不得通过我们提供或建议的方式以外的任何方式使用（或尝试使用）此服务，并且您明确保证不会通过任何违法或不合法方式使用（或尝试使用）此服务，或为支持任何违法或不合法活动的目的使用此服务。 进入本网站并注册此服务即表示您承诺并保证您是根据您所在司法辖区法律可以合法获得此项服务的人士。'
    },
    subAccount: {
      title: '创建子账户 - BTC.com Pool',
      createWorker: '创建子账户',
      noNode: '您还未选择节点!',
      noCoinType: '您还未选择币种!',
      selectRegion: '选择地区',
      setWorker: '设置子账户名称',
      enterWorker: '请输入子账户名称',
      enterAddress: '请输入收款地址',
      coinTypeEx:
        'BTC.com现已支持 {supportCoins} 挖矿，更多币种即将推出，敬请期待。',
      regionEx: 'BTC.com在全球都部署服务器，选择最近的服务器能获得最好的体验。',
      workerEx:
        '您设置的子账户将绑定于所选地区。您最多可设置2000个子账户。这一子账户名称加上矿机编号即为矿机设置页的矿机名（worker name），如: "{name}.001"，"{name}.002"。',
      workerCaution:
        '* 请注意，同一子账户不支持跨地区挖矿，您创建于当前地区的子账户在其他区域不存在。如果您在其他地区拥有矿机，请重新创建对应地区的子账户。',
      setAddress: '填写提币地址',
      save: '创建并挖矿',
      verifyWorker: '子账户仅支持由3-20位字母或数字组成',
      option: '选填',
      'optional-BTC':
        '注：比特币现金（BCH）旧格式地址与比特币（BTC）地址相似，请谨慎填写，以免造成丢币损失。',
      'optional-BCH':
        '注：比特币现金（BCH）旧格式地址与比特币（BTC）地址相似，请谨慎填写，以免造成丢币损失。推荐使用新格式地址',
      'optional-UBTC':
        '注：比特币现金（BCH）旧格式地址与比特联储（UBTC）地址相似，请谨慎填写，以免造成丢币损失。',
      'optional-SBTC':
        '注：比特币现金（BCH）旧格式地址与超级比特币（SBTC）地址相似，请谨慎填写，以免造成丢币损失。',
      'optional-coin': '注：请谨慎填写 { coin_type } 地址，以免造成丢币损失。',
      selectCoinType: '选择币种',
      automatic: '自动生成',
      recommandWallet: '推荐使用BTC.com钱包',
      withdrawToExchange: '提现至交易所',
      withdrawToWallet: '提现至钱包'
    },
    tools: {
      // title: '工具 - BTC.com Pool',
      // tools: '工具',
      // fppsPps: 'FPPS与传统PPS动态对比工具',
      // aboutFpps: 'FPPS比传统PPS好在哪？',
      // charts: '动态数据 一目了然',
      // watchCharts: '点击对比',
      // miniCalc: '挖矿计算器',
      // miniCalcExpress: '结合难度与币价，通过算力、功耗、电费计算挖矿利润',
      // miniMiniCalc: '迷你挖矿计算器',
      // miniMiniCalcExpress: '快速计算挖矿利润，仅考虑算力、难度与币价',
      // strat: '开始',
      // btcTool: 'BTC.com 矿池批量工具',
      // btcToolIntroduce: '批量处理矿机后台配置，方便 快捷',
      // download: '点击下载'
      btcSmartAgent: 'BTC 智能代理',
      agentExpress: '高效透明 <br/> 单台矿机可见',
      agentExpressBanner:
        '高效透明 <i style="margin-right:20px"></i> 单台矿机可见',
      bit: 'bit',
      document: 'windows 版帮助文档',
      poolApp: 'BTC.com 矿池App  全功能App',
      poolRealTime: '实时查看矿池、矿机数据',
      poolOpeation: '支持矿机删除、修改等操作',
      poolComming: '已上线推送报警功能',
      poolDownlaod: '点击下载',
      title: '工具 - BTC.com Pool',
      tools: '工具',
      fppsPps: 'FPPS与传统PPS动态对比工具',
      aboutFpps: 'FPPS比传统PPS好在哪？',
      charts: '动态数据 一目了然',
      watchCharts: '点击对比',
      miniCalc: '挖矿计算器',
      miniCalcExpress: '结合难度与币价，通过算力、功耗、电费计算挖矿利润',
      miniMiniCalc: '迷你挖矿计算器',
      miniMiniCalcExpress: '快速计算挖矿利润，仅考虑算力、难度与币价',
      start: '开始',
      btcTool: 'BTC.com 矿池批量工具',
      btcToolIntroduce: '批量处理矿机后台配置，方便 快捷',
      download: '点击下载'
    },
    miners: {
      createGroup: '创建新分组',
      deleteGroupTitle: '删除分组',
      editGroupTitle: '编辑分组',
      all: '全部',
      default: '未分组',
      addGroup: '创建新分组',
      addGroupInfo: '注意：选中的矿机将自动添加到新分组中',
      groupNameLabel: '分组名',
      addGroupEmpty: '组名称不能为空',
      groupRejected: '实时拒绝率', // Real time rejection rate
      groupNameLimit: '分组名必须在 3 到 20 个字之间',
      inactive: '不活跃',
      active: '活跃',
      dead: '失效',
      moveTo: '移动到',
      remove: '删除',

      allTooltip: '包含：活跃矿机 ({active}) + 不活跃矿机（{inactive}）',
      inactiveTooltip: '超过10分钟未提交计算结果（Share）的矿机数量。',
      activeTooltip: '正常提交计算结果（Share）的矿机数量',
      deadTooltip: '超过24小时未提交计算结果（Share）的矿机数量',

      home: '首页',
      miners: '矿机',

      workerName: '矿机名',
      Hashrate: '实时算力',
      Rejected: '实时拒绝率',
      dailyHashrate: '日算力',
      firstShare: '首次连接时间',
      LastShare: '最近提交时间',
      groupName: '分组名',

      delMillTitle: '确认要删除这些矿机信息吗？',
      delMillContext:
        '该操作会删除您选中的所有矿机，但活跃矿机不受影响，即无法删除活跃矿机。',
      delMillFailed: '删除失败',

      delGroupTitle: '你确定删除 “{name}” 分组吗？',
      delGroupContext: '分组内矿机将全部移动到未分组中',

      moveTitle: '移动矿机',
      notChecked: '请勾选矿机,支持批量操作',
      moveContext: '该操作会移动所有选中矿机到 {groupName} 组',
      moveFaild: '不能移动到当前分组',

      searchPlaceholder: '输入矿机名',
      reportTime: '此报告生成时间',
      refresh: '刷新',
      editGroupName: '修改分组名称',
      workListEmpty: '该分组未检测到矿机',
      exist: '该分组已存在',
      goOldVersion: '返回旧版'
    },
    minerInfo: {
      miners: '矿机',
      hashrate: '单机算力',
      runningCondition: '运行状况',
      min: '分钟',
      hour: '{n}小时',
      hashrateAxis: '算力',
      rejected: '拒绝率',
      status: '状态',
      lastShare: '最近提交时间',
      lastShareIP: '最近提交IP',
      hashrateChart: '算力图表',
      realTime: '实时',
      trans: {
        seconds: '秒',
        mins: '分钟',
        hours: '小时',
        ago: '前',
        ACTIVE: '活跃',
        INACTIVE: '不活跃',
        DEAD: '失效'
      }
    },
    earningHistory: {
      title: '收益历史 - BTC.com Pool',
      earn: '收益记录',
      paid: '已支付',
      Unpaid: '余额',
      EarningsToday: '今日预估',
      EarningsYesterday: '昨日收益',
      Time: '挖矿日期',
      NetworkDifficulty: '全网难度',
      SettleHashrate: '结算算力',
      Earnings: '收益',
      Payment: '支付时间',
      payWithOTCAmount: '以 {otcType} 结算收益: {amount}',
      Mode: '模式',
      Address: '地址',
      PaidAmount: '收益数额',
      yesterday:
        '昨日收益为国际标准时间昨日0:00-24:00（北京时间昨日8:00到今日8:00）之间的收益。',
      unpaidReason: {
        PENDING_NON_ADDRESS: '未设定收款地址，将存入余额。',
        PENDING_CHANGED_ADDRESS:
          '地址改动后将在48小时后开始支付。警告：如果地址并非由你本人改动，你的账号（邮箱/手机）可能已被攻破。请马上使用其他邮箱或手机号注册新账户，将矿机切换到新账户。',
        PENDING_NOT_ENOUGH:
          '金额小于您设置的起付金额（默认 :limit ），将存入余额。',
        PENDING_POOL_SETTLE_FAULT:
          '因矿池原因，导致这部分金额误发，后续将不再发放。'
      },
      depositeAmount: '存入余额',
      diffChg: '难度变化: ',
      equalsPps: '折合PPS',
      Chg: '日变动',
      status: '支付状态',
      paid: '已支付',
      waitingPay: '待支付',
      reason: '原因',
      fppsMoreEarn: 'FPPS额外收益:',
      fppsContain: 'FPPS额外收益 {pps96} 已包含在收益 {paid_amount } 中。',
      noEarningsInformation: '暂无收益信息'
    },
    transactionHistory: {
      title: '收款记录 - BTC.com Pool',
      earn: '收款记录',
      paid: '已支付',
      Unpaid: '余额',
      EarningsToday: '今日预估',
      EarningsYesterday: '昨日收益',
      Time: '挖矿周期',
      NetworkDifficulty: '全网难度',
      Earnings: '收益',
      Payment: '支付时间',
      payWithOTCAmount: '以 {otcType} 结算收益: {amount}',
      Mode: '模式',
      Address: '地址',
      PaidAmount: '收款金额',
      Transactions: '交易哈希',
      yesterday:
        '昨日收益为国际标准时间昨日0:00-24:00（北京时间昨日8:00到今日8:00）之间的收益。',
      unpaidReason: {
        PENDING_NON_ADDRESS: '未设定收款地址，将存入余额。',
        PENDING_CHANGED_ADDRESS:
          '地址改动后将在48小时后开始支付。警告：如果地址并非由你本人改动，你的账号（邮箱/手机）可能已被攻破。请马上使用其他邮箱或手机号注册新账户，将矿机切换到新账户。',
        PENDING_NOT_ENOUGH:
          '金额小于您设置的起付金额（默认 :limit ），将存入余额。',
        PENDING_POOL_SETTLE_FAULT:
          '因矿池原因，导致这部分金额误发，后续将不再发放。'
      },
      depositeAmount: '存入余额',
      diffChg: '难度变化: ',
      equalsPps: '折合PPS',
      Chg: '日变动',
      status: '支付状态',
      paid: '已支付',
      waitingPay: '待支付',
      reason: '原因',
      fppsMoreEarn: 'FPPS额外收益:',
      fppsContain: 'FPPS额外收益 {pps96} 已包含在收益 {paid_amount } 中。',
      noEarningsInformation: '暂无收益信息',
      share_host_earn: '平台分润打款',
      share_hosting: '平台分润打款',
      share_elec_fee: '电费打款',
      hosting: '托管费用打款',
      repair: '维修费用打款',
      onboard: '上架费用打款',
      offboard: '下架费用打款',
      transfer: '转场费用打款',
      service: '平台管理费打款'
    },
    poolStats: {
      title: '矿池统计 - BTC.com Pool',
      poolStats: '统计',
      hashrate: '算力',
      coinType: '币种',
      miners: '矿机',
      found: '挖矿所得',
      hashrateChart: '算力图表',
      blocksRelayed: '广播区块',
      height: '高度',
      time: '时间',
      blocks: '区块',
      blockHash: '区块哈希',
      reward: '区块奖励',
      day: '日',
      region: '报块地区',
      lucky: '幸运值',
      btc: '比特币',
      ltc: '莱特币',
      nmc: '域名币',
      zec: '零币',
      bcc: '比特币现金',
      bch: '比特币现金',
      eth: '以太坊',
      etc: '以太经典',
      dcr: 'DCR',
      doge: '狗狗币',
      grin: 'GRIN',
      sbtc: '超级比特币',
      ubtc: '比特币联储'
    },

    // 守护者
    guardian: {
      expiredDeadline: '(:day 自动删除)',
      guardianRemark:
        '您可以将全部或部分子账号信息（包括算力、矿机等）共享给其它用户，该用户仅可以查看但无法改动任何信息',
      createGuardian: '添加共享用户',
      remark: '备注：',
      user: '用户:',
      manage: '管理',
      status: '状态:',
      theGuardian: '共享账户:',
      creationDate: '创建日期:',
      datePrevious: '最近使用日期:',
      bounded: '已绑定',
      delete: '删除',
      deleteGuardianLink: '删除共享账户链接',
      createGuardianInvitation: '生成共享账户邀请链接',
      guardianAccountManagement: '共享账户管理',
      modifyRemark: '修改备注名称',
      validateRemarkEmpty: '备注名称不可为空',
      enterRemark: '输入备注名称',
      noteInformation:
        '提醒： 该名称用于区别不同共享账户，建议使用对方的联系方式等信息。',
      confirm: '确认',
      create: '生成',
      cancel: '取消',
      guardianSuccessfullyCreated: '成功创建共享账户邀请链接',
      copyLink: '复制链接',
      authorized: '删除后，他人会失去对您子账户的管理权限',
      noteSendGuardian:
        '提醒： 请将该链接发送给有矿机管理需求的可信人员。该链接仅供访问一次，若对方提示失效，请重新生成。',
      close: '关闭',
      subaccount: '子账户',
      coinType: '币种',
      region: '地区',
      guardianStatus: '共享状态',
      guardianSelectExpress:
        '通过分享状态中的开关，您可以自由的选择他人能够管理的子账户（默认设置为全部子账户均可访问）',
      invitTitle: '共享给他人',
      acceptTitle: '接受他人共享',
      losePower: '删除后你将失去对方子账户的管理权限。',
      show: '显示'
    },
    // 绑定守护者邀请链接
    guardianBind: {
      bindingGuardianInvitation: '绑定共享账户邀请链接',
      advance: '高级',
      guardianAccountManagement: '共享账户管理:',
      confirmGuardianBind: '确定守护并绑定账户',
      remark: '备注名称',
      enterRemark: '输入备注名称',
      suggestName:
        '推荐使用对方的名字，方便统一管理。若不填写将默认使用对方的手机号或电子邮箱。',
      guardian: '绑定',
      cancel: '取消',
      confirm: '确定由',
      manageView: '管理维护您的所有子账户？',
      suggestUsingName:
        '推荐使用对方的名字，方便统一管理。若不填写将默认使用对方的手机号或电子邮箱。',
      sms: '手机',
      mail: '邮箱',
      code: '{mode}验证码',
      send: '获取'
    },
    account: {
      subAccountManage: '子账户管理',
      currentAccount: '当前账户',
      locationRegion:
        '您当前位于 <strong>{coin}</strong> <strong>{region}</strong> 服务器',
      locationEx:
        '每个子账户对应固定的币种和区域，一旦创建完成，币种和区域将无法修改。如果有其他需要，可以创建新的子账户。',
      general: '通用版',
      advance: '专业版',
      create: '创建',
      algorithm: ' 算法',
      oneButtonSwitch: '一键切换',
      addOthersCoins: '添加其它算法币种',
      supportCoins: '支持',
      hiddenSubAccounts: '隐藏子账户管理',
      hashAlert: '运行良好',
      viewHideSubAccount: '查看隐藏子账户',
      addSubAccount: '添加子账户',
      recover: '还原',
      latestAlert: {
        hashrate: '实际算力 {symbol} {expect} {unit}',
        miners: '实际矿机数量 {symbol} {expect} 台'
      }
    },
    settings: {
      subAccount: '子账户',
      currency: '币种',
      region: '地区',
      walletAddressAndMinimumPayment: '收款地址 & 最小起付金额',
      walletAddress: '收款地址',
      shareData: '共享数据',
      alertSettings: '报警设置',
      payWithBtc: '以BTC结算',
      //wallet address & minimum payment
      setAddress: '请设置 {coinType} 收款地址',
      address: '收款地址',
      modifyAddress: '更改地址',
      modify: '修改',
      minPay: '起付金额',
      lastUpdateTime: '地址修改时间',
      updateBitconAddressTip:
        '注意：为了安全原因，修改地址后48小时内不予支付，您的收益将作为余额，在48小时后的第一个结算日支付。',

      old: '旧地址',
      new: '新地址',
      continue: '下一步',
      emptyAddress: '地址不能为空',
      addAddress: '填写',
      enterCode: '请填写验证码',
      newBit: '您的新地址',
      way: '我们将发送验证码来确认此次修改 , 请选择验证方式:',
      sendVerifyCode: '验证码已发送到{contactType}',
      sms: '手机',
      mail: '邮箱',
      mailVia: '发送验证码到邮件',
      smsVia: '发送验证码到手机',
      errorLater: '请稍后再试 ...',
      updateSuccess: '地址修改成功',
      //min pay
      currentMinPay: '当前起付金额:',
      minPayAllSubAccount: '所有比特币(BTC)子账户使用相同起付金额',
      todayUpdateMinPay: '从今日起挖矿收益的起付金额已更改为: {minPay}',

      //Share to watcher
      watcher: '观察者',
      availableWatchers: '当前可用',
      watcherExpress:
        '* 观察者可以通过以下链接访问你的数据，但无法操作、改动你的账户。',
      createWatcher: '创建新观察者',
      setWatcherName: '为观察者设置一个备注名',
      notEmptyWatcherNote: '观察者备注名不能为空',
      moreSettings: '更多设置',
      guardianRemark:
        '您可以将全部或部分子账号信息（包括算力、矿机等）共享给其它用户，该用户仅可以查看但无法改动任何信息',
      guardian: '共享账户',
      shareAccountsSetting: '共享账户设置',
      accessKey: 'Access Key',
      generateAK: '生成 Access Key',
      puid: 'PUID',
      apiDoc: 'API 文档',
      confirmDeleteWatcher: '确认删除该观察者账户?',

      // Alert setting
      alertItem: '报警项设置',
      alertContacts: '报警联系人',
      hashrateAlert: '算力报警',
      minerAlert: '矿机数量报警',
      alertFrequency: '报警频率',
      warnMeWhenHashrate: '算力警戒线≤',
      warnMeWhenActiveMiner: '活跃矿机数量警戒线≤',
      dontWarnMeTwice: '两次报警最小间隔',
      hours: '小时',
      apply: '应用',
      operateFailed: '操作失败!',
      contactsList: '联系人列表',
      cancel: '取消',
      ok: '确定',
      save: '保存',
      reset: '重置',
      newContact: '新增联系人',
      editContact: '修改联系人',
      deleteContact: '删除联系人',
      confirmDeleteContact: '确认删除该报警联系人?',
      name: '称谓/备注',
      phone: '电话',
      email: '邮箱',
      validateAlert: {
        nameEmpty: '称谓/备注不能为空',
        phoneRegionFormat: '国际区号格式不正确',
        phoneFormat: '电话格式不正确',
        emailFormat: '邮箱格式不正确',
        contactEmpty: 'email 和 电话号码 必须填写一个'
      },
      max_email_contacts: '最多支持10个邮件联系人',
      max_phone_contacts: '最多支持5个电话联系人',
      enabled_alert_need_hashrate: '未检测到算力，无法开启报警。',
      alertItemUpdateSuccess: '报警项设置成功',

      done: '提交',

      okay: '确定',
      emptyAddress: '地址不能为空',
      invalidAddress: '错误的地址',
      emptyCode: '验证码不能为空',
      wrongCode: '验证码错误',
      emailVia: '发送邮件到',
      textVia: '发送短信到',
      help: '帮助',

      contact: '联系方式:',
      close: '关闭',
      receivedress: '收款地址:',

      todayUpdateMinPay: '从今日起挖矿收益的起付金额已更改为:{minPay}',
      updateAddress: '地址修改时间:',
      // pay with btc
      payWithBtcTip:
        '* 开启以BTC结算之后，您的挖矿收益将在每天的固定时间折算成比特币，体现在收益记录中，并支付到以下独立的BTC结算地址上，一般情况下为每天上午的12:00，特殊情况时会另行通知。',
      convertedCurrency: '兑换币种: ',
      setBtcAddressTip: '请设置BTC收款地址',
      btcAddress: '比特币地址: ',
      conversionRate: '参考汇率',
      convert: '兑换',
      getValue: '您将得到 ',
      conversionTip: '* 兑换汇率仅供参考，以实际结算结果为准.',
      lastUpdate: '最后更新于: ',
      on: '开',
      off: '关',
      turnOnPaywith: '确定要开启以 BTC 支付吗?',
      turnOffPaywith: '确定要关闭以 BTC 支付吗?',
      payOn: '开启',
      payOff: '关闭',
      resend: '重新发送',
      send: '发送验证码',
      resendTip: '如未收到验证码，请在倒计时结束后点击重新发送',

      // 一键切换 One Switch
      oneSwitch: {
        attention: '请注意',
        optionCurrentMode: '请选择切换模式',
        switch: '您即将切换算力到 {destMode}',
        switchAnotherMining: '正在切换到 {destMode} 模式中...',
        ethSwitchWarning:
          '警告：若您的矿机不支持切换DAG，会导致收益为零。需手动重启矿机，才可恢复正常。（已知蚂蚁矿机E3不支持，由ETC切换到ETH，需手动重启）',
        ethSwitchWarningLinkTxt: '详情请点击',
        hashsHaveSwitched: '算力已切换到 {destMode} 挖矿;',
        checkSubAccount:
          '请查看子账号 {subAccountName}- {subAccountRegionNode}',
        enter: '进入'
      }
    },
    homepage: {
      btcSmartAgent: 'BTC智能代理',
      agentExpress: '高效透明&emsp;&emsp;&emsp;单台矿机可见',
      bit64: 'Win7及以上 64位',
      bit32: 'WinXP 32位',
      document: 'windows版帮助文档',
      accessLinuxVersion: 'Linux版本安装直接访问',
      cuttingEdgeTechnology: '领先的核心技术',
      latestArchitecture: '最新原创架构<br/>俯瞰百家所长',
      openSourceCode: '代码开源<br/>公开公平',
      highTestPressure: '超高压力测试<br/>坚若磐石',
      lowOrphanedRate: '超低孤块率<br/>快如闪电',
      transparentAgent: '全新配套软件<br/>透明代理',
      standardsServices: '矿业服务新标准',
      customerHotline: '客服电话',
      highHashrateConsultation: '大客户招商',
      professionalCustomerService: '真正有专业客服的矿池',
      bestVipServer: '业内最高规格VIP服务器',
      oneToOne: '业内顶尖研究者一对一分析案例',
      appAvailable: '矿池App让你躺着管理',
      earningsTimelyEveryday: '准点打款 风雨无阻',
      transnationalTeamOperation: '首个由跨国团队支持的矿池',
      currencies: '币种',
      dailyEarnings: '日收益',
      hashrate: '算力',
      prices: '币价',
      earningsModels: '收益模式',
      minPayment: '最小起付金额',
      help: '社区交流',
      notePaytime: '备注：结算时间为每日上午 08:00.（北京时间 UTC +8）',
      yuanKwhElectric: '元/千瓦·时(电费)',
      todayEarnings: '日收益约:',
      piece: '台',
      signIn: '登录',
      signUp: '注册',
      power: '功耗',
      hashrateProfitEst: '算力收益参考',
      miningProfitEst: '主流矿机收益参考',
      fee: '费率'
    },
    advanceAccount: {
      classic: '（经典）',
      title: '高级子账户管理 - BTC.com',
      advance: '专业',
      all: '全部币种',
      ofSubAccount: '个子账户',
      updateAt: '更新于',
      unit: '单位',
      index: '序号',
      subAccount: '子账户',
      coin: '币种',
      hashrate: '算力',
      allMiners: '全部矿机',
      guard: '备注名称',
      activeMiners: '活跃',
      inactiveMiners: '非活跃',
      deadMiners: '失效',
      region: '地区',
      more: '更多',
      setting: '设置',
      totalHashrate: '总算力',
      addressChange: '收款地址修改',
      minPay: '起付金额',
      setAlert: '报警设置',
      setContact: '报警联系人',
      nmcChange: 'NMC地址修改',
      allIncome: '收益一览',
      shareData: '共享数据',
      getAk: '获取币看监控密钥',
      search: '搜索',
      noMatch: '没有找到匹配的记录'
    },
    getCoin: {
      tip: {
        btc:
          '即日起在BTC.com矿池挖矿(比特币现金，比特币)的同时免费赠送域名币(NMC), 亦来云(Elastos), VCash(VCASH)。',
        bch:
          '即日起在BTC.com矿池挖矿(比特币现金，比特币)的同时免费赠送域名币(NMC), 亦来云(Elastos), VCash(VCASH)。',
        ltc:
          '即日起在BTC.com矿池挖矿(莱特币LTC)的同时免费赠送狗狗币(Doge)，以PPLNS方式结算。'
      },
      beta: '内测中',
      whatsThis: '这是什么?',
      address: '地址:',
      newAddress: '新地址',
      addressComplete: '为了保证您的收益不受任何损失，请尽快填写免费赠币地址。'
    },
    watcher: {
      expired: '观察者权限已过期',
      incorrect: '观察者链接错误'
    },
    activity: {
      pointsToday: '今日积分',
      pointsSum: '累计积分',
      ranking: '排名',
      earnings: '收益'
    }
  }
};
const pageMessageFlatten = flatten(pageMessage);
export default pageMessageFlatten;
