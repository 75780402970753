import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Switch } from 'antd';
import Ts from 'localeTranslator';

@inject('store')
@observer
class ToggleAccount extends Component {
  constructor(props) {
    super(props);
    this.appStore = this.props.store.appStore;
    this.userStore = this.props.store.userStore;
  }

  onToggleOpen = checked => {
    this.userStore.toggleAccountAllOpen(checked);
  };

  render() {
    const { accountAllOpen } = this.userStore;

    return (
      <Switch
        checkedChildren={<Ts id="common.showAll" />}
        unCheckedChildren={<Ts id="common.foldMe" />}
        checked={accountAllOpen}
        onClick={this.onToggleOpen}
      />
    );
  }
}

export default ToggleAccount;
